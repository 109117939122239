import {date} from "ROOT/src/components/listEditableFields/exportConfig";

export default function wasThere(recordData) {

  let userID = this.dataGet(recordData.prefix + "/fields/assigned_user_id/value")
  let when = this.dateFormat(this.dataGet(recordData.prefix + "/fields/date_start/value"))

  let distanceTolerance = 14;

  let postData = {
    id: recordData.id,
    type: 'lastKnownPosition', // zatim se nevyuziva
    userID: userID,
    when: when,
    distanceTolerance: distanceTolerance // kolik dni naspet kontrolovat, jestli se auto hnulo z mista
  }

  this.load();
  this.rest.post("wasThere", postData, returnData => {
    if (returnData.status) {
      let carData = returnData.message.data.car;
      let MeetingData = returnData.message.data.meeting;
      MeetingData.when = when;
      let item = {
        time: carData.time,
        gpsX: carData.lon,
        gpsY: carData.lat,
        obec: carData.obec,
        psc: carData.psc,
        kraj: carData.kraj,
        spz: carData.spz,
        carID: carData.carID,
      };

      this.popup("OpenStreetMap", {
        item: item,
        target: MeetingData,
        name: '',
        markerTitle: this.translate("LBL_MARKER_TITLE_VEHICLE_POSITION", "acm_reports"),
        params: recordData,
        sidebar: true, // zobrazit sidebaru pri vygenerovani mapy
        sidebarSearch: false, // zobrazit sidebaru pri vygenerovani mapy
        clickable: false, // zruseni hledani souradnic pri kliknuti na mapu
        header: this.translate("LBL_BYL_TAM", "acm_reports")
      });
      this.unLoad()
    } else {
      this.unLoad()
      let errMsg = this.translate(returnData.errorMessage.text).replace('{MAX_ATT}', distanceTolerance)
      this.alert(errMsg)
    }
  });


}

function getFormatedDate(timeStamp) {
  const date = new Date(timeStamp);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  if (month < 10) {
    month = "0" + month
  }
  let day = date.getDate();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  if (day < 10) {
    day = "0" + day;
  }
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes
  }

  return (year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":00");
}
