export default function convertToCampaignsAction(params) {
    this.openRelatePopup({
        module: "acm_CampaignsAction",
        selectedActive: false,
    }, selectedRecord => {
        this.popup("SetCampaignStage", {
            header: this.translate("LBL_ADD_TO_CAMPAIGNS_ACTION"),
            data: {campaignId: selectedRecord.id, prospectListId: params.id}
        });
    });
}


