import React from "react";
import sAction from "sAction";

export default function Multirelate(props) {
    let values = props.value.get("value");

    if(!values){
        return (<div className={"newListViewLine " + props.extraClass}/>)
    }

    values = values.split(',');

    let accountsToRender = [];
    let names = [];
    let ids = [];

    values.forEach(value => {
        if (value.includes("^")) {
            ids.push(value.replaceAll("^", ""))
            return
        }
        if(value.includes(";")){
            names.push(value.replaceAll(";", ","))
            return
        }
        names.push(value)
    })

    names.forEach((name, index) => {
        let element = <span className={"multiRelateListItem"} key={index} title={name}>{ids[index]}</span>
        if (sAction.hasAccess(props.module, "detail")) {
            element = <a className={"multiRelateListItem"} key={index} title={name} href={"#detail/Accounts/" + ids[index]}>{name}</a>;
        }

        accountsToRender.push(element)
    })


    return (<div className={"newListViewLine " + props.extraClass}> {accountsToRender} </div>);
}
