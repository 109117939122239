import React, { Component } from 'react'
import PureComponent from '../../pure'

import QProductGroup from './QProductGroup'
import QTotal from './QTotal'

import ButtonFloat from "../../formElements/Button";
class ProductComponent extends PureComponent{

    render(){
    const data = this.props.data;
    const way = this.props.way;
    const readonly = this.props.readonly;
    
    var renderComp = [];


    data.groups.forEach((group,k) => {

        renderComp.push(<QProductGroup
            data={group}
            key={k}
            ind={k}
            way={way+"/groups"}
            currency_id={data.currency_id}
            readonly={readonly} />);
    });


    return (
        <div className="detailCard detailViewTabContent detailQuoteProdComponent">
        {renderComp}
            <QTotal
                data={data}
                way={way}
                readonly={readonly}
            />
        </div>
    )
    }
}
export default ProductComponent
//sAction.openRightPanel("productView", {})
