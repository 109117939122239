export default function quoteActivate(data) {
  //Pro aktivaci je treba splnit podminky
  const quotetype = this.dataGet(data.prefix + '/fields/quotetype/value');
  if (quotetype === 'simplified') {
    this.load();
    this.rest.post('canQuoteBeActivated', {quoteID: data.id}, returnData => {
      if (returnData.status) {
        saveActivated(data, this);
      } else {
        this.unLoad();
        this.error(this.translate(returnData.errorMessage.text));
      }
    });
  } else {
    saveActivated(data, this);
  }
}

function saveActivated(data, sAction) {
  const field = { way: data.prefix + "/fields/quote_stage", name: "quote_stage", value: "Active" };
  sAction.saveField(field, true);
  document.getElementById("save_record").click();

  if (sAction.dataGet(data.prefix+"/canSave")) {
    const opportunity_id = sAction.dataGet(data.prefix + "/fields/opportunity_name/def/id_value");
    if (opportunity_id) {
      const data = {
        opportunity_id,
        sales_stage: "Value Proposition",
      };
      sAction.rest.post("opportunityStatusChange", data, returnData => {

      }, false);
    }
  }
}
