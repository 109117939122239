import sAction from 'sAction';
import React, {PureComponent} from 'react';
import Relate from '../../formElements/Relate';

export default class Multirelate extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            relates: []
        }

        this.index = 0;
        this.filterAttr = '';

        this.fieldName = this.props.data.name;
    }

    componentDidMount() {
        this.makeFilter();
        this.makeRelateFields();
    }

    /**
     * Creates array with <Relates> (formElements/Relate) with every name
     * that is in the options or is passed in paramNames
     * @param paramNames names of accounts default null
     */
    makeRelateFields(paramNames = null) {
        this.index = 0;
        let renderRelates = []

        if (this.props.data.def?.get('options')?.get('names').size || paramNames) {
            const names = paramNames ? paramNames : this.props.data.def.get('options').get('names');
            names.forEach((name, index) => {
                this.pushNewRelate(renderRelates, name, index === names.size - 1);
            });
        } else {
            this.pushNewRelate(renderRelates, '', true);
        }

        this.setState({relates: renderRelates});
    }

    /**
     * If there is a filter saved in definition of field make field according to this value
     */
    makeFilter() {
        //helpdesk https://helpdesk.acmark.cz/mantis/view.php?id=12562
        // zmenit na  account_type:
        //                     {
        //                         "field": "dealer_type",
        //                         "type": "contLikeArray",
        //                         "value": [filter]
        //                     }


        let filter = []
        if (this.props.data.def.get("fieldFilter")) {
            filter = this.props.data.def.get("fieldFilter");

            this.filterAttr = {
                account_type:
                    {
                        "field": "rel_type",
                        "type": "eq",
                        "value": filter
                    }
            }
        }
    }

    /**
     * Gets all current ids names and value string value is in format ^ID^ deletes name id and value according
     * to fieldIndex passed in data.
     * @param data format is data = {self: Multirelate  prefix: fieldIndex:}
     */
    deleteValue(data) {

        let ids = sAction.dataGet(data.prefix + '/fields/' + data.self.fieldName + '/def/options/id');
        let names = sAction.dataGet(data.prefix + '/fields/' + data.self.fieldName + '/def/options/names');
        let value = sAction.dataGet(data.prefix + '/fields/' + data.self.fieldName + '/value');

        ids = ids.filter((element, index) => index !== data.fieldIndex - 1);
        names = names.filter((element, index) => index !== data.fieldIndex - 1);

        value = value.split(',');
        value = value.filter((element) => element !== '').filter((element, index) => index !== data.fieldIndex - 1).join();

        sAction.dsClear();
        if (value.length === 0) {
            sAction.dsAdd('set', data.prefix + '/fields/' + data.self.fieldName + '/actEdit', false);
        }
        sAction.dsAdd('set', data.prefix + '/fields/' + data.self.fieldName + '/def/options/id', ids);
        sAction.dsAdd('set', data.prefix + '/fields/' + data.self.fieldName + '/def/options/names', names);
        sAction.dsAdd('set', data.prefix + '/fields/' + data.self.fieldName + '/def/edit', true);
        sAction.dsAdd('set', data.prefix + '/fields/' + data.self.fieldName + '/value', value);
        sAction.dsAdd('set', data.prefix + '/changes/fields/' + data.self.fieldName + '', value);
        sAction.dsProcess();
        data.self.makeRelateFields(names);
    }

    /**
     * Pushes to passed array with specified name
     * @param renderRelates array to be filled
     * @param name name which will be rendered
     * @param autoFocus whether or not focus new relate field
     */
    pushNewRelate(renderRelates, name, autoFocus) {
        let i = this.index
        renderRelates.push(<Relate
            fieldIndex={this.index}
            key={this.index}
            autoFocus={autoFocus}
            onKeyDown={(e) => this.onKeyDown(e, i)}
            newRecord={false}
            module="Accounts"
            data={this.props.data}
            buttons={[{
                id: "deleteValue",
                className: "icon-detailCancel",
                onClick: this.deleteValue,
                params: {self: this, prefix: this.props.prefix, fieldIndex: this.index}
            }]}
            name={name}
            callback={(item, fieldIndex) => this.handleSelect(this.props.prefix, item, fieldIndex)}
            defaultFilter={this.filterAttr}
        />);
        this.index++;
    }

    /**
     * adds new empty fields to relates arr and changes state
     */
    addRelateField() {
        const renderRelates = [];
        this.pushNewRelate(renderRelates, '', true);
        this.setState({relates: [...this.state.relates, renderRelates]});
    }

    /**
     * called when accounts are selected from popup pushes new value to array or replaces them and saves them to store
     * @param prefix either view or rightpanel
     * @param item item is array or object depends whether user clicked on one account or selected more
     * @param fieldIndex index of fields where user clicked
     */
    handleSelect(prefix, item, fieldIndex) {
        sAction.load();
        if (item.id) {
            let existingIds = [];
            const newIds = [];
            if (sAction.dataGet(prefix + '/fields/' + this.fieldName + '/def/options/id')) {
                existingIds = sAction.dataGet(prefix + '/fields/' + this.fieldName + '/def/options/id').toJS();
            }

            if (Array.isArray(item.id)) {
                item.id.forEach((accountId) => {
                    this.pushOrReplace(accountId, existingIds, fieldIndex, newIds);
                    fieldIndex++;
                });
            } else {
                this.pushOrReplace(item.id, existingIds, fieldIndex, newIds);
            }

            const allIds = [...existingIds, ...newIds]
            sAction.rest.post("getAccountNames", allIds, returnData => {
                if (returnData.status) {

                    const options = {
                        id: allIds,
                        names: returnData.message.data.name,
                        rel_type: returnData.message.data.rel_type,
                    };

                    let concatAcc = [];
                    allIds.forEach((id) => {
                        concatAcc.push(`^${id}^`);
                    });

                    concatAcc = concatAcc.join();

                    sAction.dsClear();
                    sAction.dsAdd('set', prefix + '/fields/' + this.fieldName + '/def/options', options);
                    sAction.dsAdd('set', prefix + '/fields/' + this.fieldName + '/def/edit', true);
                    sAction.dsAdd('set', prefix + '/fields/' + this.fieldName + '/value', concatAcc);
                    sAction.dsAdd('set', prefix + '/changes/fields/' + this.fieldName + '', concatAcc);
                    sAction.dsProcess();
                    sAction.unLoad();
                }
            });
            this.addRelateField();
        }
        sAction.unLoad();
    }

    /**
     * Pushes new value to array or replaces it.
     * Must be with two different arrays because fieldIndex gets increment and would rewrite
     * @param valueToPush
     * @param existingIds
     * @param fieldIndex
     * @param newIds
     */
    pushOrReplace(valueToPush, existingIds, fieldIndex, newIds) {
        if (!existingIds.includes(valueToPush)) {
            if (existingIds[fieldIndex]) {
                existingIds[fieldIndex] = valueToPush;
            } else {
                newIds.push(valueToPush);
            }
        }
    }
    onKeyDown(e, index) {
        switch (e.keyCode) {
            case 8:
            case 46:
                if (e.target.value === '' && index === this.index - 1) {
                    e.preventDefault();
                    this.deleteValue({fieldIndex: index + 1, self: this, prefix: this.props.prefix});
                }
                break;
            case 9:
                e.preventDefault();
                sAction.detailTabNext({
                    name: this.props.data.name,
                    prefix: this.props.prefix,
                    index: this.props.index,
                    rowWay: this.props.rowWay,
                    type: this.props.data.def.get('type'),
                    shift: e.shiftKey,
                }, true);
                break;
            default:
                break;
        }
    }

    render() {
        return (
            <>
                {this.state.relates}
                <div>
                    <a onClick={() => this.addRelateField()} tabIndex="-1">
                        + <span className="icon-accounts"/>
                        {" " + sAction.translate("LBL_ADD_ACCOUNT_RELATE")}
                    </a>
                </div>
            </>
        );
    }
}
