export default function checkActiveQuestionnaire(recordData) {
    const questionnaireTemplateType = this.dataGet(recordData.prefix + "/fields/type/value")
    const active = this.dataGet(recordData.prefix + "/fields/active/value")

    const questionnaireData = {
        'currentId': recordData.id,
        'questionnaireTemplateType': questionnaireTemplateType
    }

    if (questionnaireTemplateType && (active === "1" || active === 1)) {
        this.rest.post("checkActiveQuestionnaire", questionnaireData, returnData => {
            if (returnData.status) {
                if (returnData.message.data > 0) {
                    const popupData = {
                        header: this.translate("LBL_QUESTIONNAIRE_LIMIT"),
                        buttons: [
                            {
                                label: this.translate("LBL_NO"), callback: () => {
                                    this.dsClear()
                                    this.dsAdd("set", recordData.prefix + "/fields/active/value", 0)
                                    this.dsAdd("set", recordData.prefix + "/changes/fields/active", 0)
                                    this.dsProcess()
                                    this.popupHide();
                                    this.saveRecord(recordData)
                                }
                            },
                            {
                                label: this.translate("LBL_YES"), callback: () => {

                                    this.popupHide();
                                    this.rest.post("setInactiveQuestionnaires", questionnaireData, returnData => {
                                        if (returnData.status) {
                                            this.saveRecord(recordData)
                                        } else {
                                            this.error("Špatný návrat ze serveru")
                                        }
                                    })
                                }
                            },
                        ],

                    };

                    this.popup(this.translate("LBL_QUESTIONNAIRE_LIMIT_PHRASE"), popupData);
                } else {
                    this.saveRecord(recordData)
                }
            } else {
                this.error("Špatný návrat ze serveru")
            }
        })
    } else {
        this.saveRecord(recordData)
    }


}