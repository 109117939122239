import React from "react";
import PureComponent from "../../pure";
import sAction from "sAction";
import Button from "../../formElements/Button";
import RolesTable from "../roles/RolesTable";
import TabContainer from "../../formElements/TabContainer";
import InputText from "../../formElements/InputText";
import Checkbox from "../../formElements/CheckBox";

export default class QuoteCreateTemplatePopup extends PureComponent {
    constructor(props) {
        super(props);

        var pomName = sAction.translate("LBL_TEMPLATE", "Quotes") + " - " + props.data.get("name");

        if (sAction.dataGet(props.data.get("prefix") + "/fields/quote_num/value") === "template") {
            pomName = props.data.get("name");
        }


        this.state = {
            name: pomName,
            selectedRoles: [],
            selectedUsers: [],
            activeTab: 0,
            adminView: 0,
        }

    }

    handleCreate(id) {
        const params = {
            action: "quoteCreateTemplate",
            id: id,
            name: this.state.name,
            selectedUsers: this.state.selectedUsers,
            selectedRoles: this.state.selectedRoles,
            adminView: this.state.adminView,
        };
        sAction.load();
        sAction.rest.post("customAction", params, function (resp) {
            sAction.popupHide();
            sAction.unLoad();
            if (resp.errCode == "OK") {
                sAction.alert(resp.msg);
            }
        });
    }

    updateData(table, data) {
        this.setState({[table]: data});
    }

    changeActiveTab(value) {
        this.setState({activeTab: value});
    }

    onKeyDown(key) {
        // Ukládá při enteru
        if (key.keyCode === 13) {
            const id = this.props.data.get("id");
            this.handleCreate(id);
        }
    }

    changeAdminView(value) {
        value = value ? 1 : 0
        this.setState({adminView: value})
    }

    render() {
        const id = this.props.data.get("id");
        const roles = sAction.translate("LBL_ROLES", "Home");
        const users = sAction.translate("LBL_USERS", "Home");
        const tabs = [roles, users]; //"Role", "Uživatelé"
        let userSelection = null;
        let renderMenu = (
            <TabContainer
                onChange={(e, value) => this.changeActiveTab(value)}
                value={this.state.activeTab}
                tabs={tabs}
            />
        );

        //musi se checkovat na "1" prijde jako "1" nebo "0"
        if (sAction.userPreferences.is_admin == "1") {
            userSelection = (
                <>
                    <tr>
                        <td className="formlabel">{sAction.translate("LBL_SYSTEM_TEMPLATE")}</td>
                        <td>
                            <Checkbox
                                defaultValue={this.state.adminView}
                                onChange={val => this.changeAdminView(val)}
                            />
                        </td>
                    </tr>
                </>
            );

            if (!this.state.adminView) {
                userSelection = (
                    <>
                        <tr>
                            <td className="formlabel">{sAction.translate("LBL_SYSTEM_TEMPLATE")}</td>
                            <td>
                                <Checkbox
                                    defaultValue={this.state.adminView}
                                    onChange={val => this.changeAdminView(val)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="formlabel">
                                {sAction.translate("LBL_ASSIGNED", "Quotes")}
                            </td>
                            <td>
                                <div className="acmTabContainer">{renderMenu}</div>
                                <div className={this.state.activeTab !== 0 ? "homeTableHide hidden" : "homeTableHide"}>
                                    <RolesTable
                                        module="ACLRoles"
                                        selectedData={this.state.selectedRoles}
                                        onChange={data => this.updateData("selectedRoles", data)}
                                    />
                                </div>
                                <div className={this.state.activeTab !== 1 ? "homeTableHide hidden" : "homeTableHide"}>
                                    <RolesTable
                                        keepSelf={true}
                                        module="Users"
                                        selectedData={this.state.selectedUsers}
                                        onChange={data => this.updateData("selectedUsers", data)}
                                    />
                                </div>
                            </td>
                        </tr>
                    </>
                );
            }
        }

        return (
            <div className="" onKeyDown={e => this.onKeyDown(e)}>
                <div className='viewActionPanelButtons'>
                    <Button className="hoverGreen" onClick={() => this.handleCreate(id)}>
                        <div className={"icon-saveIcon calHeaderIcon"}/>
                        {sAction.translate("LBL_SAVE", "Home")}
                    </Button>
                    <Button className="hoverRed" onClick={() => sAction.popupHide()}>
                        <div className={"icon-close calHeaderIcon"}/>
                        {sAction.translate("LBL_CANCEL_ACTION")}
                    </Button>
                </div>
                <div className="templateCreatorFormContainer simpleForm" onKeyDown={e => this.onKeyDown(e)}>
                    <table>
                        <tbody>
                        <tr>
                            <td>
                                {sAction.translate("LBL_TEMPLATE_NAME", "Quotes")}
                            </td>
                            <td>
                                <InputText
                                    variant="outlined"
                                    id="name"
                                    type="text"
                                    value={this.state.name}
                                    onChange={(ev) => {
                                        this.setState({name: ev.target.value})
                                    }}
                                    autoFocus={true}
                                />
                            </td>
                        </tr>

                        {userSelection}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
