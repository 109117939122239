import React from "react";
import sAction from "sAction";
import PureComponent from "../../pure";
import InputText from "../../formElements/InputText";
import Button from "../../formElements/Button";

export default class AddToRightPanelEnum extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      itemToAdd: null,
    }
  }

  add() {
    const data = this.props.data;
    const callback = data.get("callback");
    if (callback !== undefined) {
      callback(
        this.state.itemToAdd
      );
    }
  }

  render() {
    return (
      <div className="simpleForm">
        <table>
          <tbody>
          <tr>
            <td className="formlabel">{sAction.translate("LBL_NEW_ENUM_ELEMENT")}</td>
            <td>
              <InputText
                className="withBorder"
                defaultValue={this.state.email}
                onChange={(e) => this.setState({itemToAdd: e.target.value})}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className="viewActionPanelButtons flexRight">
                <Button onClick={() => this.props.close()} className='popupCloseButton'>
                  <div className={"actionPanelButtonIcon icon-closeIconLight"}/>
                  {sAction.translate("LBL_EXPORT_CLOSE")}
                </Button>
                <Button onClick={() => this.add()}>
                  <div className={"actionPanelButtonIcon icon-download"}/>
                  {sAction.translate("LBL_ADD_TO_ENUM")}
                </Button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

