import React from 'react';
import InputField from '../inputFieldClass';

import Select from '../../formElements/Select';
import InputText from '../../formElements/InputText';

import sAction from 'sAction';

export default class AcmDynamicEnum extends InputField {
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
            options: null,
            type: 'enum',
            inputError: false,
        };
        this.input = React.createRef();

        this.preferredOptions = [
            'desta_vzv',
            'hyster_vzv',
            'jungheinrich_vzv',
            'linde_vzv',
            'still_vzv',
            'toyota_vzv',
            'yale_vzv',
        ];
    }
    componentDidMount() {        
        sAction.load();
        const data = this.props.data;
        const actLang = sAction.dataGet('conf/language');
        sAction.openDynamicEnum(this.props.way, data, (returnData) => {
            const lines = returnData.lines;

            let linesOptions = [];
            // if (returnData.canAddNew == true) {
            linesOptions.push({
                label: sAction.translate('LBL_ADD_NEW_VALUE'),
                value: 'acm_add_new',
                type: 'button',
            });
            // }
            lines.forEach((line, index) => {
                const value = line.language_json[actLang] ?? line.value;
                if (!line.language_json[actLang]) {
                    console.warn(`[AcmDynamicEnum] Missing translation for ${line.value} in ${actLang}`);
                }
                const option = {
                    value: line.value,
                    label: value,
                };
                value ? linesOptions.push(option) : linesOptions.unshift(option);
            });

            linesOptions = this.processOptions(linesOptions);
            this.setState({
                options: linesOptions,
                showForm: true,
            });
            sAction.unLoad();
        });
    }

    processOptions(options) {
        const addNew = options[0];
        const preferredOptions = [addNew];
        const otherOptions = [];
        options.forEach((option, index) => {
            if (this.preferredOptions.includes(option.value)) {
                preferredOptions.push(option);
            } else {
                if (index > 0) {
                    otherOptions.push(option);
                }
            }
        });

        let retOptions = preferredOptions.concat(otherOptions);
        retOptions = retOptions.sort((a, b) => {
            if (a.value === 'acm_add_new') {
                return -1;
            }
            if (b.value === 'acm_add_new') {
                return 1;
            }
            return a.label.localeCompare(b.label);
        });
        return retOptions;
    }

    // Stara verze s restovym volanim
    // componentDidMount() {
    //   sAction.load();
    //   const data = this.props.data;
    //   const actLang = sAction.dataGet("conf/language");
    //   sAction.openDynamicEnum(this.props.way, data, returnData => {
    //     const lines = returnDat a.lines;
    //
    //     var linesOptions = [];
    //     if (returnData.canAddNew == true) {
    //       linesOptions.push({ label: sAction.translate("LBL_ADD_NEW_VALUE"), value: "acm_add_new",type:"button" });
    //     }
    //     lines.forEach((line, index) => {
    //       const json = line.language_json;
    //       const lang = sAction.fromJSON(sAction.htmlDecode(json));
    //       var value = lang[actLang];
    //       if (value == undefined || value == "" || value == null) {
    //         value = line.get("value");
    //       }
    //       linesOptions.push({ value: line.value, label: value });
    //     });
    //     this.setState({
    //       options: linesOptions,
    //       showForm: true
    //     });
    //     sAction.unLoad();
    //   });
    // }
    selectItem(e) {
        const prefix = this.props.prefix;
        const data = this.props.data;
        const value = e.target.value;

        if (value == 'acm_add_new') {
            this.setState({
                type: 'input',
            });
        } else {
            const params = {
                way: this.props.way,
                name: this.props.data.name,
                value,
            };
            sAction.saveField(params, true);
            sAction.dataSet(this.props.way + '/actEdit', false);
            sAction.clearRelatedDynamicEnums(prefix, data.def.get('options'), this.props.data.name);
        }
    }

    saveInput(e) {
        this.setState({
            isDuplicate: false,
        });
        const data = this.props.data;
        const value = this.input.current.value;
        const keyValue = sAction.normalizeString(value).replace(/ /g, '_');

        if (this.isDuplicate(value, this.state.options)) {
            this.setState({
                inputError: true,
                isDuplicate: true,
            });
            // sAction.error(sAction.translate("LBL_DUPLICATE_VALUE"))
            return;
        }

        if (value == '') {
            this.setState({inputError: true});
        } else {
            const updateData = {
                enum: data.def.get('options'),
                key: keyValue,
                parentValue: this.getParentValue(),
                parent: data.def.getIn(['relData', 'parent']),
                value: value,
            };
            sAction.load();
            sAction.updateDynamicEnum(updateData, (ret) => {
                sAction.unLoad();
                const params = {
                    way: this.props.way,
                    name: this.props.data.name,
                    value: keyValue,
                };
                sAction.app_strings['dynamicEnum'][data.def.get('options')][keyValue] = value;
                sAction.saveField(params, true);
            });
        }
    }

    isDuplicate(value, options) {
        let isDuplicate = false;
        options.forEach((option) => {
            if (option.label.toLowerCase() === value.toLowerCase()) {
                isDuplicate = true;
            }
        });
        return isDuplicate;
    }

    getParentValue() {
        const data = this.props.data;
        const way = this.props.way;
        const parent = data.def.getIn(['relData', 'parent']);
        const fields = sAction.dataGet(way+'/..');
        const enums = {};
        fields.forEach((field) => {
            if (field.def.get('type') == 'AcmDynamicEnum') {
                const id = field.def.get('options');
                const value = field.value;
                enums[id] = value;
            }
        });

        return enums[parent];
    }
    keyUpCall(e) {
        const keyCode = e.keyCode;
        if (keyCode == 13) {
            this.saveInput(e);
        }
    }

    render() {
        if (this.state.isDuplicate) {
            sAction.error(sAction.translate('LBL_DUPLICATE_VALUE'));
        }

        const data = this.props.data;
        const key = data.value;
        let options = [];
        if (sAction.app_strings['dynamicEnum'] && data.def && data.def.get('options') && sAction.app_strings['dynamicEnum'][data.def.get('options')]) {
            options = sAction.app_strings['dynamicEnum'][data.def.get('options')];
        } else {
            console.log('Chybějící dynamický číselník');
        }
        const value = options[key];
        return (
            <React.Fragment>
                {this.state.showForm == true ? (
                    this.state.type == 'enum' ?
                        <Select
                            options={this.state.options}
                            open={true}
                            autoFocus={true}
                            onChange={(e) => this.selectItem(e)}
                            allowSearch={true}
                        /> :
                        <>
                            <div className="inputContainer">
                                <InputText
                                    autoFocus={true}
                                    onKeyUp={(e) => this.keyUpCall(e)}
                                    //  onKeyDown={e => this.onKeyDown(e)}
                                    myRef={this.input}
                                    onBlur={(e) => this.saveInput(e)}
                                    error={this.state.inputError}
                                    //  defaultValue={value}
                                    id={data.name}
                                    name={data.name}
                                    className={this.state.inputError ? 'inputError' : ''}
                                />
                            </div>
                            <div className="buttonContainer">
                                <div
                                    tabIndex="3"
                                    onClick={() => this.cancel()}
                                    className=" inputEditButton"
                                >
                                    <div className={'icon-detailCancel'} />
                                </div>
                            </div>
                        </>
                ) : (
                    <div className="valueContainer hover null">
                        <span id="dynamicenum_c">{value}</span>
                    </div>
                )}
            </React.Fragment>
        );
    }
}
