export default function test_questionnaire(params){
    this.load();
    const d = {
      id : params.id
    }
    this.rest.post("test_questionnaire",d,ret => {
      if (ret.status){
          this.unLoad();
          // this.alert(ret.message.data.link)
          window.open(ret.message.data.link,'_blank');
      }
      else{
          this.unLoad();
          this.alert(ret.errorMessage.text)
      }
    })
}
