import React from "react";
import PureComponent from "../../pure";
import sAction from 'sAction';
import InputText from "../../formElements/InputText";
import Select from "../../formElements/Select";
import Button from "../../formElements/Button";
import AcmDate from "../../formElements/AcmDate";

export default class Send_questionnaire extends PureComponent {
  constructor(props) {
    super(props);
    const defaultEmail = sAction.dataGet("conf/user/email")
    this.state = {
      email:defaultEmail,
    }
  }
  send(){
    const data = this.props.data;
    const callback = data.get("callback");
    if(callback != undefined){
      callback({
        email:this.state.email
      });
    }
  }
  render() {
    
    return (
      <div className="simpleForm">
      <table>
        <tbody>
        <tr>
            <td className="formlabel">{sAction.translate("LBL_EMAIL")}</td>
            <td>
              <InputText
                className="withBorder"
                defaultValue={this.state.email}
                onChange={(e) => this.setState({email:e.target.value})}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className="viewActionPanelButtons flexRight">
                <Button onClick={() => this.props.close()} className='popupCloseButton'>
                  <div className={"actionPanelButtonIcon icon-closeIconLight"} />
                  {sAction.translate("LBL_EXPORT_CLOSE")}
                </Button>
                <Button onClick={() => this.send()}>
                  <div className={"actionPanelButtonIcon icon-download"} />
                  {sAction.translate("LBL_SEND")}
                </Button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    );
  }
}
