import React from "react";
import PureComponent from "../../pure";

import { Map, TileLayer, Marker, Popup } from "react-leaflet";

import Button from "../../formElements/Button";

import sAction from "sAction";
import SearchPlaceDropdown from "./SearchPlaceDropdown";

class OpenStreetMap extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fromState: false,
      item: null,
      target: props.data.get('target') !== undefined ? props.data.get('target') : null,
      title: props.data.get('markerTitle') !== undefined ? props.data.get('markerTitle') : 'Existující adresa',
      x: null,
      y: null,
      sx: null,
      sy: null,
      zoom: false,
      fromSearch: false,
      sidebar: props.data.get('sidebar') !== undefined ? props.data.get('sidebar') : true, // celkove zobrazit postrani panel s moznosti vyhledavani a popisky zobrazenych bodu
      sidebarSearch: props.data.get('sidebarSearch') !== undefined ? props.data.get('sidebarSearch') : true, // moznost skryt vyhledavani v postranim panelu
      clickable: props.data.get('clickable') !== undefined ? props.data.get('clickable') : true, // jestli se pri kliknuti bude automaticky vyhledavat adresa
    };
    this.mapRef = React.createRef();

  }

  handleClick(e) {
    if (this.state.clickable){
      this.setCoordinates(e.latlng.lng, e.latlng.lat);
    }
  }

  setCoordinates(x, y) {
    var data = this.props.data;
    const paramData = { x, y };
    sAction.load();
    sAction.searchAddressByGPS(paramData, item => {
      sAction.unLoad();
      this.setState({
        item: item,
        x,
        y,
        fromState: true
      });
    });
  }
  returnAddress() {
    const item = this.state.item;
    if (item == null) {
      sAction.popupHide();
    } else {
      if (this.props.data.get("callback") != undefined) {
        this.props.data.get("callback")(item);
      }
    }
  }
  returnCoordinates() {
    const x = this.state.x;
    const y = this.state.y;
    const item = {
      cast_obce: "",
      cislo_dom: "",
      cislo_orient: "",
      kraj: "",
      obec: "",
      psc: this.state.item.psc,
      ulice: "",
      x: x,
      y: y
    };
    if (this.props.data.get("callback") != undefined) {
      this.props.data.get("callback")(item);
    }
  }
  changePosition(coordinates) {
    this.setState({sx: coordinates.x, sy: coordinates.y, fromSearch: true, zoom: 12});
  }
  render() {
    var data = this.props.data;

    let spz = '';
    let carID = '';
    let time = '';
    const name = data.get("name");
    if (data.get('item')){
      time = data.get("item").get("time");
      if (data.get("item").get("spz")) {
        spz = data.get("item").get("spz");
        carID = data.get("item").get("carID");
      }
    }

    const item = this.state.item;
    let readonly = data.get("readonly");
    readonly = readonly == true || readonly === 'true';
    const x = this.state.fromState ? item.x : data.get("item").get("gpsX");
    const y = this.state.fromState ? item.y : data.get("item").get("gpsY");
    const title = this.state.title;

    const latlng = {
      lat: y == undefined ? 49.836 : y,
      lng: x == undefined ? 15.549 : x
    };

    if (latlng['lat'] === '') {
      latlng['lat'] = 49.836;
    }
    if (latlng['lng'] === '') {
      latlng['lng'] = 15.549;
    }

    var actLatlng = null;
    if (this.state.fromState) {
      actLatlng = {
        lat: this.state.y,
        lng: this.state.x
      };
    }
    var adresa = null;
    if (this.state.fromState) {
      adresa =  sAction.getStreetlabelAcmAddress(item)

      if(adresa){
       adresa +=  ", " +
         item.obec +
        " " +
        item.psc +
        " " +
        item.kraj;
      }
    } else {
      const item = data.get("item");
      adresa = name !== " " ? name : "";
        if(adresa){
        adresa += ", " +
        item.get("obec") +
        " " +
        item.get("psc") +
        " " +
        item.get("kraj");
        }
    }
    let distance
    if (this.state.target){

      distance = '\n\r Vzdálenost od místa schůzky: '
        +(this.state.target.get('distanceFromCar') / 1000).toFixed(2)
        + 'km';
    }

    const markerIcon = new L.Icon({
      iconUrl: "img/marker.png",
      iconRetinaUrl: "img/marker.png",
      iconAnchor: null,
      popupAnchor: null,
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
      iconSize: new L.Point(55, 55)
    });
    const markerIconRed = new L.Icon({
      iconUrl: "img/markerRed.png",
      iconRetinaUrl: "img/markerRed.png",
      iconAnchor: null,
      popupAnchor: null,
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
      iconSize: new L.Point(55, 55)
    });
    const markerIconGreen = new L.Icon({
      iconUrl: "img/markerGreen.png",
      iconRetinaUrl: "img/markerGreen.png",
      iconAnchor: null,
      popupAnchor: null,
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
      iconSize: new L.Point(55, 55)
    });

    var marker2 =  null;
    var targetLat =  null;
    var targetLan =  null;
    let targetAdresa = "";
    let timeOfEvent = "";

    if (this.state.target){
      let target = this.state.target;
      targetAdresa +=
        target.get('obec') +
        " " +
        target.get('psc') +
        " " +
        target.get('kraj')
      ;

      targetLat = target.get('lat');
      targetLan = target.get('lon');

      const Targetlatlng = {
        lat: targetLat,
        lng: targetLan
      };

      if (target.get('when')){
        timeOfEvent = target.get('when');
      }

      marker2 = <Marker position={Targetlatlng} icon={markerIconGreen} />;
    }

    var marker =  null;

    if (x && y) {
      marker = <Marker position={latlng} icon={markerIcon} />;
    }
    const secondMarker = this.state.fromState ? (
      <Marker position={actLatlng} icon={markerIconRed} />
    ) : null;

    var zoom = 12;
    if (this.state.target){
      zoom = 8;
    }
    if (this.state.zoom) {
      zoom = this.state.zoom;
      this.state.zoom = false;
    } else if (x == '' || (x == undefined && !actLatlng)) {
      zoom = 7;
    }
    var selectDropDown = (
      <SearchPlaceDropdown
        filter={this.state.filter}
        onSelect={gps => this.changePosition(gps)}
      />
    );

    var center = actLatlng ? actLatlng : latlng;
    if (this.state.fromSearch) {
      center = {
        lat: this.state.sy,
        lng: this.state.sx
      };
      this.state.fromSearch = false;
    }

    let activeClass = 'mapButtonActive detailViewSpanButton';
    if (!y || !x) {
      activeClass = 'mapButtonInactive detailViewSpanButton';
    }
    return (
      <div className="openStreetMapContainer">
        <Map
          center={center}
          zoom={zoom}
          style={{ height: "100%", width: "100%" }}
          onClick={e => this.handleClick(e)}
          ref={this.mapRef}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {marker}
          {marker2}
          {secondMarker}
        </Map>
        {this.state.sidebar && (
          <div className="openStreetMapMenu">
          {this.state.sidebarSearch && (selectDropDown)}
            {x && y && (
            <div className="openStreetMapMenuGroup">
            <div className="markerIcon">
              <img src="img/marker.png" />
            </div>
            <div className="openStreetMapMenuGroupInfo">
              <div className="openStreetMapMenuLine">
                <label>{title}</label>
                <div>{adresa}</div>
                <div> <b>{distance}</b></div>
              </div>
              <div className="openStreetMapMenuLine">
                <label>Lat</label>
                <div>{y}</div>
              </div>
              <div className="openStreetMapMenuLine">
                <label>Lng</label>
                <div>{x}</div>
              </div>
              {time &&(
                <div className="openStreetMapMenuLine">
                  <label>Čas získané pozice</label>
                  <div>{time}</div>
                </div>
              )}
              {(spz && carID) &&(
                <div className="openStreetMapMenuLine">
                  <a href={'#customReports/3b35bb9e-f254-4fc3-a939-24ca75672574/?cars='+carID+'&obdOd='+timeOfEvent} target={'_blank'}>Přehled jízd vozu {spz}</a>
                </div>
              )}
              <div className="openStreetMapMenuLine">
              {!readonly && this.state.fromState &&
                <Button
                  className="stillHover"
                  onClick={() => this.returnAddress()}
                >
                  <div className="actionPanelButtonIcon icon-saveIcon" />
                  Použít adresu
                </Button>
              }
              </div>
              <div className="detailViewSpanButtonsContainer">
                <div style={{display: 'flex'}}>
                  <a key={'linkGoogleMaps'}
                     href={'https://www.google.com/maps?saddr=My+Location&daddr=' + y + ',' + x}
                     target={'_blank'}
                     className = {activeClass}>
                    <span className="icon-google_maps"/>
                  </a>
                  <a key={'linkWaze'}
                     href={'https://waze.com/ul?q=66%20Acacia%20Avenue&ll=' + y + ',' + x + '&navigate=yes&zoom=17'}
                     target={'_blank'}
                     className = {activeClass}>
                    <span className="icon-waze"/>
                  </a>
                </div>
              </div>
              </div>
            </div>
            )}
            {this.state.fromState && (
              <div className="openStreetMapMenuGroup">
              <div className="markerIcon">
                <img src="img/markerRed.png" />
              </div>
                <div className="openStreetMapMenuGroupInfo">
                <div className="openStreetMapMenuLine">
                  <label>Přesné souřadnice</label>
                </div>
                <div className="openStreetMapMenuLine">
                  <label>Lat</label>
                  <div>{actLatlng.lat}</div>
                </div>
                <div className="openStreetMapMenuLine">
                  <label>Lng</label>
                  <div>{actLatlng.lng}</div>
                </div>
                <div className="openStreetMapMenuLine">
                  {!readonly && (
                    <Button
                      className="stillHover"
                      onClick={() => this.returnCoordinates()}
                    >
                      <div className="actionPanelButtonIcon icon-saveIcon" />
                      Použít souřadnice
                    </Button>
                  )}
                </div>
                <div className="detailViewSpanButtonsContainer">
                  <div style={{display: 'flex'}}>
                    <a key={'linkGoogleMaps'}
                       href={'https://www.google.com/maps?saddr=My+Location&daddr=' + y + ',' + x}
                       target={'_blank'}
                       className = {activeClass}>
                      <span className="icon-google_maps"/>
                    </a>
                    <a key={'linkWaze'}
                       href={'https://waze.com/ul?q=66%20Acacia%20Avenue&ll=' + y + ',' + x + '&navigate=yes&zoom=17'}
                       target={'_blank'}
                       className = {activeClass}>
                      <span className="icon-waze"/>
                    </a>
                  </div>
                </div>
                </div>
              </div>
            )}
            {this.state.target && (
              <div className="openStreetMapMenuGroup">
              <div className="markerIcon">
                <img src="img/markerGreen.png" />
              </div>
                <div className="openStreetMapMenuGroupInfo">
                  <div className="openStreetMapMenuLine">
                    <label>{this.state.target.get("markerName")}</label>
                    <div>{targetAdresa}</div>
                  </div>
                  <div className="openStreetMapMenuLine">
                    <label>Lat</label>
                    <div>{targetLat}</div>
                  </div>
                  <div className="openStreetMapMenuLine">
                    <label>Lng</label>
                    <div>{targetLan}</div>
                  </div>
                  {timeOfEvent &&(
                    <div className="openStreetMapMenuLine">
                      <label>Plánovaný čas schůzky</label>
                      <div>{timeOfEvent}</div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}


export default OpenStreetMap;
