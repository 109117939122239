/**
 * Inicializuje stranku s mergem. Z BE se vrati hodnoty vsech vyplnenych poli, jejich definice a definice vsech poli.
 * Pokud se stranka refreshne ztrati se vsechny data posilana z listu, tudiz se data pokazde ulozi do local storage
 * V routeMerge.js se predavaji data z localu pouze pokud se stranka refreshla.
 * @param params
 */
export default function mergeInit(params) {
    const module = params.module;

    if (!this.hasAccess(module, "edit")) {
        this.error(this.translate("LBL_ERROR_MERGE_NOT_PERMITTED", "ACL"));
        return;
    }

    let selected = params.records ? params.records : null;
    let filterData = params.filter ? params.filter : null;

    if (params.buttonId) {
        const list = this.dataGet(params.prefix);

        if (list.selected === "all") {
            const savedSearch = list.actSavedSearch;
            filterData = this.reloadListParams({
                list,
                prefix: params.prefix,
                savedSearch,
                columns: false,
            });
            filterData["selected"] = list.selected;
            filterData["module"] = list.modul;
            filterData["type"] = list.type;
            selected = "all";
        } else {
            selected = list.selected?.toJS();
        }
    }


    let recordParams = {
        records: selected,
        module: module,
        filter: filterData,
        checkDelete: true,
        prefix: params.prefix
    }

    if (selected) {
        localStorage.setItem("merge_params", null)
        localStorage.setItem("merge_params", JSON.stringify(recordParams))
    }


    this.load();
    this.rest.post("hasAccess", recordParams, ret => {
        if (!ret) {
            this.unLoad();
            this.error(this.translate("LBL_ERROR_MERGE_NOT_PERMITTED", "ACL"));
            return
        }

        window.location.hash = "merge";
        this.rest.post("mergeInit", recordParams, data => {
            this.dsClear();
            this.dsAdd("set", "conf/load", false);
            this.dsAdd("set", params.prefix, {}, "mergeRecords");

            data.def.forEachObject((def, key) => {
                this.dsAdd("set", params.prefix + "/def/" + key, def);
            });
            data.filteredDef.forEachObject((def, key) => {
                this.dsAdd("set", params.prefix + "/filteredDef/" + key, def);
            });
            data?.customFields?.forEachObject((def, key) => {
                this.dsAdd("set", params.prefix + "/customFields/" + key, def);
            });
            this.dsAdd("set", params.prefix + "/sameValues", data.sameValues);

            let index = 0;
            data.records.forEachObject(record => {
                this.dsAdd("set", params.prefix + "/records/" + index, {});
                record.forEachObject((value, field) => {
                    this.dsAdd("set", params.prefix + "/records/" + index + "/" + field, value);
                });
                index += 1;
            })
            this.dsAdd("set", params.prefix + "/module", module);
            this.dsProcess();
        });
    });
}