import React from "react";
import PureComponent from "../pure";

import TabContainer from "../formElements/TabContainer";
import DetailTabContent from "./DetailTabContent";
import ViewPanelMenu from "./../viewPanelMenu/viewPanelMenu";
import Subpanels from "../subpanel/Subpanels";

import Button from "../formElements/Button";

import DetailCustomComponent from "./DetailCustomComponent";
import DetailInvTabComponent from "./DetailInvTabComponent";
import DetailTimeline from "./DetailTimeline";

import sAction from "sAction";
import CustomDetailLines from "./CustomLines/CustomDetailLines";
import DetailDashboard from "./DetailDashboard";
import DetailViewActivityButtons from "./DetailViewActivityButtons";
import Tags from "../Tags/Tags";
import DetailAclAccessTabComponent from "./Users/DetailAclAccessTabComponent";
import PermissionAccess from "./Permissions/PermissionAccess";
import CustomDataTop from "./CustomDataTop/CustomDataTop";
import BoundModules from "./BoundModules/BoundModules";
import DetailAccountsTurnoverTab from "./Accounts/DetailAccountsTurnoverTab";
import DetailProductsTab from "ROOT/src/components/detailView/Accounts/DetailProductsTab";

class Detail extends PureComponent {
  constructor(props) {
    super(props);
    this.rightPanelCustom = ["Meetings", "Calls"];
    this.detail = React.createRef();
    this.uId = sAction.dataGet("conf/user/id");

    sAction.dataSet("conf/viewType", "desktop");
  }
  state = {
    contextMenu: null,
  };

  UNSAFE_componentWillMount() {
    sAction.fieldsForValidation = new Array(); // vyprázdnění pole pro validaci
    // kontrola, zda nemame neulozene zmeny ve storage
    var way = this.props.way;

    sAction.setStorage("stopChanges", "0");
    var foundChanges = sAction.getStorage(
      "changes-" + this.props.data.module + "-" + this.uId
    );
    if (
      foundChanges &&
      (Object.keys(foundChanges.changes.fields).length !== 0 ||
        Object.keys(foundChanges.changes.customData).length !== 0) &&
      foundChanges.expiration - Date.now() > 0 &&
      this.props.view !== "calendar" &&
      this.props.data.id === foundChanges.id
    ) {
      // pokud mame zaznam o neulozene zmene, tak ho vyuzijeme
      sAction.dsClear();
      var changesFields = foundChanges.changes.fields;
      changesFields.forEachObject(function (value, index) {
        if (index === "relate") {
          value.forEachObject(function (value, index) {
            sAction.dsAdd("set", way + "/fields/" + index + "/value", value);
          });
        } else if (index === "parent_type") {
          sAction.dsAdd(
            "set",
            way + "/fields/parent_name/def/parent_type_value",
            value
          );
        } else if (index === "ss") {
          var res = value.split("|");
          sAction.dsAdd("set", way + "/fields/ss/def/selected", res);
        }

        if (sAction.dataGet(way + "/fields/" + index)) {
          sAction.dsAdd("set", way + "/fields/" + index + "/value", value);
        }
        if (sAction.dataGet(way + "/fields/email1")) {
          sAction.dsAdd(
            "set",
            way + "/fields/email1/def/emails",
            foundChanges.emails
          );
        }
      });
      sAction.dsAdd("set", way + "/changes", foundChanges.changes);
      sAction.dsAdd("set", way + "/changes/forceChange", true);
      if (
        foundChanges.module === "Quotes" ||
        foundChanges.module === "Opportunities"
      ) {
        var akce = {
          type: "DETAILLOCALSTORAGERESTORE",
          content: foundChanges,
          prefix: way,
        };
        sAction.dsAddCustom(akce);
      } else {
        // pokud bychom uz meli ve storu customData a zaroven mame null v LS
        // tak je nesmysl ho tam davat misto dat co uz tam jsou
        if (foundChanges.customData !== null) {
          sAction.dsAdd("set", way + "/customData", foundChanges.customData);
        }
      }
      sAction.dsProcess();
    }
  }
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyEvent);

    const data = this.props.data;
    sAction.fillDetailPredefinedFields(this.props.way, data.id);

    sAction.custom("load", {
      prefix: this.props.way,
      module: data.module,
    });

    sAction.checkDetailUrl(this.props.way);
    sAction.detailTempData[this.props.way] = {
      tabChange: true,
    };
    document.onkeydown = (e) => {
      sAction.detailOnKeyDown(e, this.props);
    };
  }

  handleKeyEvent = (e) => {
    if (e.keyCode !== 9) {
      return;
    }
    const found = this.props.data.fields?.find((field) => {
      return field.actEdit;
    });
    if (found === undefined) {
      e.preventDefault();
      e.stopPropagation();
      sAction.detailTabNext({
        name: '',
        prefix: this.props.way,
        index: -1,
        rowWay: this.props.way + '/tabs/' + this.props.data.activeTab + '/rows/0',
        shift: false,
      }, false);
    }
  }
  componentWillUpdate() {
    var storeChanges = this.props.data.changes;
    var forceChange = storeChanges.get("forceChange");

    if (
      (sAction.getStorage("stopChanges") === "0" ||
        !sAction.getStorage("stopChanges")) &&
      (forceChange ||
        storeChanges.get("fields").size > 0 ||
        storeChanges.get("files").length > 0)
    ) {
      console.log("---------- ukladam zmeny do localStorage");

      const disabledModules = ["Emails"];

      var date = new Date();
      date.setDate(date.getDate() + 1); //+1 den

      if (!disabledModules.includes(this.props.data.module)) {
        var changes = {
          expiration: date.getTime(date),
          id: this.props.data.id,
          name: this.props.data.name,
          module: this.props.data.module,
          changes: this.props.data.changes,
          customData: this.props.data.customData,
        };
        if (this.props.data.fields.get("email1")) {
          changes.emails = this.props.data.fields
            .get("email1")
            .get("def")
            .get("emails");
        }
        sAction.setStorage(
          "changes-" + this.props.data.module + "-" + this.uId,
          sAction.toJSON(changes)
        );
      }
    }
  }

  componentDidUpdate(prevProps) {
    const data = this.props.data;
    let set_assigned_user = false;
    if (prevProps.data.module != this.props.data.module) {
      sAction.checkDetailUrl(this.props.way);
      var container = document.querySelector(
        ".pageContainer .detailViewContainer"
      );
      container.scrollTop = 0;
    }
    if (prevProps.data.id != this.props.data.id) {
      sAction.custom("load", {
        prefix: this.props.way,
        module: this.props.data.module,
      });
      set_assigned_user = true;
    }
    sAction.fillDetailPredefinedFields(
      this.props.way,
      data.id,
      set_assigned_user
    );

    if (data.doCustom == true) {
      sAction.custom("load", {
        prefix: this.props.way,
        module: this.props.data.module,
      });
    }
    //____________________________________________________Zkontroluje špatné fieldy a pdoel toho vyznačí červeně celý tab
    const errors = this.detail.current.querySelectorAll(
      ".valueContainer.error"
    );
    this.detail.current
      .querySelectorAll(".acmTabContainerButton")
      .forEach((tab) => {
        tab.classList.remove("error");
      });
    errors.forEach((item) => {
      var tabId = item.closest(".detailViewTabContentBlok").dataset.index;
      const tab = this.detail.current.querySelector(
        ".acmTabContainerButton[data-index='" + tabId + "']"
      );
      tab.classList.add("error");
    });

    //____________________________________________________END
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyEvent);
    let way = this.props.way;
    sAction.detailTempData[way] = null;

    const data = sAction.dataGet(way);
    if (!data) {
      return;
    }

    if (way.includes("calendar")) {
      sAction.removeChangesFromLS(data.module, sAction.dataGet("conf/user/id"));
    } else {
      sAction.removeChangesFromLS(data.modul, sAction.dataGet("conf/user/id"));
    }
  }

  tabChange(value) {
    sAction.changeDetailTab(value, this.props.way, this.props.data.activeTab,this.props.data.fields);
  }

  render() {
    const data = this.props.data;
    const module = data.module;
    const way = this.props.way;
    const view = this.props.view;
    const hideHeader = this.props.hideHeader;
    const mode = data.mode;
    var renderDataTabContent = [];
    var activeTab = null;

    var tabs = [];
    data.tabs.forEach((tab, index) => {
      if (tab.hidden) {
        // pro dynamicke skryvani/odkryvani tabu za pochodu dle aktualne vybranych dat na zaznamu
        tabs.push("");
        return;
      }

      if (mode == "edit") {
        tabs.push(
          <div className="detailViewEditTabContainer">
            {sAction.translate(tab.name.toUpperCase(), module)}
            <div
              onClick={(e) =>
                sAction.editDetailEditTab(
                  e,
                  tab.name.toUpperCase(),
                  module,
                  way + "/tabs/" + index
                )
              }
              className="icon-EditField detailViewEditTabButton"
              title={sAction.translate("LBL_EDIT_NAME")}
            />
            <div
              onClick={(e) => sAction.editDetailDeleteTab(e, tab, way, index)}
              className="icon-deleteIcon detailViewEditTabButton"
              title={sAction.translate("LBL_DELETE")}
            />
          </div>
        );
      } else {
        tabs.push(sAction.translate(tab.name.toUpperCase(), module));
      }

      if (tab.active == true) {
        activeTab = index;
      }

      if (tab.type == "standard") {
        renderDataTabContent.push(
          <DetailTabContent
            prefix={way}
            module={module}
            index={index}
            way={way + "/tabs/" + index}
            key={tab.id}
            data={tab}
            rowCount={data.rowCount}
            id={data.id}
            fields={data.fields}
            readonly={data.readonly}
            acl={data.acl}
            mode={mode}
          />
        );
      } else if (tab.type == "invitees") {
        renderDataTabContent.push(
          <DetailInvTabComponent
            active={tab.active}
            module={module}
            id={data.id}
            way={way + "/customData"}
            data={data.customData}
            key={tab.id}
          />
        );
      } else if (
        tab.type == "acl_access" &&
        sAction.dataGet("conf/user/is_admin") == 1
      ) {
        renderDataTabContent.push(
          <DetailAclAccessTabComponent
            active={tab.active}
            module={module}
            id={data.id}
            way={way + "/customData"}
            data={data.customData}
            key={tab.id}
          />
        );
      } else if (tab.type == 'account_turnover') {
        renderDataTabContent.push(
            <DetailAccountsTurnoverTab
                active={tab.active}
                module={module}
                id={data.id}
                way={way + "/customData"}
                data={data.customData}
                key={tab.id}
            />
        );
      } else if (tab.type === "account_products"){
        renderDataTabContent.push(
            <DetailProductsTab
                active={tab.active}
                module={module}
                id={data.id}
                way={way + "/customData"}
                data={data.customData}
                key={tab.id}
            />
        )
      }
    });

    if (mode == "edit") {
      tabs.push(
        <div
          onClick={(e) =>
            sAction.detailEditAddNewTab(e, way, module, data.tabs)
          }
          className="addTabButton"
          title={sAction.translate("LBL_ADD_TAB_TITLE")}
        >
          {"+"}
        </div>
      );
    }

    const isNewRecord = !data.id;
    const header = (
      <div className="header">
        <div className="title">
          <span className={"detailViewHeaderIcon icon-" + data.module} />
          <span className="detailViewName">
            {isNewRecord
              ? sAction.translate("LBL_NEW_RECORD")
              : sAction.decodeHTMLEntities(data.name)}
          </span>
        </div>
        <Tags way={way} record={data.id} module={module} tags={data.tags} />
        {!isNewRecord && (
          <DetailViewActivityButtons
            activities={data.activities}
            prefix={way}
            parentId={data.id}
            parentModule={data.module}
            parentName={data.name}
          />
        )}
      </div>
    );
    return (
      <div className="pageContainer DetailViewMainContainer">
        {data.type != "rightPanel" && data.type != "calendar" && (
          <ViewPanelMenu data={data} prefix={way} />
        )}
        <div className="detailViewContainer">
          <div className="detailCard">
            {hideHeader !== true && header}
            <div className="detailView" ref={this.detail}>
              {data.customDataTop != null && (
                <CustomDataTop data={data.customDataTop} module={module} />
              )}
              {data.timeline != null &&
                data.type != "popup" &&
                data.type != "calendar" && (
                  <DetailTimeline
                    id={data.id}
                    data={data.timeline}
                    module={data.module}
                    way={way}
                    field={data.fields.get(data.timeline.field)}
                    type={data.type}
                  />
                )}
              {data.dashboard != null && data.type != "rightPanel" && (
                <DetailDashboard
                  data={data.dashboard}
                  id={data.id}
                  module={data.module}
                  way={way + "/dashboard"}
                />
              )}
              <div style={{position:"relative"}}>
                <TabContainer
                  onChange={(e, value) => this.tabChange(value)}
                  value={activeTab}
                  tabs={tabs}
                />
                {((sAction.userPreferences.is_admin == 1 ||
                  sAction.userPreferences.is_superUser == 1)
                  && sAction.clientDetailEdit === true
                  ) && (
                  <div className="editDetailButton">
                    {mode == "edit" ? (
                      <>
                        <Button
                          onClick={() => {
                            sAction.load();
                            sAction.routeDetailView();
                          }}
                        >
                        <div className="icon-undo buttonIcon"></div>
                          {sAction.translate("LBL_REMOVE_CHANGES")}
                        </Button>
                        <Button onClick={() => sAction.saveEditedDetail(way)}>
                          <div className="icon-saveIcon buttonIcon"></div>
                          {sAction.translate("LBL_SAVE_RECORD")}
                        </Button>
                        <Button onClick={() => sAction.detailEditDefaultLayout(way)}>
                          <div className="icon-defaultLayout buttonIcon"></div>
                          {sAction.translate("LBL_DEFAULT_LAYOUT")}
                        </Button>
                      </>
                    ) : (
                      <div
                        className="icon-EditField startEditButton"
                        onClick={() => sAction.detailEditStart(data,way)}
                      />
                    )}
                  </div>
                )}
              </div>
              <div
                className={
                  mode == "edit"
                    ? "detailViewTabContent edit"
                    : "detailViewTabContent"
                }
              >
                {renderDataTabContent}
                {mode == "edit" && (
                  <div className="detailViewHiddenFieldsContainer">
                    <div className="detailViewHiddenFieldsContainerList">
                      <div
                        onClick={() => sAction.editFieldsNewField(way, module)}
                        className={"hiddenFieldItem hiddenFieldButton"}
                      >
                        {sAction.translate("LBL_DETAIL_EDIT_NEW_FIELD_BUTTON")}
                      </div>
                      <div
                        onClick={() =>
                          data.get("hiddenFieldButton")
                            ? sAction.hiddenField(way)
                            : null
                        }
                        className={
                          data.get("hiddenFieldButton")
                            ? "hiddenFieldItem hiddenFieldButton"
                            : "hiddenFieldItem hiddenFieldButton disabled"
                        }
                      >
                        {sAction.translate(
                          "LBL_DETAIL_EDIT_NEW_FIELD_HIDE_BUTTON"
                        )}
                      </div>
                      {data.get("hiddenFields").map((field, id) => {
                        if (field != "empty_row") {
                          const aSelect = data.getIn([
                            "editFieldsPosition",
                            "a",
                            "rowWay",
                          ]);
                          var className = "hiddenFieldItem";
                          if (aSelect == way + "/hiddenFields/" + id) {
                            className += " select";
                          }
                          return (
                            <div key={id} className={className} title={sAction.translate(
                                data.fields.getIn([field, "def", "vname"])
                              )}>
                              <div>
                              <div
                                className="icon-editDetailFieldPosition editDetailFieldPosition"
                                onClick={() =>
                                  sAction.editFieldPosition(
                                    true,
                                    way + "/fields/" + field,
                                    way + "/hiddenFields/" + id,
                                    way
                                  )
                                }
                              ></div>
                              {sAction.translate(
                                data.fields.getIn([field, "def", "vname"])
                              )}
                              <span>{" ("+data.fields.getIn([field, "def", "type"])+")"}</span>
                              </div>
                              <div title={sAction.translate("LBL_EDIT_FIELD")} className="icon-EditField editDetailFieldPosition" onClick={() => sAction.editFieldToEdit(way,module,data.fields.getIn([field, "def"]))}></div>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {data.customData != null && data.type != "popup" && (
            <DetailCustomComponent
              view={view}
              module={module}
              id={data.id}
              way={way + "/customData"}
              prefix={way}
              data={data.customData}
              readonly={data.readonly}
              type={data.type}
            />
          )}
          {data.module == "acm_coripoReports" &&
            data.type != "popup" &&
            data.type != "calendar" && (
              <PermissionAccess
                way={way}
                acl={data.acl}
                view={view}
                data={data.customData}
              />
            )}
          {data.get &&
            data.get("customData") != null &&
            data.get("customData").get &&
            data.get("customData").get("customLines") &&
            data.type != "rightPanel" &&
            data.type != "popup" && (
              <CustomDetailLines
                view={view}
                module={module}
                way={way}
                id={data.id}
                data={data.get("customData").get("customLines")}
                type={data.type}
                acl={data.acl}
              />
            )}
          {!data.id && data.boundModules && <BoundModules data={data.boundModules} way={way} prefix={way + '/boundModules'} acl={data.acl} />}
          {data.id != null && (
            <Subpanels data={data.subpanels} way={way} acl={data.acl} />
          )}
        </div>
      </div>
    );
  }
}

export default Detail;
