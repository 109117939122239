export default function kampanImportPolozky(data){
    var input = document.createElement('input');
    input.type = "file";
    input.style = "display:none";
    // input.multiple = true;
    input.onchange = (e) => {
      const sendData = {
        data: {
          module: data.module,
          id: data.id,
          files: e.target.files,
        },
        action: "importAudience",
        responseType: "json",
        success: response => {
          this.unLoad();

          if(response.status == "ok"){

            this.route()

            this.alert(this.translate("LBL_IMPORTED_ROWS_CNT", "ProspectLists")+ ": "+response.imported_count+
              '<br>'+this.translate("LBL_IMPORTED_ROWS_EXISTED", "ProspectLists")+': '+ response.existed+
              '<br>'+this.translate("LBL_IMPORTED_ROWS_EXISTED_LINKED", "ProspectLists")+': '+ response.existed_linked+
              '<br>'+this.translate("LBL_IMPORTED_ROWS_FAILEDNOEMAIL", "ProspectLists")+': '+ response.failedNoEmail+
              '<br>'+this.translate("LBL_IMPORTED_ROWS_FAILEDTOIMPORTCOUNT", "ProspectLists")+': '+ response.failedToImportCount
              // +'<br>'+ response.failedToImport // vypis konkrétních emailů na ktere se nezpracovali
            );
          }
          else{
            this.error(response.message);
          }
        },
        progress: progress => {
        }
      }
      this.load();
      this.filesXhr(sendData);
    }
  
    setTimeout(function () {
      input.click();
    }, 200);
  
}
