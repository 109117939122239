
export default function setWrongFieldClass(fields, prefix){
    fields?.forEach(field => {
        const error = this.dataGet(prefix + '/fields/' + field + '/def/error')
        if(error){
            this.dataSet(prefix + "/fields/" + field + "/customClass", 'nullValue error');
        } else {
            this.dataSet(prefix + "/fields/" + field + "/customClass", '');
        }
    })
}