export default function openDynamicEnum(way, data, callback) {
    const enumId = data.def.get('options');
    const parent = data.def.getIn(['relData', 'parent']);
    const fields = this.dataGet(way+'/..');
    const enums = {};
    if (data.def.get?.('name')?.split?.('_')[1] === 'address' &&
        data.def.get?.('name')?.split?.('_')[2] === 'kraj'
    ) {
        enums[data.def.get?.('options')] = data.value;
        const countryField = fields.get(data.def.get?.('name')?.replace('kraj', 'country'));
        enums[countryField?.get?.('def')?.get?.('options')] = countryField?.get?.('value');
    } else {
        fields.forEach((field) => {
            if (field.def?.get('type') == 'AcmDynamicEnum') {
                const id = field.def.get('options');
                const value = field.value;
                enums[id] = value;
            }
        });
    }
    const params = {
        enum: enumId,
        parentValue: enums[parent],
        enums,
    };
    this.rest.post('dynamicEnumList', params, (returnData) => {
        returnData['lines'] = returnData['lines'].sort((a, b) => {
            if (typeof a === 'object' && typeof b === 'object') {
                return a.language_json['cs_CZ'].localeCompare(b.language_json['cs_CZ']);
            }
            return JSON.parse(a.language_json)['cs_CZ'].localeCompare(JSON.parse(b.language_json)['cs_CZ']);
        });
        callback(returnData);
    });
}
