import React from "react";
import FilterBasic from "./filterBasic";

import MultiSelect from '../../formElements/MultiSelect'
import TabContainer from "../../formElements/TabContainer";
import  sAction  from "sAction";

export default class FilterEnum extends FilterBasic {
    constructor(props){
        super(props);

        const actFilter = this.props.actFilter
        var operatorTab = 0
        if(actFilter != null){
            if(actFilter.get("operator") == "or"){
                operatorTab = 1
            }
        }
        this.state = {
            operatorTab
        }

    }
    componentDidUpdate() {
        const actFilter = this.props.actFilter;
        if (actFilter == null || actFilter.actCustom == false) {
            this.props.parent.normal();
        } else {
            this.props.parent.advanced();
        }
    }
    multiEnumValue(values){
        const data = this.props.data
        const parentType = this.props.parent.props.def.get('type');
        let type = 'contArray';
        if (parentType === 'multienum') {
            type = 'contLikeArray';
        }
        if(this.state.operatorTab == 0){
            this.addFilter(data.get("name"), type, values)
        }else{
            const prefix = this.props.prefix;
            const field = data.get("name")
            var filters = [];
            values.forEach(value => {
                filters.push({
                    field:field,
                    type:"cont",
                    value:value
                })
            })
            const groupOperator = "or"
            const params = {
                field:field,
                filters:filters,
                operator:groupOperator,
                prefix,
                reload: true
            };
            sAction.addFilter(params);
        }
    }

    render() {
        const data = this.props.data;
        const actFilter = this.props.actFilter

        var defaultValue = null;
        if(actFilter != null){
            if(actFilter.get("operator") == "or"){
                defaultValue = []
                actFilter.get("filters").forEach(filter => {
                    defaultValue.push(filter.get("value"))
                })
            }else{
                defaultValue = actFilter.filters.get(0).value;
            }

        }

        const options = sAction.app_strings[data.get("options")];
        var optionsToSelect = [];
        for (var key in options) {
            if (options.hasOwnProperty(key)) {
                var value = options[key];
                optionsToSelect.push({ value: key, label: value });
            }
        }


        const custom = [
            <div className="filterEnumSelectContainer" key="select">
                {data.get("type") == "multienum" && (
                    <TabContainer tabs={["AND","OR"]} value={this.state.operatorTab}
                                  onChange={(e, value) => this.setState({ operatorTab: value })}
                    />
                )}
                <MultiSelect
                    ref="input"
                    id={data.get("options")}
                    options={optionsToSelect}
                    defaultValue={defaultValue}
                    open={false}
                    valueFromProps={true}
                    onBlur={values => this.multiEnumValue(values)}
                />
            </div>
        ];

        var removeFilterButton = null;
        if (actFilter != null) {
            removeFilterButton = (
                <div
                    onClick={() => this.removeFilter(data.get("name"))}
                    className="filterButton cancelFilterButton"
                >
                    {sAction.translate("LBL_DELETE_FILTER")}
                </div>
            );
        }
        return (
            <div>
                <div
                    onClick={() => this.order(data, 1)}
                    className="filterButton"
                >
                    {sAction.translate("LBL_ORDER_AZ")}
                </div>
                <div
                    onClick={() => this.order(data, 0)}
                    className="filterButton"
                >
                    {sAction.translate("LBL_ORDER_ZA")}
                </div>
                <div
                    onClick={() => this.addFilter(data.get("name"), "nnull", null)}
                    className="filterButton"
                >
                    {sAction.translate("LBL_CONTAINS_DATA")}
                </div>
                <div
                    onClick={() => this.addFilter(data.get("name"), "null", null)}
                    className="filterButton"
                >
                    {sAction.translate("LBL_NOT_CONTAINS_DATA")}
                </div>
                {custom}
                {removeFilterButton}
            </div>
        );
    }
}
