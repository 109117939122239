import React from "react";
import FilterBasic from "./filterBasic";

import TabContainer from "../../formElements/TabContainer";
import RadioGroup from "../../formElements/RadioGroup";

import  sAction  from "sAction";
import InputText from "../../formElements/InputText";
import Select from "../../formElements/Select";

export default class FilterNumber extends FilterBasic {
  constructor(props) {
    super(props);

    const actFilter = this.props.actFilter;
    var lines = [];
    if (actFilter && actFilter.filters) {
      actFilter.filters.forEach((line, key) => {
        lines[key] = {
          select: line.type,
          input: line.value,
        };
      });
    }
    this.state = {
      activeTab: 0, // filter/calc
      lines: lines,
    };

    this.customOperator = React.createRef();
  }

  addCustomFilterLineToView() {
    const data = this.props.data;
    const prefix = this.props.prefix;
    const way = this.props.way;
    const index = this.props.index;
    if (prefix == way) {
      sAction.dsClear();
      sAction.dsAdd(
          "add",
          prefix + "/filter",
          { field: data.get("name"), operator: "and", actCustom: true },
          "filterGroup"
      );
      sAction.dsAdd(
          "add",
          prefix + "/filter/" + index + "/filters",
          { type: null, value: "" },
          "filterParameter"
      );
      sAction.dsProcess();
    } else {
      sAction.dsClear();
      sAction.dsAdd(
          "add",
          way + "/filters",
          { type: null, value: "" },
          "filterParameter"
      );
      sAction.dsProcess();
    }
  }
  deleteCustomFilterLineFromView(key) {
    const way = this.props.way;
    sAction.dataDelete(way + "/filters", key);
  }

  customOption() {
    this.props.parent.advanced();
    const data = this.props.data;
    const prefix = this.props.prefix;
    const way = this.props.way;
    const index = this.props.index;
    if (prefix == way) {
      sAction.dsClear();
      sAction.dsAdd(
          "add",
          prefix + "/filter",
          { field: data.get("name"), operator: "and", actCustom: true },
          "filterGroup"
      );
      sAction.dsAdd(
          "add",
          prefix + "/filter/" + index + "/filters",
          { type: null, value: "" },
          "filterParameter"
      );
      sAction.dsProcess();
    }
  }

  addCustomFilter(field) {
    const prefix = this.props.prefix;
    var filters = new Array();
    var lines = this.state.lines;
    lines.forEach(line => {
      const select = line.select;
      const text = line.input;
      var filter = {
        field: field,
        type: select,
        value: text
      };
      filters.push(filter);
    });
    const operator = this.customOperator.current.value;
    const data = {
      field,
      filters,
      operator,
      prefix,
      reload: true
    };
    sAction.addFilter(data);
  }

  updateLines(key, value, input) {
    var newLine = this.state.lines;
    var property = newLine[key] ? newLine[key] : {};
    property[input] = value;
    newLine[key] = property;
    this.setState({
      lines: newLine
    })
  }

  render() {
    const data = this.props.data;
    const actFilter = this.props.actFilter;
    var custom = null;
    if (actFilter == null || actFilter.actCustom == false) {
      custom = (
          <div onClick={() => this.customOption()} className="filterButton">
            {sAction.translate("LBL_CUSTOM_FILTER")}
          </div>
      );
    } else {
      var customLines = [];
      if (actFilter != null) {
        actFilter.filters.forEach((line, key) => {
          customLines.push(
              <div className="filterLine" key={key}>
                <Select
                    key={actFilter.field + "_sekect"}
                    defaultValue={line.type}
                    options={sAction.getNumberFilterOptions()}
                    onChange={(e) =>
                        this.updateLines(key, e.target.value, 'select')
                    }
                />
                <InputText
                    key={actFilter.field + "_input"}
                    type="text"
                    defaultValue={line.value}
                    onChange={(e) =>
                        this.updateLines(key, e.target.value, 'input')
                    }
                />
                {customLines.length != 0 && (
                    <div
                        className="customFilterDeleteRow iconCancel"
                        onClick={() => this.deleteCustomFilterLineFromView(key)}
                    />
                )}
              </div>
          );
        });
      }
      custom = (
          <div className="customFilter">
            <div className="customFilterheader">
              {sAction.translate("LBL_CUSTOM_FILTER")}
            </div>
            <div className="CustomFilterLinesContent">
              <div className="CustomFilterLines">
                <div ref="customLines">{customLines}</div>
                <div
                    className="filterLine customFilterAddLineButton"
                    onClick={() => this.addCustomFilterLineToView()}
                >
                  +
                </div>
              </div>
              <div className="customFilterLinesOperator">
                <Select
                    key={actFilter.field + "_groupSelect"}
                    defaultValue={actFilter.operator}
                    myRef={this.customOperator}
                    options={[{value: 'and', label: sAction.translate("LBL_AND")},{value: 'or', label: sAction.translate("LBL_OR")}]}
                />

              </div>
            </div>

            <div
                tabIndex="2"
                onClick={() => this.addCustomFilter(data.get("name"))}
                className="filterCustomFilterButton"
            >
              {sAction.translate("LBL_LIST_FILTER")}
            </div>
          </div>
      );
    }
    var removeFilterButton = null;
    if (actFilter != null) {
      removeFilterButton = (
          <div
              onClick={() => this.removeFilter(data.get("name"))}
              className="filterButton cancelFilterButton"
          >
            {sAction.translate("LBL_DELETE_FILTER")}
          </div>
      );
    }

    return (
        <div className="filterContent">
          <TabContainer
              className="listViewFilter"
              onChange={(e, value) => this.setState({ activeTab: value })}
              value={this.state.activeTab}
              tabs={[
                sAction.translate("LBL_FILTER_HEADER_FILTER"),
                sAction.translate("LBL_FILTER_HEADER_CALC")
              ]}
          />
          {this.state.activeTab == 0 && (
              <div>
                <div
                    onClick={() => this.order(data, 0)}
                    className="filterButton"
                >
                  {sAction.translate("LBL_ORDER_MIN")}
                </div>
                <div
                    onClick={() => this.order(data, 1)}
                    className="filterButton"
                >
                  {sAction.translate("LBL_ORDER_MAX")}
                </div>
                <div
                    onClick={() => this.addFilter(data.get("name"), "nnull", null)}
                    className="filterButton"
                >
                  {sAction.translate("LBL_IS_NOT_ZERO")}
                </div>
                <div
                    onClick={() => this.addFilter(data.get("name"), "null", null)}
                    className="filterButton"
                >
                  {sAction.translate("LBL_IS_ZERO")}
                </div>
                {custom}
                {removeFilterButton}
              </div>
          )}
          {this.state.activeTab == 1 && (
              <div className="listViewFilterCalcContainer">
                <RadioGroup options={{ SUM: "Suma", COUNT: "Počet", AVG: "Průměr" }}
                            onChange={e => this.addFieldFunction(e)}
                />
              </div>
          )}
        </div>
    );
  }
}
