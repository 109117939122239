import React from "react";
import PureComponent from "../../pure";
import sAction from "../../../action"

import Tree from 'react-d3-tree';
import 'regenerator-runtime/runtime'
import {fromJSON} from "../../../action/exportConfig"
import MixedNodeElement from './accHieratchy/MixedNodeElement';
import Loader from "../../loader";
import './accHieratchy/AccHieratchyStyle.less';

export default class AccHierarchyPopup extends PureComponent {

    constructor(){
        super();

        const customNodeFnMapping =({ nodeDatum, toggleNode }, appState) => (
            <MixedNodeElement
                nodeData={nodeDatum}
                triggerNodeToggle={toggleNode}
                foreignObjectProps={{
                    width: appState.nodeSize.x * 1.2,
                    height: appState.nodeSize.y * 2,
                    x: 10,
                    y: -70,
                }}
            />
        );

        let centerPosition = {
            x: window.innerWidth * 0.8 / 2,
            y: 60,
        }

        this.state = {
            loaded: false,
            data: null,
            orientation: 'vertical',
            dimensions: undefined,
            centeringTransitionDuration: 800,
            translateX: centerPosition.x,
            translateY: centerPosition.y,
            depthFactor: 400,
            collapsible: false,
            shouldCollapseNeighborNodes: false,
            zoomable: true,
            zoom: 0.8,
            scaleExtent: { min: 0.1, max: 1 },
            separation: { siblings: 1.5, nonSiblings: 1.5 },
            nodeSize: { x: 200, y: 200 },
            enableLegacyTransitions: false,
            transitionDuration: 500,
            renderCustomNodeElement: customNodeFnMapping,
            styles: {
                nodes: {
                    node: {
                        circle: {
                            fill: '#2D3748',
                        },
                        attributes: {
                            stroke: '#2D3748',
                        },
                    },
                    leafNode: {
                        circle: {
                            fill: '#2D3748',
                        },
                        attributes: {
                            stroke: '#2D3748',
                        },
                    },
                }
            },
        };
    }

    processTreeData = (data) => {
        var treeData = {
            name: data.title,
            children: [],
            attributes: {
                id: data.id,
                name: data.name,
            },
            data: {
                acc_url: data.acc_url,
                contacts: data.contacts,
            }
        };

        if (data.children.length > 0) {
            data.children.map(function (child, index) {
                treeData.children.push(this.processTreeData(child));
            }.bind(this))
        }
        return treeData;
    }

    componentDidMount() {
        var record = this.props.data.get('params').get('id')
        const params = record;
        this.setState({
            loaded: false,
        })

        var component = this;
        // sAction.load()

        sAction.rest.post("getAccountHierarchy", params, (resp) => {
            if(resp.status === true){
                sAction.unLoad();

                component.setState({loaded:true});
                var d = fromJSON(resp.message.data)
                let data = d[0];
                let treeData = this.processTreeData(data);

                component.setState({ data: treeData });
                // sAction.unload()
            }
        });
    }

    render(){
        if(!this.state.loaded) return <Loader />;

        return (
            <div id="treeWrapper" style={{ width: '80vw', height: '80vh' }}>

                {this.state.data &&
                <Tree
                    hasInteractiveNodes
                    data={this.state.data}
                    rootNodeClassName="tree-node"
                    branchNodeClassName="tree-node"
                    orientation={this.state.orientation}
                    dimensions={this.state.dimensions}
                    centeringTransitionDuration={this.state.centeringTransitionDuration}
                    translate={{ x: this.state.translateX, y: this.state.translateY }}
                    pathFunc={this.state.pathFunc}
                    collapsible={this.state.collapsible}
                    zoomable={this.state.zoomable}
                    zoom={this.state.zoom}
                    scaleExtent={this.state.scaleExtent}
                    nodeSize={this.state.nodeSize}
                    separation={this.state.separation}
                    enableLegacyTransitions={this.state.enableLegacyTransitions}
                    transitionDuration={this.state.transitionDuration}
                    depthFactor={this.state.depthFactor}
                    styles={this.state.styles}
                    shouldCollapseNeighborNodes={this.state.shouldCollapseNeighborNodes}
                    renderCustomNodeElement={
                        this.state.renderCustomNodeElement
                            ? rd3tProps => this.state.renderCustomNodeElement(rd3tProps, this.state)
                            : undefined
                    }
                    // onUpdate={(...args) => {console.log(args)}}
                    onNodeClick={(node, evt) => {
                        window.open(node.data.data.acc_url, '_blank');
                    }}
                />}

            </div>
        );

    }
}

