export default function utils(){
    const areAllEqual = (value, ...elements) =>{
        if(!Array.isArray(elements)){
            elements.toArray()
        }

        return elements.every((elements) => elements === value)
    }

    const areValuesEmpty = (...values) => {
        if(!Array.isArray(values)){
            values.toArray()
        }

        return values.every(value => !value)
    }
    
    const isAtLeastOneEqual = (value, ...elements) => {
        if(!Array.isArray(elements)){
            elements.toArray()
        }

        return elements.some((elements) => elements === value)
    }

    return {areAllEqual, isAtLeastOneEqual, areValuesEmpty}
}