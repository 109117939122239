import React from 'react';
import InputField from '../inputFieldClass';

import Select from '../../formElements/Select';
import InputText from '../../formElements/InputText';

import sAction from 'sAction';

export default class AcmSimpleDynamicEnum extends InputField {
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
            options: null,
            type: 'enum',
            inputError: false,
            isDuplicate: false,
        };
        this.input = React.createRef();
    }

    componentDidMount() {
        sAction.load();
        const data = this.props.data;
        const module = sAction.dataGet(this.props.prefix + '/module');
        sAction.openSimpleDynamicEnum(this.props.way, data, module, (returnData) => {
            const lines = returnData.lines;

            let linesOptions = [];
            if (returnData.canAddNew == true) {
                linesOptions.push({
                    label: sAction.translate('LBL_ADD_NEW_VALUE'),
                    value: 'acm_add_new',
                    type: 'button',
                });
            }
            lines.forEach((line, index) => {
                const option = {
                    value: line,
                    label: line,
                };
                linesOptions.push(option);
            });

            linesOptions = this.processOptions(linesOptions);

            this.setState({
                options: linesOptions,
                showForm: true,
            });
            sAction.unLoad();
        });
    }

    processOptions(options) {
        const retOptions = options.sort((a, b) => {
            if (a.value === 'acm_add_new') {
                return -1;
            }
            if (b.value === 'acm_add_new') {
                return 1;
            }
            return a.label.localeCompare(b.label);
        });
        return retOptions;
    }

    selectItem(e) {
        const prefix = this.props.prefix;
        const data = this.props.data;
        const value = e.target.value;

        if (value == 'acm_add_new') {
            this.setState({
                type: 'input',
            });
        } else {
            const params = {
                way: this.props.way,
                name: this.props.data.name,
                value,
            };
            sAction.saveField(params, true);
            sAction.dataSet(this.props.way + '/actEdit', false);
            sAction.clearRelatedDynamicEnums(prefix, data.def.get('options'), this.props.data.name);
        }
    }

    saveInput(e) {
        let value = this.input.current.value;

        const duplicate = this.isDuplicate(value, this.state.options);
        if (duplicate !== null) {
            value = this.state.options[duplicate].label;
            sAction.toast({name: sAction.translate('LBL_DUPLICATE_VALUE'), description: value});
            // this.setState({
            //     inputError: true,
            //     isDuplicate: true,
            // });
            // sAction.error(sAction.translate("LBL_DUPLICATE_VALUE"))
            // return;
        }

        if (value == '') {
            this.setState({inputError: true});
        } else {
            const changes = this.props.way.replace('fields', 'changes/fields');
            sAction.dataSet(this.props.way + '/value', value);
            sAction.dataSet(changes, value);
        }
    }

    isDuplicate(value, options) {
        let isDuplicate = null;
        for (let i = 0; i < options.length; i++) {
            if (options[i].label.toLowerCase() === value.toLowerCase()) {
                isDuplicate = i;
            }
        }
        return isDuplicate;
    }

    getParentValue() {
        const data = this.props.data;
        const way = this.props.way;
        const parent = data.def.getIn(['relData', 'parent']);
        const fields = sAction.dataGet(way+'/..');
        const enums = {};
        fields.forEach((field) => {
            if (field.def.get('type') == 'AcmDynamicEnum') {
                const id = field.def.get('options');
                const value = field.value;
                enums[id] = value;
            }
        });

        return enums[parent];
    }
    keyUpCall(e) {
        const keyCode = e.keyCode;
        if (keyCode == 13) {
            this.saveInput(e);
        }
    }

    render() {
        if (this.state.isDuplicate) {
            sAction.error(sAction.translate('LBL_DUPLICATE_VALUE'));
        }

        const data = this.props.data;

        return (<>
            {this.state.showForm == true ? (
                this.state.type == 'enum' ?
                    <Select
                        options={this.state.options}
                        open={true}
                        autoFocus={true}
                        onChange={(e) => this.selectItem(e)}
                        allowSearch={true}
                    /> :
                    <>
                        <div className="inputContainer">
                            <InputText
                                autoFocus={true}
                                onKeyUp={(e) => this.keyUpCall(e)}
                                //  onKeyDown={e => this.onKeyDown(e)}
                                myRef={this.input}
                                onBlur={(e) => this.saveInput(e)}
                                error={this.state.inputError}
                                //  defaultValue={value}
                                id={data.name}
                                name={data.name}
                                className={this.state.inputError ? 'inputError' : ''}
                            />
                        </div>
                        <div className="buttonContainer">
                            <div
                                tabIndex="3"
                                onClick={() => this.cancel()}
                                className=" inputEditButton"
                            >
                                <div className={'icon-detailCancel'} />
                            </div>
                        </div>
                    </>
            ) : (
                <div className="valueContainer hover null">
                    <span id="dynamicenum_c">{data.value}</span>
                </div>
            )}
        </>);
    }
}
