import React from 'react';
import Button from '../formElements/Button';
function AdminHeaderButtons({adminButtons}) {
    return (
        <div className="viewActionPanel">
            <div className="viewActionPanelButtons">
                {adminButtons.map((item, index)=>
                    <Button key={index} onClick={item.onClick}>
                        <div className={`actionPanelButtonIcon ${item.className}`}/>
                        {item.value}
                    </Button>,
                )}

            </div>
        </div>
    );
}

export default AdminHeaderButtons;
