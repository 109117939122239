import detailDefault from '../../detailDefault';
import $ from 'jquery';

export default class detailTasks extends detailDefault {
    load(sAction, data) {
        $('#task_name').closest('.detailViewTabContentRow').css('display', 'none');
        this.updateVisibilityFields(sAction, data);
        this.setRecidivism(sAction, data);

        const parent_type = sAction.dataGet(data.prefix+'/fields/parent_name/def/parent_type_value');
        if (!parent_type) {
            sAction.dataSet(data.prefix+'/fields/parent_name/def/parent_type_value', 'Accounts');
            sAction.dataSet(data.prefix+'/changes/fields/parent_type', 'Accounts');
        }
    }

    update(sAction, data) {
        switch (data.field) {
            case 'recidivism':
                this.setRecidivism(sAction, data);
                break;
            case 'repetitions_till':
                this.updateVisibilityFields(sAction, data);
                break;
            case 'recidivism_by':
                this.updateDaysVisibility(sAction, data);
                break;
        }
    }

    beforeSave(sAction, data) {
        const recidivism = sAction.dataGet(data.prefix + '/fields/task_name/def/id_value');
        if (!recidivism) {
            return true;
        } else {
            sAction.popup('SelectSaveTask', data);
            return false;
        }
    }

    updateVisibilityFields(sAction, data) {
        const repetitions_till = sAction.dataGet(data.prefix + '/fields/repetitions_till/value');
        switch (repetitions_till) {
            case 'ByDate':
                $('#date_recidivism_end').closest('.detailViewTabContentRow').prevAll('.detailViewPanelHeader:first').css('display', 'flex');
                $('#date_recidivism_end').closest('.detailViewTabContentRow').css('display', 'flex');
                $('#date_recidivism_end').closest('.detailViwRow').css('visibility', 'visible');

                $('#recidivism_amount').closest('.detailViewTabContentRow').prevAll('.detailViewPanelHeader:first').css('display', 'flex');
                $('#recidivism_amount').closest('.detailViewTabContentRow').css('display', 'none');
                $('#recidivism_amount').closest('.detailViwRow').css('visibility', 'hidden');
                break;
            case 'ByNumber':
                $('#date_recidivism_end').closest('.detailViewTabContentRow').prevAll('.detailViewPanelHeader:first').css('display', 'flex');
                $('#date_recidivism_end').closest('.detailViewTabContentRow').css('display', 'none');
                $('#date_recidivism_end').closest('.detailViwRow').css('visibility', 'hidden');

                $('#recidivism_amount').closest('.detailViewTabContentRow').prevAll('.detailViewPanelHeader:first').css('display', 'flex');
                $('#recidivism_amount').closest('.detailViewTabContentRow').css('display', 'flex');
                $('#recidivism_amount').closest('.detailViwRow').css('visibility', 'visible');
                break;
            case 'Inf':
            default:
                $('#date_recidivism_end').closest('.detailViewTabContentRow').prevAll('.detailViewPanelHeader:first').css('display', 'flex');
                $('#date_recidivism_end').closest('.detailViewTabContentRow').css('display', 'none');
                $('#date_recidivism_end').closest('.detailViwRow').css('visibility', 'hidden');

                $('#recidivism_amount').closest('.detailViewTabContentRow').prevAll('.detailViewPanelHeader:first').css('display', 'flex');
                $('#recidivism_amount').closest('.detailViewTabContentRow').css('display', 'none');
                $('#recidivism_amount').closest('.detailViwRow').css('visibility', 'hidden');
                break;
        }
    }

    setRecidivism(sAction, data) {
        const recidivism = sAction.dataGet(data.prefix + '/fields/recidivism/value');
        if (recidivism == 1) {
            $('#recidivism_by').closest('.detailViewTabContentRow').css('display', 'flex');
            $('#recidivism_period').closest('.detailViewTabContentRow').css('display', 'flex');
            $('#repetitions_till').closest('.detailViewTabContentRow').css('display', 'flex');
            this.updateVisibilityFields(sAction, data);
            this.updateDaysVisibility(sAction, data);
        } else {
            $('#recidivism_amount').closest('.detailViewTabContentRow').css('display', 'none');
            $('#date_recidivism_end').closest('.detailViewTabContentRow').css('display', 'none');
            $('#recidivism_by').closest('.detailViewTabContentRow').css('display', 'none');
            $('#recidivism_period').closest('.detailViewTabContentRow').css('display', 'none');
            $('#repetitions_till').closest('.detailViewTabContentRow').css('display', 'none');
            $('#recidivism_days').closest('.detailViewTabContentRow').css('display', 'none');
        }
    }

    updateDaysVisibility(sAction, data) {
        const recidivism_by = sAction.dataGet(data.prefix + '/fields/recidivism_by/value');
        if (recidivism_by === 'week') {
            $('#recidivism_days').closest('.detailViewTabContentRow').css('display', 'flex');
        } else {
            $('#recidivism_days').closest('.detailViewTabContentRow').css('display', 'none');
        }
    }
}

