import React from "react";
import PureComponent from "../../pure";
import  sAction  from "sAction";
import Button from "../../formElements/Button";

export default class SelectSaveTask extends PureComponent {
    constructor(props) {
        super(props);
    }

    save(type) {
        let data = this.props.data.toJS();

        sAction.dsClear();
        switch(type) {
            case 'onlyOne':
                sAction.dsAdd('set', data.prefix + '/changes/fields/task_id', '');
                sAction.dsAdd('set', data.prefix + '/changes/fields/recidivism', '');
                sAction.dsAdd('set', data.prefix + '/fields/task_name/value', '');
                sAction.dsAdd('set', data.prefix + '/changes/fields/relate', {task_name: ''});
                break;
            case 'all':
            default:
                break;
        }
        sAction.dsProcess();

        sAction.popupHide();
        data.ignoreBeforeSave = true;
        if (data.buttonId === 'save_record_and_leave') {
            sAction.saveRecordLeave(data);
        } else {
            sAction.saveRecord(data);
        }
        sAction.popupHide();
    }

    render() {

        return (
            <React.Fragment>
                <div className="reportWindowHeader">
                    <div className="reportWindowHeaderText">{sAction.translate('LBL_SELECT_SAVE_TYPE', 'Tasks')}</div>
                </div>
                <div className="exportListPopupContent">{sAction.translate('LBL_SAVING_RECIDIVISM_TASK', 'Tasks')}</div>
                <div className="exportListPopupContent">
                    <div className="viewActionPanelButtons flexRight">
                        <Button onClick={() => this.save('onlyOne')}>
                            <div className={"actionPanelButtonIcon icon-reject"} />
                            {sAction.translate('LBL_EDIT_THIS_ONE', 'Tasks')}
                        </Button>
                        <Button onClick={() => this.save('all')}>
                            <div className={"actionPanelButtonIcon icon-reject"} />
                            {sAction.translate('LBL_EDIT_ALL', 'Tasks')}
                        </Button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
