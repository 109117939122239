import $ from "jquery";
import detailDefault from "../../detailDefault";

export default class detailCalls extends detailDefault{
    load(sAction,data) {

      sAction.presetParentType(data);

      if(!sAction.dataGet(data.prefix+"/id")){
        const parent_type = sAction.dataGet(data.prefix+"/fields/parent_name/def/parent_type_value");
        const parent_id = sAction.dataGet(data.prefix+"/fields/parent_name/def/id_value");
        if(parent_id && (parent_type == "Accounts" || parent_type == "Contacts")){
          this.setContactinfo(sAction, {module : parent_type, id : parent_id}, data.prefix);
        }
      }

      var prefixInv = data.prefix+"/customData/invitees";

      if(data.view == "calendar" || data.view == "home"){
        prefixInv = data.prefix.replace("/detailView", "");
        prefixInv += "/invitees";
      }

      this.searchInvitees(sAction, data.prefix, prefixInv);

      if(data.view != "calendar" && data.view != "home"){
        const id = sAction.dataGet(data.prefix+"/id");
        if(!id){
          const date = new Date();
          const year = date.getFullYear();
          var month = date.getMonth()+1;
          if(month < 10){
            month = "0"+month
          }
          var day = date.getDate();
          var hours = date.getHours();
          var minutes = date.getMinutes();
          if (minutes > 44) {
            minutes = 0;
            hours++;
            if (hours > 23) {
              hours = 0;
              day++;
            }
          }
          if (day < 10) {
            day = "0" + day;
          }
          if (hours < 10) {
            hours = "0" + hours;
          }
          minutes = Math.round(minutes / 30) * 30;
          if(minutes < 10){
            minutes = "0"+minutes
          }

          //Kontrola zda nejsou ulozene hodiny a minuty aby zustali i pri duplikaci.

          const savedHours = sAction.dataGet(data.prefix+"/fields/duration_hours/value")

          let innitValueHours = 0;
          let innitValuesMinutes = 15;

          if(savedHours) {
            innitValueHours = savedHours
            innitValuesMinutes = sAction.dataGet(data.prefix+"/fields/duration_hours/def/minutesValue")
          }

          const today = year+"-"+month+"-"+day+" "+hours+":"+minutes+":00";
          sAction.dsClear();
          sAction.dsAdd("set",data.prefix+"/fields/date_start/value",today)
          sAction.dsAdd("set",data.prefix+"/changes/fields/date_start",today)
          sAction.dsAdd("set",data.prefix+"/fields/duration_hours/value",innitValueHours)
          sAction.dsAdd("set",data.prefix+"/fields/duration_hours/def/minutesValue",innitValuesMinutes)
          sAction.dsAdd("set",data.prefix+"/changes/fields/duration_hours",innitValueHours)
          sAction.dsAdd("set",data.prefix+"/changes/fields/duration_minutes",innitValuesMinutes)
          sAction.dsProcess();
        }
      }
    }
    update(sAction,data) {
      if(data.field == "parent_id"){
        const parentId = sAction.dataGet(data.prefix + "/fields/parent_name/def/id_value")
        const parentName = sAction.dataGet(data.prefix + "/fields/parent_name/value")

        sAction.rest.post("getAccountsHQ", {recordId: parentId}, returnData => {
          sAction.dsClear();
          if (returnData.status) { // ma centralu, tak vytvorime jmeno z centraly ... centrala se pak doplni v logic hooku
            sAction.dsAdd("set",data.prefix + "/fields/centrala/value", returnData.message.data.name);
          } else {// nema centralu, tak pouzijeme nazev vybrane firmy z tyka se
            sAction.dsAdd("set", data.prefix + "/fields/centrala/value", parentName);
          }
          sAction.dsProcess()
          this.checkName(sAction, data)
        }, false)

        const parent_type = sAction.dataGet(data.prefix+"/fields/parent_name/def/parent_type_value");
        if(parent_type == "Accounts" || parent_type == "Contacts"){
          if(data.value.id){
            this.setContactinfo(sAction, {module : parent_type, id : data.value.id}, data.prefix);
          }
        }

        var prefixInv = data.prefix+"/customData/invitees";
        if(data.view == "calendar" || data.view == "home"){
          var prefixInv = data.prefix.replace("/detailView", "").replace("/callView", "");
          prefixInv += "/invitees";
        }
        else if(data.view != "detail"){
          return;
        }
        this.searchInvitees(sAction, data.prefix, prefixInv);

        //Prednastavi centralu



      // } else if (data.field === 'date_end') {
      //   let start = sAction.dataGet(data.prefix + '/fields/date_start/value');
      //   start = new Date(start);
      //   let end = new Date(data.value);
      //   let diff = Math.abs(end - start);
      //   let mins = (Math.floor(diff / (1000 * 60))) % 60;
      //   let hours = Math.floor(diff / (1000 * 60 * 60));
      //   sAction.dsClear();
      //   sAction.dsAdd("set", data.prefix + "/fields/duration_hours/value", hours);
      //   sAction.dsAdd("set", data.prefix + "/fields/duration_hours/def/minutesValue", mins);
      //   sAction.dsAdd("set", data.prefix + "/changes/fields/duration_hours", hours);
      //   sAction.dsAdd("set", data.prefix + "/changes/fields/duration_minutes", mins);
      //   sAction.dsProcess();
      // } else if (data.field === 'duration_hours' || data.field === 'date_start') {
      //   let hours = sAction.dataGet(data.prefix + "/fields/duration_hours/value");
      //   let mins = sAction.dataGet(data.prefix + "/fields/duration_hours/def/minutesValue");
      //   let start = sAction.dataGet(data.prefix + '/fields/date_start/value');
      //   start = new Date(start);
      //   let end = new Date(start.getTime() + (1000 * 60 * (mins + hours * 60)));
      //
      //   const year = end.getFullYear();
      //   var month = end.getMonth() + 1;
      //   if (month < 10) {
      //     month = "0" + month
      //   }
      //   var day = end.getDate();
      //   var hrs = end.getHours();
      //   var minutes = end.getMinutes();
      //   if (minutes > 44) {
      //     hours++;
      //     if (hours > 23) {
      //       hours = 0;
      //       day++;
      //     }
      //   }
      //   if (day < 10) {
      //     day = "0" + day;
      //   }
      //   if (hours < 10) {
      //     hours = "0" + hours;
      //   }
      //   if (minutes < 10) {
      //     minutes = "0" + minutes
      //   }
      //   let endDate = year + "-" + month + "-" + day + " " + hrs + ":" + minutes + ":00";
      //   sAction.dsClear();
      //   sAction.dsAdd("set", data.prefix + "/fields/date_end/value", endDate);
      //   sAction.dsAdd("set", data.prefix + "/changes/fields/date_end", endDate);
      //   sAction.dsProcess();
      }
    }

  checkName(sAction, data){
    const parentName = sAction.dataGet(data.prefix + "/fields/centrala/value");
    const parentType = sAction.dataGet(data.prefix+"/fields/parent_name/def/parent_type_value");

    // coripo/cematsk#66 -> disabled AP
    // if (parentName && parentType === 'Accounts'){
    //   sAction.dsClear();
    //   sAction.dsAdd("set", data.prefix + "/fields/name/value", "Hovor - " + parentName);
    //   sAction.dsAdd("set", data.prefix + "/changes/fields/name", "Hovor - " + parentName);
    //   sAction.dsProcess();
    // }
  }


  searchInvitees(sAction, prefix, prefixInv){
      const parent = sAction.dataGet(prefix+"/fields/parent_name");
      if(parent && parent.def && parent.def.get("parent_type_value") == "Accounts" && parent.def.get("id_value") != ""){
        sAction.searchInvitees({parent: parent.toJS()}, prefixInv);
      }
    }

    setContactinfo(sAction, params, prefix){
      params.action = "getActivityContactInfo";
      sAction.rest.post("customAction", params, function(resp) {
        if(resp.errCode == "OK"){
            sAction.dsClear();
            sAction.dsAdd("set",prefix+"/fields/phone_c/value", resp.data.phone)
            sAction.dsAdd("set",prefix+"/changes/fields/phone_c", resp.data.phone)
            sAction.dsAdd("set",prefix+"/fields/mobile_c/value", resp.data.mobile)
            sAction.dsAdd("set",prefix+"/changes/fields/mobile_c",resp.data.mobile)
            sAction.dsProcess();

        }
      }, false);
    }

}
