import React from "react";
import sAction from "sAction";

export default function DetailViewSpanButtons(props){

  const type = props.data.def.get("type");
  const item = props.data.def.get("item");
  const readonly = props.data.def.get("readonly");
  const name = props.data.value
    var buttons = [];
    if (type == "AcmAddress") {
      let activeClass = 'mapButtonActive';
      if (!item?.get('gpsY') || !item?.get('gpsX')) {
        activeClass = 'mapButtonInactive';
      }
      buttons.push(
        <div style={{display: 'flex'}}>
          <a key={0} className="detailViewSpanButton"
             onClick={() => sAction.popup("openStreetMap", {
               item: item,
               name: name,
               header: sAction.translate("LBL_MAP"),
               readonly,
               callback: data => {
                 sAction.saveAcmAddressField(data, props.data, props.prefix, props.way)
               }
             })}>
            <span className="icon-Location"/>
          </a>
          <a key={1}
             href={'https://www.google.com/maps?saddr=My+Location&daddr=' + item.get('gpsY') + ',' + item.get('gpsX')}
             target={'_blank'}
             className = {activeClass}>
            <span className="icon-google_maps"/>
          </a>
          <a key={2}
             href={'https://waze.com/ul?q=66%20Acacia%20Avenue&ll=' + item.get('gpsY') + ',' + item.get('gpsX') + '&navigate=yes&zoom=17'}
             target={'_blank'}
             className = {activeClass}>
            <span className="icon-waze"/>
          </a>
        </div>
      );
    }
    return(
        <div className="detailViewSpanButtonsContainer">
            {buttons}
        </div>
    )
}
