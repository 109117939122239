import PropTypes from 'prop-types';
import React from 'react';
import PureComponent from '../../pure';
import sAction from 'sAction';

import Button from '../../formElements/Button';

import InputText from '../../formElements/InputText';
import TickBox from '../../formElements/TickBox';
import Select from '../../formElements/Select';
import {EmailPopupActionPanel} from './shared/EmailPopupActionPanel';
import Loader from '../../loader';
import ErrorMessage from '../../default/ErrorMessage';
import TabContainer from '../../formElements/TabContainer';
import EmailOAuthAddAccount from './EmailOAuthAddAccount';

export default class EmailSettingsIePopup extends PureComponent {
    _isMounted = false;

    constructor(props) {
        super(props);

        let outbound_email = '';
        if ('smtps' in props && Array.isArray(props.smtps) && props.smtps.length) {
            outbound_email = props.smtps[0].value;
        }

        this.state = {
            expand_pass: false,
            outbound_email_options: props.smtps,
            test_inbound: '',
            loaded: false,
            errors: [],
            error_msg: [],
            is_new: false,

            data: {
                ie_id: props.id,
                ie_status: 'Active',
                ie_team: '',
                group_id: sAction.dataGet('conf/user/id'),
                mark_read: '1',
                searchField: '',

                // inbound
                ie_name: '',
                email_user: '',
                email_password: '',
                server_url: '',
                protocol: 'imap',
                ssl: '1',
                port: '993',
                mailbox: 'INBOX',
                trashFolder: 'Trash',
                sentFolder: 'Sent',

                // outbound
                from_name: '',
                from_addr: '',
                reply_to_addr: '',
                outbound_email,
                mail_smtpuser: '',
                mail_smtppass: '',
            },
            activeView: 0,
            disableTabs: false,
        };
    }

    handleChange = ({target: {name, value}}) => {
        this.setState((prevState) => ({
            data: {...prevState.data, [name]: value},
        }));
        if (name === 'ssl') {
            this.setState((prevState) => ({
                data: {...prevState.data, port: value ? '993' : '143'},
            }));
        } else if (name === 'outbound_email' && value === 'choose-smtp') {
            this.setState((prevState) => ({
                data: {...prevState.data, [name]: ''},
            }));
            sAction.popup('emailSettingsOePopup', {
                callback: this.setOutboundEmail,
            });
        }
    };

    setOutboundEmail = (returnData) => {
    // console.log("returnData", returnData);
        const label = returnData.mail_name + ' ' + returnData.mail_smtpserver;
        this.setState({
            outbound_email_options: this.state.outbound_email_options.concat([
                {label, value: returnData.mail_id},
            ]),
        });
        this.setState({
            data: {...this.state.data, outbound_email: returnData.mail_id},
        });
    };

    componentDidMount() {
        this._isMounted = true;

        if (this.props?.oeId || this.props?.ieId) {
            this.setState({loaded: true, activeView: 1, disableTabs: true});
        }

        const {id: ieId} = this.props;
        if (!ieId) {
            this.setState({loaded: true, expand_pass: true, is_new: true});
            return;
        }

        sAction.rest.post('email/getIeAccount', {ieId}, (data) => {
            if (!this._isMounted) return;

            this.setState({
                loaded: true,

                data: {
                    ...this.state.data,
                    // inbound
                    ie_name: data.name,
                    email_user: data.email_user,
                    server_url: data.server_url,
                    // protocol: data.protocol,
                    ssl: 2 in data.service,
                    port: data.port,
                    mailbox: data.mailbox,
                    trashFolder: data.stored_options.trashFolder,
                    sentFolder: data.stored_options.sentFolder,

                    // outbound
                    from_name: data.stored_options.from_name,
                    from_addr: data.stored_options.from_addr,
                    reply_to_addr: data.stored_options.reply_to_addr,
                    outbound_email: data.stored_options.outbound_email,
                    // mail_smtpuser: "admin",
                    // mail_smtppass: "123654",
                },
            });
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    isFormValid = () => {
        const {
            ie_name,
            email_user,
            email_password,
            server_url,
            protocol,
            ssl,
            port,
            mailbox,
            trashFolder,
            sentFolder,

            from_name,
            from_addr,
            reply_to_addr,
            outbound_email,
        } = this.state.data;

        const errors = [];
        const error_msg = [];
        if (!ie_name) errors.push('ie_name');
        if (!email_user) errors.push('email_user');
        if (!email_password && this.state.is_new) errors.push('email_password');
        if (!server_url) errors.push('server_url');
        if (!protocol) errors.push('protocol');
        if (!port) errors.push('port');
        if (!mailbox) errors.push('mailbox');
        if (!trashFolder) errors.push('trashFolder');
        if (!from_name) errors.push('from_name');
        if (!from_addr) errors.push('from_addr');
        if (!outbound_email) errors.push('outbound_email');

        this.setState({errors});

        if (errors.length) {
            // Nejsou vyplněny všechny povinné položky
            this.setState({error_msg: [sAction.translate('ERR_FILL_REQUIRED_ITEMS', 'Emails')]});
            return false;
        }

        return true;
    };

    // ulozeni zmen v popupu
    savePopup = () => {
        if (!this.isFormValid()) return false;

        sAction.load();
        sAction.rest.post('email/saveIeAccount', this.state.data, (data) => {
            sAction.unLoad();
            if (data.error === 'error') {
                sAction.error(
                    sAction.translate('LBL_EMAIL_ERROR_DESC') +
            ' ' +
            sAction.translate('LBL_EMAIL_ERROR_CHECK_IE_SETTINGS'),
                );
            } else {
                sAction.popupHide();
                sAction.load();
                sAction.rest.post(
                    'email/checkEmailProgress',
                    {currentCount: 0, ieId: data.id},
                    (data) => {
                        if (this.props.onChange) {
                            this.props.onChange();
                        }
                    },
                );
            }
        });
    };

    testSettings = () => {
    // ne vsechny nazvy promennych jsou stejne
        const {data} = this.state;
        const sendData = {
            server_url: data.server_url,
            email_user: data.email_user,
            protocol: data.protocol,
            port: data.port,
            email_password: data.email_password,
            mailbox: data.mailbox,
            ssl: data.ssl,
            ie_id: data.ie_id,
            personal: true,
        };

        this.setState({loaded: false});
        sAction.rest.post('email/testInbound', sendData, (data) => {
            this.setState({loaded: true});
            sAction.alert(data);
        });
    };

    chooseMailbox = (folder, mailbox = false) => {
        const {data} = this.state;
        const folderValue = this.state.data[folder];
        const sendData = {
            ie_id: data.ie_id,
            server_url: data.server_url,
            email_user: data.email_user,
            protocol: data.protocol,
            port: data.port,
            email_password: data.email_password,
            mailbox: mailbox === true ? folderValue : '',
            ssl: data.ssl,
            searchField: mailbox !== true ? folderValue : '',
            personal: true,
        };
        this.setState({loaded: false});
        sAction.rest.post('email/ShowInboundFoldersList', sendData, (data) => {
            this.setState({loaded: true});
            if (data.status != true) {
                sAction.error(data.statusMessage);
            } else {
                sAction.popup('emailSelectFolderPopup', {
                    input: folder,
                    value: folderValue,
                    folders: data.foldersList,
                    callback: this.handleFolder,
                });
            }
        });
    };

    handleFolder = (input, folders) => {
        this.handleChange({target: {name: input, value: folders}});
    };

    render() {
        let {
            expand_pass,
            outbound_email_options,
            loaded,
            errors,
            error_msg,

            data: {
                ie_name,
                email_user,
                email_password,
                server_url,
                protocol,
                ssl,
                port,
                mailbox,
                trashFolder,
                sentFolder,

                from_name,
                from_addr,
                reply_to_addr,
                outbound_email,
            },
        } = this.state;

        // vložit
        outbound_email_options = outbound_email_options.concat([
            {
                label: sAction.translate('LBL_ADD_OUTBOUND_ACCOUNT', 'Emails'),
                value: 'choose-smtp',
            },
        ]);

        const tabs = [
            sAction.translate('LBL_EMAIL_SETTINGS_EMAIL_TAB', 'Emails'),
        ];

        if (this.props?.oAuth.allowOAuthProvider) {
            tabs.push(sAction.translate('LBL_EMAIL_SETTINGS_COMPANY_EMAIL_TAB', 'Emails'));
        }

        if (!loaded) return <Loader />;

        return (
            <div className="email__popup">
                <div className="acmPopupHeader">
                    {/* Nastavení e-mailového účtu*/}
                    {sAction.translate('LBL_EMAIL_ACCOUNTS_INBOUND', 'Emails')}
                </div>

                <EmailPopupActionPanel onSave={this.savePopup} />

                <TabContainer
                    tabs={tabs}
                    value={this.state.activeView}
                    className={this.state.disableTabs ? 'disabled' : ''}
                    onChange={(e, value) => {
                        this.setState({activeView: value});
                    }}
                />
                {this.state.activeView === 0 && (
                    <div className="email__wrapper">
                        {!!error_msg.length && <ErrorMessage>{error_msg}</ErrorMessage>}

                        {/* label="Příchozí E-mail"*/}
                        <h4>{sAction.translate('LBL_EMAIL_SETTINGS_INBOUND', 'Emails')}</h4>
                        <InputText
                            required
                            margin="dense"
                            // inputRef={this.to_addrsRef}
                            id="ie_name"
                            // label="Jméno e-mailového účtu"
                            label={sAction.translate('LBL_EMAIL_SETTINGS_NAME')}
                            type="text"
                            name="ie_name"
                            value={ie_name}
                            onChange={this.handleChange}
                            error={errors.includes('ie_name')}
                        />
                        <InputText
                            required
                            margin="dense"
                            // inputRef={this.to_addrsRef}
                            id="email_user"
                            // label="Uživatelské jméno"
                            label={sAction.translate('LBL_LOGIN', 'InboundEmail')}
                            type="text"
                            name="email_user"
                            value={email_user}
                            onChange={this.handleChange}
                            error={errors.includes('email_user')}
                        />
                        {expand_pass ? (
                            <InputText
                                required={this.state.is_new}
                                margin="dense"
                                // inputRef={this.to_addrsRef}
                                id="email_password"
                                // label="Heslo
                                label={sAction.translate('LBL_PASSWORD', 'InboundEmail')}
                                type="password"
                                name="email_password"
                                value={email_password}
                                onChange={this.handleChange}
                                error={errors.includes('email_password')}
                            />
                        ) : (
                        // Změnit heslo
                            <a onClick={() => this.setState({expand_pass: true})}>
                                {sAction.translate('LBL_CHANGE_PASSWORD')}
                            </a>
                        )}
                        <InputText
                            required
                            margin="dense"
                            // inputRef={this.to_addrsRef}
                            id="server_url"
                            // label="Adresa mailového serveru"
                            label={sAction.translate('LBL_SERVER_URL', 'InboundEmail')}
                            type="text"
                            name="server_url"
                            value={server_url}
                            onChange={this.handleChange}
                            error={errors.includes('server_url')}
                        />
                        <InputText
                            required
                            margin="dense"
                            // inputRef={this.to_addrsRef}
                            id="protocol"
                            // label="Protokol mailového serveru"
                            label={sAction.translate('LBL_SERVER_TYPE', 'InboundEmail')}
                            type="text"
                            name="protocol"
                            value="IMAP"
                            disabled
                            onChange={this.handleChange}
                            error={errors.includes('protocol')}
                        />
                        <TickBox
                            checked={this.state.data.ssl == '1'}
                            id="ssl"
                            onChange={(value) =>
                                this.handleChange({target: {name: 'ssl', value}})
                            }
                        />{' '}
                        {sAction.translate('LBL_SSL', 'InboundEmail')}
                        <InputText
                            required
                            margin="dense"
                            // inputRef={this.to_addrsRef}
                            id="port"
                            // label="Port mailového serveru"
                            label={sAction.translate('LBL_PORT', 'InboundEmail')}
                            type="text"
                            name="port"
                            value={port}
                            onChange={this.handleChange}
                            error={errors.includes('port')}
                        />
                        <div className="email__compose__field-row">
                            <div className="email__compose__field-row__input">
                                <InputText
                                    required
                                    margin="dense"
                                    // inputRef={this.to_addrsRef}
                                    id="mailbox"
                                    // label="Monitorovaná složka"
                                    label={sAction.translate('LBL_MAILBOX', 'InboundEmail')}
                                    type="text"
                                    name="mailbox"
                                    value={mailbox}
                                    onChange={this.handleChange}
                                    error={errors.includes('mailbox')}
                                />
                            </div>
                            <div className="email__compose__field-row__button">
                                <Button
                                    onClick={() => this.chooseMailbox('mailbox', true)}
                                    className="stillHover"
                                >
                                    {/* Vybrat*/}
                                    {sAction.translate('LBL_EMAIL_SELECT')}
                                </Button>
                            </div>
                        </div>
                        <div className="email__compose__field-row">
                            <div className="email__compose__field-row__input">
                                <InputText
                                    required
                                    margin="dense"
                                    // inputRef={this.to_addrsRef}
                                    id="trashFolder"
                                    // label="Složka koše"
                                    label={sAction.translate('LBL_TRASH_FOLDER', 'InboundEmail')}
                                    type="text"
                                    name="trashFolder"
                                    value={trashFolder}
                                    onChange={this.handleChange}
                                    error={errors.includes('trashFolder')}
                                />
                            </div>
                            <div className="email__compose__field-row__button">
                                <Button
                                    onClick={() => this.chooseMailbox('trashFolder', false)}
                                    className="stillHover"
                                >
                                    {/* Vybrat*/}
                                    {sAction.translate('LBL_EMAIL_SELECT')}
                                </Button>
                            </div>
                        </div>
                        <div className="email__compose__field-row">
                            <div className="email__compose__field-row__input">
                                <InputText
                                    margin="dense"
                                    // inputRef={this.to_addrsRef}
                                    id="sentFolder"
                                    // label="Odesla složku"
                                    label={sAction.translate('LBL_SENT_FOLDER', 'InboundEmail')}
                                    type="text"
                                    name="sentFolder"
                                    value={sentFolder}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="email__compose__field-row__button">
                                <Button
                                    onClick={() => this.chooseMailbox('sentFolder', false)}
                                    className="stillHover"
                                >
                                    {/* Vybrat*/}
                                    {sAction.translate('LBL_EMAIL_SELECT')}
                                </Button>
                            </div>
                        </div>
                        {/* // label="Zkontrolovat nastavení"*/}
                        <Button onClick={this.testSettings} className="stillHover">
                            {sAction.translate('LBL_TEST_SETTINGS', 'InboundEmail')}
                        </Button>

                        <br />
                        {/* // label="Odchozí Email"*/}
                        <h4>{sAction.translate('LBL_EMAIL_SETTINGS_OUTBOUND', 'Emails')}</h4>
                        <InputText
                            required
                            margin="dense"
                            // inputRef={this.to_addrsRef}
                            id="from_name"
                            // label="Jméno odesílatele"
                            label={sAction.translate('LBL_FROM_NAME', 'Emails')}
                            type="from_name"
                            name="from_name"
                            value={from_name}
                            onChange={this.handleChange}
                            error={errors.includes('from_name')}
                        />
                        <InputText
                            required
                            margin="dense"
                            // inputRef={this.to_addrsRef}
                            id="from_addr"
                            // label="Z e-mailové adresy"
                            label={sAction.translate('LBL_EMAIL_SETTINGS_FROM_ADDR')}
                            type="text"
                            name="from_addr"
                            value={from_addr}
                            onChange={this.handleChange}
                            error={errors.includes('from_addr')}
                        />
                        <InputText
                            margin="dense"
                            // inputRef={this.to_addrsRef}
                            id="reply_to_addr"
                            // label="Odpovědět na adresy"
                            label={sAction.translate('LBL_EMAIL_SETTINGS_REPLY_TO_ADDR')}
                            type="text"
                            name="reply_to_addr"
                            value={reply_to_addr}
                            onChange={this.handleChange}
                        />
                        <Select
                            required
                            options={outbound_email_options}
                            // label="Odchozí SMTP mail server"
                            label={sAction.translate(
                                'LBL_EMAIL_SETTINGS_OUTBOUND_ACCOUNT',
                                'Emails',
                            )}
                            name="outbound_email"
                            value={outbound_email}
                            onChange={this.handleChange}
                            error={errors.includes('outbound_email')}
                        />
                    </div>
                )}
                {this.state.activeView === 1 && (
                    <div className="email__wrapper">
                        {!!error_msg.length && <ErrorMessage>{error_msg}</ErrorMessage>}
                        <EmailOAuthAddAccount outboundOnly={false} ieId={this.props?.ieId} oeId={this.props?.oeId} />
                    </div>
                )}
            </div>
        );
    }
}

EmailSettingsIePopup.propTypes = {
    id: PropTypes.any,
    ieId: PropTypes.any,
    oAuth: PropTypes.shape({
        allowOAuthProvider: PropTypes.bool,
        providerName: PropTypes.string,
    }),
    oeId: PropTypes.any,
    onChange: PropTypes.func,
    smtps: PropTypes.shape({
        length: PropTypes.any,
    }),
};
