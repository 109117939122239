import React from "react";
import PureComponent from "../pure";

import MoreOptions from "../formElements/MoreOptions";
import DetailViewTabContentRow from "./DetailViewTabContentRow";

import sAction from "sAction";
class DetailTabContent extends PureComponent {
  addNewLine(e, key = null, module = null) {
    const way = this.props.way;
    sAction.addNewLineToTab(way, key, module);
  }
  addNewHeader(e, key = null, module = null) {
    const way = this.props.way;
    sAction.detailEditAddNewHeader(way, key, module);
  }

  render() {
    const data = this.props.data;
    const module = this.props.module;
    const rowCount = this.props.rowCount;
    const id = this.props.id;
    const way = this.props.way;
    const fields = this.props.fields;
    const prefix = this.props.prefix;
    const index = this.props.index;
    const readonly = this.props.readonly;
    const mode = this.props.mode;

    var rows = [];

    let groupedRows = [[]];
    let groupedRowsIndex = 0;

    data.rows.forEach((row, key) => {
      let renderRow = null;

      if (row.get("type") == "headerPanel") {
        const headerName = row.get("name");
        const label = sAction.translate(headerName.toUpperCase(), module);
        renderRow = 
          <React.Fragment key={headerName}>
            {mode == "edit" && (
              <div className="detailViewEditAddLineContainer">
                <MoreOptions
                  options={[
                    {
                      label: sAction.translate("LBL_ADD_NEW_LINE"),
                      icon: "icon-addIcon",
                      value: "addNewLine",
                    },
                    {
                      label: sAction.translate("LBL_ADD_NEW_DETAIL_HEADER"),
                      icon: "icon-addIcon",
                      value: "addNewHeader",
                    },
                  ]}
                  onClick={(e, val) => this[val](e, key, module)}
                >
                  <div className="icon-addIcon" />
                </MoreOptions>
              </div>
            )}
            <div className="detailViewPanelHeader">
              <div className="detailViewPanelText" colSpan={2}>
                {label}
                {mode == "edit" && (
                  <>
                    <div
                      onClick={(e) =>
                        sAction.editDetailEditTab(
                          e,
                          headerName.toUpperCase(),
                          module,
                          way + "/rows/" + key
                        )
                      }
                      className="icon-EditField detailViewEditTabButton"
                      title={sAction.translate("LBL_EDIT_NAME")}
                    ></div>
                    <div
                      onClick={(e) => sAction.dataDelete(way + "/rows",key)}
                      className="icon-deleteIcon detailViewEditTabButton"
                      title={sAction.translate("LBL_DELETE")}
                    ></div>
                  </>
                )}
              </div>
            </div>
          </React.Fragment>
        ;
      } else {
        renderRow =
          <DetailViewTabContentRow
            prefix={prefix}
            module={module}
            way={way + "/rows/" + key}
            key={key}
            index={key}
            data={row}
            rowCount={100 / rowCount}
            id={id}
            fields={fields}
            readonly={readonly}
            acl={this.props.acl}
            mode={mode}
          />
        ;
      }

      if (row.get("type") !== "headerPanel") {
        groupedRows[groupedRowsIndex].push(renderRow);
      } else {
        groupedRowsIndex++;
        groupedRows[groupedRowsIndex] = [renderRow];
      }

    });

    groupedRows.forEach((group, key) => {
      rows.push(<div key={key} className="detailViewGroup">{group}</div>);
    });

    var blok = "";
    if (data.active == true) {
      blok = (
        <div className="detailViewTabContentBlok select" data-index={index}>
          {rows}
          {mode == "edit" && (
            <div className="detailViewEditAddLineContainer">
              <MoreOptions
                options={[
                  {
                    label: sAction.translate("LBL_ADD_NEW_LINE"),
                    icon: "icon-addIcon",
                    value: "addNewLine",
                  },
                  {
                    label: sAction.translate("LBL_ADD_NEW_DETAIL_HEADER"),
                    icon: "icon-addIcon",
                    value: "addNewHeader",
                  },
                ]}
                onClick={(e, val) => this[val](e, null, module)}
              >
                <div className="icon-addIcon" />
              </MoreOptions>
            </div>
          )}
        </div>
      );
    } else {
      blok = (
        <div className="detailViewTabContentBlok" data-index={index}>
          {rows}
        </div>
      );
    }

    return blok;
  }
}

export default DetailTabContent;
