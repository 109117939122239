export default function customCanSave(status, text, att){
  this.dsClear()

  if (!this.dataGet('view/customData')) {
    this.dsAdd('set', 'view/customData', {});
  }

  this.dsAdd("set", "view/customData/canSave/"+att, status);

  if (text){
    this.dsAdd("set", "view/customData/canSaveText/"+att, text);
  }

  this.dsProcess()
}
