import rest from "./rest";

function processUrlRedirect(urlParams, urlData) {
  const coripoUrl = urlParams.get('coripo_url')
  if(!coripoUrl){
    return;
  }

  // remove coripo_url from url used for redirect from office365 oAuth
  const baseUrl = window.location.href.split('?')[0];
  let newUrl = `${baseUrl}#${coripoUrl}`;
  if(Object.keys(urlData).length > 1){
    newUrl += '?';
    Object.keys(urlData).forEach((key) => {
      if(key === 'coripo_url'){
        return;
      }
      newUrl += `${key}=${urlData[key]}&`;
    });
    newUrl = newUrl.slice(0, -1);
  }
  
  window.location.replace(newUrl);
}


export default function init(paramData, callback) {

  if(window.location.search != ""){
    let urlParams = new URLSearchParams(window.location.search);
    let urlData = {};
    urlParams.forEach((val,key) => {
      urlData[key] = val;
    });
    processUrlRedirect(urlParams, urlData);

    urlData = this.toBase64(urlData);
    var r = urlParams.get("r");
    if(r != null){
      
      window.location.href = window.location.origin+"/#"+r+"/"+urlData;
    }
  }


  this.dataReducer = "app";
  this.param = paramData;

  this.notificationInterval = null;
  this.currencies = null;
  this.customClass = null;
  this.colorTemplate = new Array(
    "#898CFF",
    "#71E096",
    "#5AD0E5",
    "#BB96FF",
    "#FF89B5",
    "#F5A26F",
    "#DA97E0",
    "#EFCE7E",
    "#668DE5",
    "#90D4F7",
    "#ED6D79",
    "#FF96E3",
    "#93BFDC",
    "#E09399",
    "#B6D96E",
    "#FF9393",
    "#79CB9F",
    "#BDCCAA",
    "#5AD0E5",
    "#65D8CC"
  );
  this.detailTempData = {};
  this.rest = new rest(this);
  this.objectLoopInit();
  this.overwriteStyle();

  
  this.hashChangeEvent();
    this.rest.get("checksid", data => {
      this.initData(data);
      var view = this.getViewName();
      this.route(true,data,view == "login" ? "home" : null);
      this.renderReact();
      this.checkNewNotifications(); 
    });
}
