import detailDefault from "../../detailDefault";
import React from "react";

export default class detailacm_discounts extends detailDefault {
    update(sAction, data) {
        if (data.field === "sortiment_group") {
            const options = sAction.app_strings["products_sortiment_group_list"];

            options.forEachObject((option, key) => {
                if (key === data.value) {
                    const discountName = this.purifyName(option)
                    sAction.dsClear()
                    sAction.dsAdd("set", data.prefix + "/name", discountName)
                    sAction.dsAdd("set", data.prefix + "/fields/name/value", discountName)
                    sAction.dsAdd("set", data.prefix + "/changes/fields/name", discountName)
                    sAction.dsProcess()
                }
            })
        }
    }

    purifyName(option) {
        if (option.substring(0, 3).includes("03")) {
            return option.substring(3).trim()
        }

        return option.replace(/[0-9]/g, '').trim()
    }
}
