export default function driversLastPosition(recordData) {

  let spz = this.dataGet(recordData.prefix + "/fields/spz/value")
  let dayTolerance = 14;

  let postData = {
    id: recordData.id,
    type: 'lastKnownPosition', // zatim se nevyuziva
    spz: spz,
    dayTolerance: dayTolerance // kolik dni naspet kontrolovat, jestli se auto hnulo z mista
  }

  this.load();
  this.rest.post("getLastKnownPosition", postData, returnData => {
    if (returnData.status) {
      let item = {
        gpsX: returnData.message.data.lon,
        gpsY: returnData.message.data.lat,
        obec: returnData.message.data.obec,
        psc: returnData.message.data.psc,
        kraj: returnData.message.data.kraj,
      };
      this.popup("OpenStreetMap", {
        item: item,
        name: spz,
        params: recordData,
        sidebar: true, // zobrazit sidebaru pri vygenerovani mapy
        sidebarSearch: false, // zobrazit sidebaru pri vygenerovani mapy
        clickable: false, // zruseni hledani souradnic pri kliknuti na mapu
        header: this.translate("LBL_POSLEDNI_POZICE", "acm_reports")
      });
      this.unLoad()
    } else {
      this.unLoad()
      let errMsg = this.translate(returnData.errorMessage.text).replace('{MAX_ATT}', dayTolerance).replace('{LIC_PLATE}', spz)
      this.alert(errMsg)
    }
  });


}
