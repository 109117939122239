import React from "react";
import PureComponent from "../../pure";
import moment from "moment";
import Button from "../../formElements/Button";
import Select from "../../formElements/Select";
import AcmDate from "../../formElements/AcmDate";
import sAction from "sAction";

export default class CloseQuotePopup extends PureComponent {

  defaultQuoteStage = "Closed Won";
  closeStates = ["Closed Won", "Closed Lost", "Canceled", "Closed canceled client"];
  lostStates = ["Closed Lost", "Canceled", "Closed canceled client"]
  stageOptions = [];

  constructor(props) {
    super(props);

    this.closeStates.forEach(state => {
      this.stageOptions.push({ value: state, label: sAction.app_strings.quote_stage_list[state] });
    });

    this.state = {
      quote_stage: this.defaultQuoteStage,
      date_closed: new Date(),
      lossReason: "",
      errors: [],
    };
  }

  handleCloseQuote = () => {
    const errors = [];

    if (!this.state.date_closed) {
      errors.push("date_closed");
    }

    this.setState({ errors });
    if (errors.length) {
      return;
    }

    const date = moment(this.state.date_closed).format("YYYY-MM-DD");
    let params = this.props.data.get("params").toJS();

    sAction.dsClear();
    sAction.dsAdd("set", params.prefix + "/fields/quote_stage/value", this.state.quote_stage);
    sAction.dsAdd("set", params.prefix + "/changes/fields/quote_stage", this.state.quote_stage);
    sAction.dsAdd("set", params.prefix + "/fields/date_quote_closed/value", date);
    sAction.dsAdd("set", params.prefix + "/changes/fields/date_quote_closed", date);
    if(this.state.lossReason){
      sAction.dsAdd("set", `${params.prefix}/fields/loss_reason/value`, this.state.lossReason)
      sAction.dsAdd("set", `${params.prefix}/fields/loss_reason/def/visible`, true)
      sAction.dsAdd("set", `${params.prefix}/changes/fields/loss_reason`, this.state.lossReason)
    }
    sAction.dsProcess();
    params.canSave = true;
    sAction.saveRecord(params, () => {
      sAction.popupHide();
      sAction.unLoad();
    });


  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });

    if (name === "quote_stage") {
      switch (value) {
        case "Closed Won":
        case "Closed Lost":
        case "Closed canceled client":
        case "Canceled ":
          this.setState({ opravit_nabidku: false, disabled_other_option: true });
          break;
      }
    }
  };

    getLossTextarea() {
      if (this.lostStates.includes(this.state.quote_stage)) {
        const data = {
          name: "lossReason",
          value: ""
        }
        return (<>
          <div className="closeQuoteContentTextarea">
            <div className="textareaEditContainer quoteTextAreaContent">
              <div className="inputContainer">
                <label htmlFor="lossReason">{sAction.translate("LBL_LOSS_REASON")}</label>
                <textarea
                    name="lossReason"
                    onChange={this.handleChange}
                    ref={this.input}
                    className="form-control form-control-sm"
                    type="text"
                    id={data.name}
                />
              </div>
            </div>
          </div>

        </>)
      }
      return null
    }

  render() {
    let lossReasonTextArea = this.getLossTextarea()


    return <div>
      <div className="viewActionPanel">
        <div className="viewActionPanelButtons">
          <Button className="hoverGreen" onClick={this.handleCloseQuote}>
            <div className={"icon-saveIcon calHeaderIcon"}/>
            {sAction.translate("LBL_CLOSE_OPPORTUNITY")}
          </Button>
          <div className="viewPanelButtonsDelimiter"/>
          <Button className="hoverRed" onClick={() => sAction.popupHide()}>
            <div className={"icon-close calHeaderIcon"}/>
            {sAction.translate("LBL_CANCEL_ACTION")}
          </Button>
          <div className="viewPanelButtonsDelimiter"/>
        </div>
      </div>
      <div className="CloseQuoteContainer">
        <div className="closeQuoteContent">
          <div className="closeQuoteContentLine">
            <span>{sAction.translate("LBL_QUOTE_STAGE")}</span>
            <Select
                open={false}
                name="quote_stage"
                options={this.stageOptions}
                defaultValue={this.defaultQuoteStage}
                onChange={this.handleChange}
            />
          </div>
          <div className="closeQuoteContentLine quoteDateClosed">
            <span>{sAction.translate("LBL_DATE_CLOSED", "Opportunities")}</span>
            <AcmDate
                value={this.state.date_closed}
                onChange={date => this.setState({date_closed: date})}
            />
          </div>
          {lossReasonTextArea}
        </div>
      </div>
    </div>;
  }
}

