import React, {useState} from 'react';
import {LoadError, Viewer, Worker} from '@react-pdf-viewer/core';
import sAction from 'sAction';
import './index.less';

const renderError = (error) => {
    let message = '';
    switch (error.name) {
        case 'InvalidPDFException':
            message = sAction.translate('LBL_INVALID_PDF', 'Documents');
            break;
        case 'MissingPDFException':
            message = sAction.translate('LBL_MISSING_PDF', 'Documents');
            break;
        case 'UnexpectedResponseException':
            message = sAction.translate('LBL_UNEXPECTED_RESPONSE', 'Documents');
            break;
        default:
            message = sAction.translate('LBL_PDF_ON_REMOTE_SERVER', 'Documents');
            break;
    }

    return (
        <div
            style={{
                alignItems: 'center',
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
            }}
        >
            <div
                style={{
                    backgroundColor: '#e53e3e',
                    borderRadius: '0.25rem',
                    color: '#fff',
                    padding: '0.5rem',
                }}
            >
                {message}
            </div>
        </div>
    );
};

function RightPanelPDF(props) {
    const pageLayout={
        tranformSize: ({size}) => ({
            height: size.height,
            width: size.width,
        }),
    };
    return (
        // workerUrl is required to load the PDF(needs to be in sync with the version of pdfjs)
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
            <div style={{height: '100%', width: '100%'}}>
                <Viewer
                    renderError={renderError}
                    pageLayout={pageLayout}
                    fileUrl={props.data.get('url')}

                />
            </div>
        </Worker>
    );
}

export default RightPanelPDF;
