export default function clearRelatedDynamicEnums(way, id, name) {
    const fields = this.dataGet(`${way}/fields`);
    this.dsClear();
    clearRelatedDynamicEnumField(this, fields, way, id, name);
    this.dsProcess();
}

function clearRelatedDynamicEnumField(sAction, fields, way, id, name) {
    fields.forEach(field => {
        if (field.def != null) {
            if (field.def.get?.('type') === 'AcmDynamicEnum') {
                if (field.def?.getIn?.(['relData', 'parent']) === id) {
                    // Vyjimka pro adresy
                    if (name && (name.split?.('_'))[1] === 'address'
                        && (field.def.get?.('name').split?.('_'))?.[1] === 'address'
                        && (field.def.get?.('name').split?.('_'))?.[0] !== (name.split?.('_'))[0]
                    ) {
                        return;
                    }
                    sAction.dsAdd('set', `${way}/fields/${field.name}/value`, '');
                    sAction.dsAdd('set', `${way}/changes/fields/${field.name}`, '');
                    clearRelatedDynamicEnumField(sAction, fields, way, field.def.get('options'), field.def.get('name'));
                }
            }
        }
    });
}
