import React, {useState, useEffect} from 'react';
import Button from '../../formElements/Button';
import sAction from 'sAction';

export default function IsInAbraPopup(props) {
    const [accountData, setAccountData] = useState(null);
    const [selectedAccount, setSelectedAccount] = useState(null);

    useEffect(() => {
        console.log('IsInAbraPopup', props);
        let params = props.data.get("params")
        if (params) {
            params = params.toJS()
        }
        const accountData = {
            id: params.id
        }
        sAction.rest.post('isAccountInAbra', accountData, (returnData) => {
            console.log('isAccountInAbra', returnData);
            console.log('isAccountInAbra data', returnData.message.data);
            if(returnData.status && returnData.message.data.length > 0) {
                setAccountData({
                    id: params.id,
                    results: returnData.message.data
                });
            }
        });
    }, []);

    const handleSelect = (result) => {
        console.log('handleSelect', result);
        setSelectedAccount(result);
    }

    const handleBack = () => {
        setSelectedAccount(null);
    }

    const handleSelectConfirm = () => {
        console.log('handleSelectConfirm', selectedAccount);
        sAction.dsClear();
        sAction.dsAdd('set', 'view/fields/abra_id/value', selectedAccount.id);
        sAction.dsAdd('set', 'view/changes/fields/abra_id', selectedAccount.id);
        sAction.dsProcess();
        sAction.popupHide();
        const prefix = 'view';
        const saveData = {
            canSave: true,
            id: sAction.dataGet(prefix + "/id"),
            module: sAction.dataGet(prefix + "/module"),
            prefix: prefix,
            type: sAction.dataGet(prefix + "/type"),
            view: sAction.dataGet("conf/view"),
          };
      
          sAction.saveRecord(saveData);
    }

    if(selectedAccount) {
        return (
            <div className='isAccountInAbraPopupContainer'>
                <h4>Vybraná firma:</h4>
                <div className='selectedResultsRow'>
                    <div className='resultLine resultCode'><p className='legendLineName'>Abra id: </p><p>{selectedAccount.id}</p></div>
                    <div className='resultLine resultName'><p className='legendLineName'>Název: </p><p>{selectedAccount.name}</p></div>
                    <div className='resultLine resultIC'><p className='legendLineName'>IČ: </p><p>{selectedAccount.orgidentnumber}</p></div>
                    <div className='resultLine resultDIC'><p className='legendLineName'>DIČ: </p><p>{selectedAccount.vatidentnumber}</p></div>
                    <div className='resultLine resultAddress'><p className='legendLineName'>popis: </p><p>{selectedAccount['ResidenceAddress_ID.DisplayName']}</p></div>
                </div>
                <br/>
                <p className='confirmText'>Převzít Abra id?</p>
                <div className='buttonContainer'>
                    <Button onClick={() => handleBack()}>Zpět</Button>
                    <Button onClick={() => handleSelectConfirm()}>Převzít</Button>
                </div>
            </div>
        );
    }

    return (
        <div className='isAccountInAbraPopupContainer'>
            <p>Firmy nalezené v Abře podle IČ a názvu, vyberte Abra id</p>
            <div className='isAccountInAbraPopupContainerResults'>
                <div className='resultsRow legend'>
                    <div className='resultCode'>Abra id</div>
                    <div className='resultName'>Název</div>
                    <div className='resultIC'>IČ</div>
                    <div className='resultDIC'>DIČ</div>
                    <div className='resultAddress'>popis</div>
                </div>
                {accountData && accountData.results.map((result, index) => {
                    return (
                        <div className='resultsRow' key={index} onClick={() => handleSelect(result)}>
                            <div className='resultCode'>{result.id}</div>
                            <div className='resultName'>{result.name}</div>
                            <div className='resultIC'>{result.orgidentnumber}</div>
                            <div className='resultDIC'>{result.vatidentnumber}</div>
                            <div className='resultAddress'>{result['ResidenceAddress_ID.DisplayName']}</div>
                        </div>
                    )
                })}
                {(!accountData?.results || accountData?.results.length <= 0) && 
                    <div className='resultsRow empty'>
                        <div className='noResults'>Žádné výsledky</div>
                    </div>
                }
            </div>
        </div>
    );
}
