import React from "react"
import AcmDate from "../../formElements/AcmDate.js"
import FormField from "./../formField.js"

export default class Date2 extends FormField {

    save(date) {
        if (this.check(date)) {
            const data = {
                way: this.props.way,
                name: this.props.data.name,
                value: date,
                cancelEdit:false,
            };
            this.saveField(data);
        }
    }
    check(date) {
        var required = this.props.data.def.get("required");
        if (required == undefined) {
            required = false;
        }
        var ok = true;
        var state = {};

        if (date == "" && required == true) {
            state.dateOk = false;
            ok = false;
        } else {
            state.dateOk = true;
        }

        this.setState(state);

        return ok;
    }
    render() {
        const data = this.props.data;
        return (
            <AcmDate
                name={this.props.data.get('name')}
                prefix={this.props.prefix}
                way={this.props.way}
                index={this.props.index}
                type={this.props.data.get('type')}
                rowWay={this.props.rowWay}
                autoOpen={true}
                autoFocus={true}
                onChange={(value) => this.save(value)} value={data.value}
            />
        );
    }
}
