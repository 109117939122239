export default function serviceOrdersSetCode(params) {
    if (params.value.id) {
        let data = {
            module: 'ProductTemplates',
            fields: ['id'],
            getFields: ['id', 'name', 'mft_part_num_int'],
            value: params.value.id
        };
        let self = this
        this.quickSearch(data, returnData => {
            self.dsClear();
            self.dsAdd('set', params.way + '/customData/customLines/lines/' + params.row + '/kod', returnData[0]['mft_part_num_int']);
            self.dsAdd('set', params.way + '/customData/customLines/lines/' + params.row + '/mj', 'ks');
            self.dsProcess();
        });
    }
}
