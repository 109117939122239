export default function sendToAbra(params) {
    const data = {
        header: this.translate('LBL_QUOTE_CREATE_ORDER', 'Quotes'),
        buttons: [
            { label: this.translate('LBL_NO'), callback: () => this.popupHide() },
            // Poslat do Abry
            {
                label: this.translate('LBL_YES'),
                callback: () => {
                    this.load();
                    this.popupHide();
                    this.rest.post('sendToAbra', {orderID: params.id}, returnData => {
                        this.unLoad();
                        if (returnData.status) {
                            const data = {
                                header: this.translate('LBL_INFO'),
                                buttons: [
                                    {
                                        label: this.translate('LBL_YES'),
                                        callback: () => this.popupHide(),
                                    },
                                ],
                            };
                            this.popup(this.translate(returnData.message.text), data);
                        } else {
                            this.error(this.translate(returnData.errorMessage.text));
                        }
                    });
                },
            },
        ],
    };

    let message = this.translate('LBL_SEND_TO_ABRA_QUESTION', 'Quotes');

    this.popup(message, data);
}
