import React from 'react';

const MixedNodeElement = ({ nodeData = {}, triggerNodeToggle, foreignObjectProps = {} }) => {
    return (
        <React.Fragment>
            <circle r={18}></circle>
            <foreignObject {...foreignObjectProps}>
                <div className={'nodeLabelContainer' + (nodeData.attributes.id === 'centerAccNode' ? ' centerAccNode' : '')}>
                    <a href={nodeData.data.acc_url} target="_blank" ><b>{nodeData.name}</b></a>
                    <ul style={{ listStyleType: 'none', padding: 0 }}>

                        {nodeData.attributes &&
                        <>
                            <li key={`${nodeData.name}-attributes-name`}>
                                Přiřazeno: {nodeData.attributes.name}
                            </li>
                        </>
                        }
                        {nodeData.data.contacts && nodeData.data.contacts.length !== 0 &&
                        <>
                            <li className='labelLine'></li>
                            <li key={`${nodeData.name}-attributes`}><b>Přiřazené kontakty:</b></li>

                            {nodeData.data.contacts.map((contact, i) => (
                                <li key={`${contact.id}-${i}`}>
                                    <a href={contact.url} target="_blank" >{`${contact.salutation ? contact.salutation : ''} ${contact.first_name ? contact.first_name : ''} ${contact.last_name ? contact.last_name : ''}`}</a>
                                </li>
                            ))}
                        </>}
                    </ul>
                </div>
            </foreignObject>
        </React.Fragment>
    );
};

export default MixedNodeElement;