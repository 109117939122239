export default function route(init = true, data = null,forceView = null) {
  this.load();

  //TODO delka historie do nastaveni
  //Pokazde pred routovanim se nastavi v storu momentalni url na zacatek listu
  let history = this.dataGet("history/urls");
  if(history.last() !== window.location.href){
    history = history.push(window.location.href);
  }
  if(history.size >= 20){
    history = history.splice(1, 1);
  }
  this.dataSet("history/urls", history)



  this.temporaryData = {};
  if (init == false) {
    this.dsClear();
    this.dsAdd("set","view",null);
    this.dsAdd("set","notifications/open",false);
  }

  var view = this.getViewName();
  if(forceView != null){
    view = forceView
  }
  const module = this.getModuleName();

  this.dsAdd("set", "conf/view", view);
  this.dsAdd("set", "conf/page", module);
  this.dsAdd("set", "conf/load", false);
  this.dsAdd("set", "conf/popup/show", false);
  this.dsAdd("set", "conf/popup/content", null);
  this.dsAdd("set", "menu/activeModule", module);
  this.dsAdd("set", "rightPanel/show", false);

  if (view !== "import") {
    sessionStorage.removeItem('listImported');
  }
  if (module == "Emails") {
    init = view == "list";
    const openEmail = view == "detail";
    this.routeEmail(init, openEmail);
  } else if (view == "list") {
    var limit = null;
    if(data && data.user){
      limit = data.user.records_per_page
    }
    this.routeListView(init,limit);
  } else if (view == "detail") {
    this.routeDetailView();
  } else if (view == "home" || view == "") {
    this.routeHome(init);
  } else if (view == "globalSearch"){
    this.routeGlobalSearch();
  } else if(view == "leadConvert"){
    this.routeLeadConvert();
  } else if (view == "admin") {
    this.routeAdmin();
  } else if(view == "merge"){
    this.routeMerge();
  } else if (view == "import") {
    this.routeImport();
  } else if (view == "custom") {
    this.routeCustom();
  }
   else {
    this.dsProcess();
  }
}
