import React, {PureComponent} from "react";
import Select from "../../formElements/Select";
import sAction from "sAction";
import Button from "../../formElements/Button";

export default class SetCampaignStage extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            campaignStage: '',
        }

        this.campaignActionsList = []

        sAction.app_strings.campaignaction_status_list.forEachObject((lbl, key) => {
            this.campaignActionsList.push({value: key, label: lbl});
        });
    }

    addToCampaigns() {
        sAction.load()
        sAction.rest.post("convertToCampaignsAction", {
            campaignId: this.props.data.get("data").get("campaignId"),
            prospectListId: this.props.data.get("data").get("prospectListId"),
            campaignStage: this.state.campaignStage
        }, returnData => {
            if (returnData.status) {
                sAction.unLoad()
                sAction.popupHide()
                sAction.popupHide()
                sAction.alert(<div style={{margin: "auto"}}>{sAction.translate("LBL_MKT_LIST_CONVERTED")} <a
                    href={`#detail/acm_CampaignsAction/${returnData.message.data.campaignId}`}>{returnData.message.data.campaignName}</a></div>);
            }
        });
    }

    render() {
        return (
            <div className="simpleForm" style={{padding: "10px"}}>
                <table>
                    <tbody>
                    <tr>
                        <td style={{width: "40%", borderBottom: "none"}}
                            className="formlabel">{sAction.translate("LBL_STATUS", "acm_CampaignsAction")}</td>
                        <td style={{borderBottom: "none"}}>
                            <Select
                                value={this.state.campaignStage}
                                containerClassName="withBorder"
                                onChange={(e) => this.setState({campaignStage: e.target.value})}
                                options={this.campaignActionsList}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} style={{paddingTop: "70px"}}>
                            <div className="viewActionPanelButtons flexRight">
                                <Button onClick={() => sAction.popupHide()} className='popupCloseButton withBorder'>
                                    <div className={"actionPanelButtonIcon icon-closeIconLight"}/>
                                    {sAction.translate("LBL_EXPORT_CLOSE")}
                                </Button>
                                <Button onClick={() => this.addToCampaigns()} className='withBorder'>
                                    <div className={"actionPanelButtonIcon icon-download"}/>
                                    {sAction.translate("LBL_ADD_TO_ENUM")}
                                </Button>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}