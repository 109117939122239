import React from 'react';
import PureComponent from '../../pure';

import Button from '../../formElements/Button';

import  sAction  from 'sAction';
import Checkbox from '../../formElements/TickBox';
import Select from '../../formElements/Select';

export default class PrintQuotePopup extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            productType: false,
            listPrice: false,
        }

        this.template = React.createRef();
    }


    print() {
        const params = this.props.data.get('params').toJS();
        let printData = params;
        printData.template = this.template.current.value;
        printData.listPrice = this.state.listPrice;
        printData.productType = this.state.productType;
        switch (params.buttonId) {
            case 'print_quote':
                sAction.printPrepare(printData);
                break;
            case 'send_quote':
                if (printData.template === 'no_template') {
                    sAction.openEmailPopup(printData);
                } else {
                    sAction.getSavedPrintFile(printData);
                }
                break;
        }
    }

    render() {
        let templates = [];
        let defaultValue = null;
        sAction.app_strings['quote_print_templates'].forEachObject((label, value) => {
            templates.push({label: label, value: value});
            if (!defaultValue) {
                defaultValue = value;
            }
        });
        if (
            this.props.data.get('params').get('buttonId') === 'send_quote' &&
            sAction.dataGet(this.props.data.get('params').get('prefix') + '/fields/quotetype/value') === 'simplified'
        ) {
            templates.push({label: sAction.translate('LBL_NO_TEMPLATE', 'Quotes'), value: 'no_template'});
            defaultValue = 'no_template';
        }

        return (
            <div className='multipleCallsTable'>
                <div className='mcRow'>
                    <div className='mcCell'>{sAction.translate('LBL_PRINT_QUOTE_INCLUDE_LIST_PRICE')}</div>
                    <div className='mcCell'>
                        <Checkbox
                            checked={this.listPrice}
                            onChange={e => this.setState({ listPrice: e })}
                            key={'listPrice'}
                        />
                    </div>
                </div>
                <div className='mcRow'>
                    <div className='mcCell'>{sAction.translate('LBL_PRINT_QUOTE_SELECT_TEMPLATE')}</div>
                    <div className='mcCell'>
                        <Select
                            open={false}
                            name='template'
                            options={templates}
                            defaultValue={defaultValue}
                            myRef={this.template}
                        />
                    </div>
                </div>
                <div className='mcRow' style={{paddingTop: '10px'}}>
                    <div className='mcCell mcButtons viewActionPanelButtons'>
                        <Button className='hoverRed' onClick={() => sAction.popupHide()}>
                            <div className={'icon-close calHeaderIcon'} > </div>
                            {sAction.translate('LBL_CANCEL_ACTION')}
                        </Button>
                        <Button className='hoverBlue' onClick={() => this.print()}>
                            <div className={'icon-print calHeaderIcon'} > </div>
                            {sAction.translate('LBL_PRINT', 'Quotes')}
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}
