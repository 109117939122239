export default function duplicateRecord(param) {
  const modul = param.module;
  const paramType = param.type;

  const detail = this.dataGet(param.prefix);
  if (param.type === "rightPanel") {
    this.dataSet("rightPanel/closeOnSave", true);
  }

  // pro coripo/cemat#205
  const branchFields = [
    "id",
    "whose_service",
    "notifyuser",
    "account_type",
    "website",
    "email1",
    "name",
    "dealer_type",
  ];
  //END

  this.dsClear();
  const parentFields = [];
  detail.fields.entrySeq().forEach((e) => {
    const name = e[0];
    const field = e[1];
    const value = field.value;
if(field){
      parentFields[name] = field.toJS();
    }
    // pro coripo/cemat#205
    if (param.createBranch && paramType === "parent" && !branchFields.includes(name)) {
      this.dsAdd("set", param.prefix + "/changes/fields/" + name, "");
      this.dsAdd("set", param.prefix + "/fields/" + name + "/value", "");
      return;
    }
    //END

    // vyjimka pro cemat coripo/cemat#42 - 3.5.3.1
    if (modul === "acm_machines" && name === "machinenum") {
      this.dsAdd("set", param.prefix + "/fields/addon_device_number/value", "");
      this.dsAdd("set", param.prefix + "/changes/fields/addon_device_number", "");
      this.dsAdd("set", param.prefix + "/fields/erp_id/value", "");
      this.dsAdd("set", param.prefix + "/changes/fields/erp_id", "");
      this.dsAdd("set", param.prefix + "/fields/motohours/value", "");
      this.dsAdd("set", param.prefix + "/changes/fields/motohours", "");
      if (name == "machienum") {
        this.dsAdd("set", param.prefix + "/fields/machinenum/value", "");
        return;
      }
    }

    //END

    // vyjimka pro cemat coripo/cemat#66 - 3.11.3.1
    if (modul === "acm_orders") {
      this.dsAdd("set", param.prefix + "/fields/name/value", "");
      return;
    }
    //END

    // vyjimka pro cemat coripo/cemat#70 - 3.13.3.1
    if (modul === "acm_service_order") {
      this.dsAdd("set", param.prefix + "/fields/name/value", "");
      return;
    }
    //END

    const type = field.def.get("type");
    if (name !== "id") {
      if (type == "relate" || type == "link" || type == "parent") {
        this.dsAdd(
          "set",
          param.prefix + "/changes/fields/" + field.def.get("id_name"),
          field.def.get("id_value")
        );

        if (type == "parent") {
          this.dsAdd(
            "set",
            param.prefix + "/changes/fields/parent_type",
            field.def.get("parent_type_value")
          );
        }
      } else {
        this.dsAdd("set", param.prefix + "/changes/fields/" + name, value);
      }
      this.dsAdd("set", param.prefix + "/changes/fields/" + name, value);
    }
  });
  this.dsAdd("set", param.prefix + "/id", null);

  if (this.dataGet(param.prefix + "/customData")) {
    this.dsAdd("set", param.prefix + "/customData/duplicateId", param.id);
  }
  if (
    detail.customData &&
    detail.customData.get &&
    detail.customData.get("customLines") &&
    detail.customData.get("customLines").get("lines")
  ) {
    detail.customData
      .get("customLines")
      .get("lines")
      .toJS()
      .forEachObject((line, key) => {
        this.dsAdd(
          "set",
          param.prefix + "/customData/customLines/lines/" + key + "/id",
          null
        );
      });
  }
  // pro coripo/cemat#205
  if (param.createBranch && paramType === "parent") {
    this.dsAdd("set", param.prefix + "/changes/fields/parent_id", param.id);
    this.dsAdd("set", param.prefix + "/fields/parent_id/value", param.id);
    this.dsAdd("set", param.prefix + "/fields/parent_name/value", param.name);

    this.dsAdd("set", param.prefix + "/fields/email1/def/emails", []);

    this.dsAdd(
      "set",
      param.prefix + "/fields/acm_address/def/item/cast_obce",
      ""
    );
    this.dsAdd("set", param.prefix + "/fields/acm_address/def/item/gpsX", "");
    this.dsAdd("set", param.prefix + "/fields/acm_address/def/item/gpsY", "");
    this.dsAdd("set", param.prefix + "/fields/acm_address/def/item/kraj", "");
    this.dsAdd("set", param.prefix + "/fields/acm_address/def/item/obec", "");
    this.dsAdd("set", param.prefix + "/fields/acm_address/def/item/okres", "");
    this.dsAdd("set", param.prefix + "/fields/acm_address/def/item/psc", "");

    // coripo/cematsk#25 - prenest fakturacni adresu z rodice
    this.dsAdd("set", param.prefix + "/fields/billing_address_street/value", parentFields.billing_address_street.value);
    this.dsAdd("set", param.prefix + "/fields/billing_address_city/value", parentFields.billing_address_city.value);
    this.dsAdd("set", param.prefix + "/fields/billing_address_state/value", parentFields.billing_address_state.value);
    this.dsAdd("set", param.prefix + "/fields/billing_address_postalcode/value", parentFields.billing_address_postalcode.value);
    this.dsAdd("set", param.prefix + "/fields/billing_address_country/value", parentFields.billing_address_country.value);
    this.dsAdd("set", param.prefix + "/fields/billing_address_kraj/value", parentFields.billing_address_kraj.value);
    this.dsAdd("set", param.prefix + "/fields/billing_address_kraj/dev/options", parentFields.billing_address_kraj.def.options);

    this.dsAdd("set", param.prefix + "/changes/fields/billing_address_street", parentFields.billing_address_street.value);
    this.dsAdd("set", param.prefix + "/changes/fields/billing_address_city", parentFields.billing_address_city.value);
    this.dsAdd("set", param.prefix + "/changes/fields/billing_address_state", parentFields.billing_address_state.value);
    this.dsAdd("set", param.prefix + "/changes/fields/billing_address_postalcode", parentFields.billing_address_postalcode.value);
    this.dsAdd("set", param.prefix + "/changes/fields/billing_address_country", parentFields.billing_address_country.value);
    this.dsAdd("set", param.prefix + "/changes/fields/billing_address_kraj", parentFields.billing_address_kraj.value);

    // coripo/cematsk#41 - prenest dealer_type z rodice
    this.dsAdd("set", param.prefix + "/fields/dealer_type/value", parentFields.dealer_type.value);
    this.dsAdd("set", param.prefix + "/changes/fields/dealer_type", parentFields.dealer_type.value);

  }
  //END
  this.dsProcess();
}
