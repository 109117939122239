import detailDefault from "../../detailDefault";

export default class detailacm_databox_persons extends detailDefault {
    load(sAction, data) {
        this.hideButtons(sAction, data);
    }

    hideButtons(sAction, data) {
        const imported = sAction.dataGet(data.prefix + "/fields/imported/value");
        if (imported === "1") {
            sAction.dsClear();
            sAction.dsAdd("delete", data.prefix + "/buttons", 5);
            sAction.dsProcess();
        }
    }
}