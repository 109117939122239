export default function openSimpleDynamicEnum(way, data, module, callback) {
    const enumId = data.def.get('options');

    const params = {
        enum: enumId,
        options: data.def.get('options') ? data.def.get('options') : data.def.get('name'),
        module: module,
    };

    this.rest.post('simpleDynamicEnumList', params, (returnData) => {
        if (returnData['status'] == false) {
            this.unLoad();
            this.error(this.translate(returnData['errorMessage']['text']));
            return;
        }
        const parsedData = returnData['message']['data'];

        parsedData['lines'] = parsedData['lines'].sort((a, b) => {
            return a.localeCompare(b);
        });
        callback(parsedData);
    });
}
