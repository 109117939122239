
import React from 'react';

import InputField from '../inputFieldClass';
import InputText from '../../formElements/InputText';

import sAction from 'sAction';


export default class Url extends InputField {
    constructor() {
        super();
        this.state = {
            state: 'fine',
            reg: /https?:\/\/(www\.)?[-a-zA-ZÀ-ž0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-ZÀ-ž0-9()@:%_\+.~#?&\/=]*)/,
        };
        this.input = React.createRef();
    }
    componentDidMount() {

    }
    onKeyDown(e, type = null, save = true) {
        switch (e.keyCode) {
            case 8:
            case 46:
                const value = this.props.data.value?.split('|');
                if (value[value.length - 1] === '' && value.length !== 1) {
                    e.preventDefault();
                    this.deleteUrl(value.length - 1, true);
                }
                break;
            case 13:
                this.addUrl();
                break;
            case 9:
                this.save()
                super.onKeyDown(e, type, save);
                break;
            default:
                break;
        }
    }

    render() {
        const data = this.props.data;
        const newRecord = this.props.newRecord;
        const ok = this.state.ok;
        // var inputClass = "form-control form-control-sm";
        const containerClass = 'inputEditContainer';
        let error = false;
        if (ok === false) {
            //  inputClass += " wrongInput";
            error = true;
        }
        let urls = [''];

        if (data.value != null) {
            urls = data.value.split('|');
        }

        let value = data.value;
        if (newRecord && (value == null || value == undefined)) {
            value = '';
        }

        const urlsCount = urls.length;

        const content = [];

        const astyle = {};
        urls.forEach((v, i) => {
            content.push(
                <tr key={v+i+urlsCount}>
                    <td id={'websiteInput'+i}>
                        <div className={containerClass} data-fieldname={data.name} >
                            <div className="inputContainer">
                                <InputText
                                    autoFocus={true}
                                    // onKeyUp={e => this.keyUpCall(e)}
                                    // onKeyDown={e => this.onKeyDown(e)}
                                    myRef={this.input}
                                    onBlur={this.onChange}
                                    onChange={(e) => this.onChange(e)}
                                    error={error}
                                    defaultValue={v}
                                    onKeyDown={(e) => this.onKeyDown(e)}
                                    // value={v}
                                    id={i.toString()}
                                    name={i.toString()}
                                />
                            </div>

              {!newRecord && (
                <div className="buttonContainer">
                  <div
                    tabIndex="2"
                    onClick={() => this.save()}
                    className="inputEditButton"
                  >
                    <div className={"icon-detailConfrim"} />
                  </div>
                  <div
                    tabIndex="3"
                    onClick={() => this.deleteUrl(i)}
                    className=" inputEditButton"
                  >
                    <div className={"icon-detailCancel"} />
                  </div>
                </div>
              )}
            </div>
          </td>
        </tr>
      );
    });

    return (
      <React.Fragment>
        {1 > 0 && (
          <table className="DetailViewValue__emails">
            <tbody>
            {content}
            </tbody>
          </table>
        )}
        {/*Přidat e-mail*/}
        <a
          onClick={() => this.addUrl()}
          tabIndex="0"
          style={astyle}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              this.addUrl();
            }
          }}
        >
          + <span className="icon-link"/>
          {" "+sAction.translate("LNK_NEW_URL", "Contacts")}
        </a>
      </React.Fragment>
    );
  }

    save() {
        const val = sAction.dataGet(this.props.way + '/value');

        sAction.dsClear();

        sAction.dataSet(this.props.way + '/value', val);
        sAction.dataSet(this.props.prefix + '/changes/fields/' + this.props.data.get('name'), val);
        // sAction.dataSet(this.props.way + '/actEdit', false);
        sAction.dsProcess();
    }

    addUrl = () => {
        const val = sAction.dataGet(this.props.way + '/value')+'|';

        sAction.dsClear();

        sAction.dataSet(this.props.way + '/value', val);

        sAction.dsProcess();
    };
    onChange = (e) => {
        const val = sAction.dataGet(this.props.way + '/value');
        const tval = e.target.value;
        const tid = e.target.id;
        let urls = [''];
        if (val != null) {
            urls = val.split('|');
        }
        urls[tid] = tval;
        const fval = urls.join('|');

        sAction.dsClear();

        sAction.dataSet(this.props.way + '/value', fval);
        sAction.dataSet(this.props.prefix + '/changes/fields/' + this.props.data.get('name'), fval);

        sAction.dsProcess();
    };
    deleteUrl = (i, actEdit = false) => {
        // document.getElementById("websiteInput"+i).remove();
        const val = sAction.dataGet(this.props.way + '/value');

        let urls = [''];
        if (val != null) {
            urls = val.split('|');
        }

        urls[i] = '';

        let fval = urls.join('|').replace('||', '|').replace(/\|$/, '');

        if (fval[0] == '|') {
            fval = fval.substring(1);
        }

        sAction.dsClear();

        sAction.dataSet(this.props.way + '/value', fval);
        sAction.dataSet(this.props.prefix + '/changes/fields/' + this.props.data.get('name'), fval);
        sAction.dataSet(this.props.way + '/actEdit', actEdit);

        sAction.dsProcess();
    };
}
