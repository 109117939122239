import React, {Component} from 'react';
import PureComponent from '../../pure';

import sAction from 'sAction';
import TextField from '@material-ui/core/TextField';

import Relate from '../../formElements/Relate';
import CheckBox from '../../formElements/CheckBox';
import TickBox from '../../formElements/TickBox';
import Select from '../../formElements/Select';
import confProd from './productLineConfig';
import Image from './Image';
import Enum from '../../listViewFields/Enum';
import StockLine from './StockLine';


class QProduct extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            descFocused: false,
            quantity: null,
        };

        this.quantity = null;
        this.prevQuantity = null;
        this.productChanged = false;
    }


    valChange(way, val) {
        sAction.updateDetailCustom('field', {way: way, value: val});
    }

    uploadImage = (val) => {
        const way = this.props.way + '/' + this.props.ind + '/picture';
        sAction.updateDetailCustom('field', {way: way, value: val});
    };

    productCallback(way, key, item) {
        const itway = way+'/'+key;
        const productId = sAction.dataGet(itway + '/product_template_id');
        if (!productId) {
            this.productChanged = true;
        }

        if (item.id != undefined && item.id != '') {
            // product template has been selected
            sAction.getProductTemplate(item.id, 'prodName', way, key);
        } else {
            sAction.updateDetailCustom('prodName', {way: way + '/' + key, item: item, template: false});
        }
    }

    prodInfo(params) {
        window.open('#detail/Products/'+params.id, '_blank');
    }

    blurDescription(way, val) {
        // this.setState({descFocused : false});
        if (sAction.dataGet(way) !== val) {
            this.valChange(way, val);
        }
    }

    handleMove(type, way, ind) {
        let list = sAction.dataGet(way);
        const cnt = list.size;

        if (type == 'up') {
            if (ind == 0) {
                return;
            }
            const pomProd = list.get(ind);
            list = list.delete(ind);
            list = list.insert(ind - 1, pomProd);
        } else if (type == 'down') {
            if ((ind + 1) >= cnt) {
                return;
            }
            const pomProd = list.get(ind + 1);
            list = list.delete(ind + 1);
            list = list.insert(ind, pomProd);
        }


        sAction.dsClear();
        sAction.dsAdd('set', way, list);
        sAction.dsAdd('set', 'view/changes/forceChange', true);
        sAction.dsProcess();
    }

    getStockOptions() {
        const stockOptions = {
            current_stock: [],
            stock_rezervace: [],
        };

        confProd.fields?.forEachObject((field) => {
            if (field.name.includes('stock') && !field.visible) {
                if (field.name.includes('rezervace')) {
                    stockOptions.stock_rezervace.push(field);
                    return;
                }
                stockOptions.current_stock.push(field);
            }
        });

        return stockOptions;
    }

    render() {
        const conf = confProd.fields;

        const data = this.props.data;

        const way = this.props.way;
        const ind = this.props.ind;
        const itway = way+'/'+ind;
        const readonly = this.props.readonly;

        const dataProd = {value: data.name, id_value: data.id, searchFields: ['name', 'mft_part_num', 'mft_part_num_int']};
        const module = 'ProductTemplates';

        const taxClassOptions =[];
        sAction.app_strings.tax_class_dom.forEachObject((lbl, key) => {
            taxClassOptions.push({value: key, label: lbl});
        });
        const semaphoreOpt = [];
        sAction.app_strings.margin_check_list.forEachObject((lbl, key) => {
            semaphoreOpt.push({value: key, label: lbl});
        });
        const semaphoreColors = sAction.app_strings.margin_check_list_colors;
        // sAction.app_strings.margin_check_list_colors.forEachObject((lbl, key) => {
        //     semaphoreColors.push({value: key, label: lbl});
        // });

        let curId = this.props.currency_id;
        if (!curId) {
            curId = '-99';
        }
        const discountOptions = [{value: '0', label: sAction.currencies[curId].symbol}, {value: '1', label: '%'}];
        const discountVal = data.discount_select;


        const buttons = [];
        if (data.id && !readonly) {
            buttons.push({id: 'fullForm', className: 'icon-expandIcon qProductProdNameIcon', onClick: this.prodInfo, params: {id: data.id}});
        }

        const descClass = 'productLineDescription';
        const keyPre = Date.now();

        const renderComp = [];

        const self = this;

        let relateClass = 'qProductProdNameField';

        let arrowsContainer = null;
        if (readonly) {
            relateClass += ' readonlyRel';
        } else {
            arrowsContainer = <div className="qProductLineButtonContainer">
                <div className={'icon-arrowUp prodLineIcon hoverBlue'} onClick={() => this.handleMove('up', way, ind)}></div>
                <div className={'icon-arrowDown prodLineIcon hoverBlue'} onClick={() => this.handleMove('down', way, ind)}></div>
            </div>;
        }


        conf.forEach( function(field) {
            if (!field.visible) {
                return;
            }

            switch (field.type) {
                case 'number':
                // zruseno protoze rozbiji pretahovani readku issue: coripo/cemat#751
                // (bylo byp potreba implementovat nejaky flag na prenos aby se neaplikovalo pokud je qty 1)
                // const productId = sAction.dataGet(itway + "/product_template_id");
                // if(field.name === "quantity" && self.quantity > 1 && data[field.name] !== self.quantity && !productId){
                    if (field.name === 'quantity' && self.quantity > 1 && data[field.name] !== self.quantity && self.productChanged) {
                        let qty = self.quantity;
                        const qtyOrg = data[field.name];
                        if (qtyOrg !== qty && qtyOrg > 1) {
                            qty = qtyOrg;
                        }

                        self.valChange(itway + '/' + field.name, qty);
                    }
                    self.productChanged = false;

                    renderComp.push(
                        <div style={{width: field.width + '%'}} key={keyPre + '_' + field.name}>
                            <TextField
                                margin="normal"
                                variant="outlined"
                                id={field.name}
                                type="number"
                                defaultValue={data[field.name]}
                                autoComplete="of"
                                disabled={field.disabled || readonly}
                                key={keyPre + '_' + field.name}
                                className="quoteProductField quoteNumberInput"
                                onBlur={(ev) => {
                                    self.valChange(itway + '/' + field.name, ev.target.value);
                                    self.quantity = ev.target.value;
                                }}
                            />
                        </div>,
                    );
                    break;
                case 'relateProd':
                    renderComp.push(
                        <div className={relateClass} style={{width: field.width+'%'}} key={keyPre+'_'+field.name}>
                            <Relate
                                newRecord={false}
                                data={dataProd}
                                module={module}
                                buttons={buttons}
                                key={keyPre+'_'+field.name}
                                InputProps={{readOnly: readonly}}
                                callback={(item) => self.productCallback(way, ind, item)}
                            />
                        </div>,
                    );
                    break;
                case 'text':
                    renderComp.push(
                        <div style={{width: field.width+'%'}} key={keyPre+'_'+field.name}>
                            <TextField
                                margin="normal"
                                variant="outlined"
                                className="quoteProductField"
                                id={field.name}
                                type="text"
                                autoComplete="of"
                                disabled={field.disabled || readonly}
                                defaultValue={data[field.name]}
                                key={keyPre+'_'+data[field.name]}
                                onBlur={(ev) => self.valChange(itway+'/'+field.name, ev.target.value)}
                            />
                        </div>,
                    );
                    break;
                case 'tax_class':
                    renderComp.push(
                        <div style={{width: field.width+'%'}} key={keyPre+'_'+field.name}>
                            <Select
                                open={false}
                                name={field.name}
                                options={taxClassOptions}
                                defaultValue={data[field.name]}
                                disabled={field.disabled || readonly}
                                key={keyPre+'_'+field.name}
                                onChange={(ev) => self.valChange(itway+'/'+field.name, ev.target.value)}
                            />
                        </div>,
                    );
                    break;
                case 'discount_select':
                    renderComp.push(
                        <div style={{width: field.width+'%'}} key={keyPre+'_'+field.name}>
                            <Select
                                open={false}
                                name={field.name}
                                options={discountOptions}
                                defaultValue={discountVal}
                                disabled={field.disabled || readonly}
                                key={keyPre+'_'+field.name}
                                onChange={(ev) => self.valChange(itway+'/'+field.name, +ev.target.value)}
                            />
                        </div>,
                    );
                    break;
                case 'price':
                    let val = 0;
                    if (field.name == 'discount_price') {
                        let discount = 0;

                        discount = sAction.parseNumber(data.discount_amount);
                        if (data.discount_select) {
                            discount = (sAction.parseNumber(data.list_price) / 100) * discount;
                        }

                        val = sAction.parseNumber(data.list_price) - discount;
                    } else if (field.name == 'total_price') {
                        let discount = 0;

                        discount = sAction.parseNumber(data.discount_amount);
                        if (data.discount_select) {
                            discount = (sAction.parseNumber(data.list_price) / 100) * discount;
                        }

                        val = (sAction.parseNumber(data.list_price) - discount) * data.quantity;
                    } else {
                        val = sAction.parseNumber(data[field.name]);
                    }

                    renderComp.push(
                        <div style={{width: field.width+'%'}} key={keyPre+'_'+field.name}>
                            <TextField
                                className="priceInput quoteProductField"
                                margin="normal"
                                variant="outlined"
                                id={field.name}
                                type="text"
                                key={keyPre+'_'+field.name}
                                autoComplete="of"
                                disabled={field.disabled || readonly}
                                defaultValue={sAction.formatNumber(val)}
                                onBlur={(ev) => self.valChange(itway+'/'+field.name, sAction.parseNumber(ev.target.value))}
                            />
                        </div>,
                    );
                    break;
                case 'checkbox':
                    renderComp.push(
                        <div style={{width: field.width+'%'}} key={keyPre+'_'+field.name}>
                            <CheckBox
                                name={field.name}
                                defaultValue={data[field.name]}
                                disabled={field.disabled || readonly}
                                onChange={(value) => self.valChange(itway+'/'+field.name, +value)}
                            />
                        </div>,
                    );
                    break;
                case 'bool':
                    let boolVal = data[field.name];
                    if (boolVal === null || boolVal === undefined) {
                        boolVal = field?.defaultValue;
                    }

                    renderComp.push(
                        <div style={{width: field.width+'%'}} key={keyPre+'_'+field.name}>
                            <TickBox
                                name={field.name}
                                defaultValue={!!parseInt(boolVal)}
                                checked={!!parseInt(boolVal)}
                                disabled={field.disabled || readonly}
                                onChange={(value) => self.valChange(itway+'/'+field.name, +value)}
                            />
                        </div>,
                    );
                    break;
                case 'semaphore':
                    let semaphoreValue = 'grey';

                    if (data.cost_price) {
                        let discount = sAction.parseNumber(data.discount_amount);
                        if (data.discount_select) {
                            discount = (sAction.parseNumber(data.list_price) / 100) * discount;
                        }
                        const margin = (sAction.parseNumber(data.list_price) - discount) / sAction.parseNumber(data.cost_price);

                        if (margin >= 1.2) {
                            semaphoreValue = 'green';
                        } else if (margin >= 1.1) {
                            semaphoreValue = 'orange';
                        } else {
                            semaphoreValue = 'red';
                        }
                    }

                    renderComp.push(
                        <div style={{width: field.width+'%'}} key={keyPre+'_'+field.name}>
                            <Enum
                                option={sAction.app_strings.margin_check_list}
                                colors={sAction.app_strings.margin_check_list_colors}
                                value={semaphoreValue}
                                // bcColor={bcColor}
                                // extraClass={extraClass}
                                onClick={(e) => {}}
                                extraClass={'qProduct'}
                            />
                        </div>,
                    );
                    break;
                case 'stock':
                    const stockOptions = self.getStockOptions();
                    renderComp.push(
                        <div style={{width: field.width + '%'}} key={keyPre + '_' + field.name}>
                            <StockLine
                                stockOptions={stockOptions}
                                productData={data}
                                fieldDefinition={field}
                                extraClass={'qProduct'}
                            />
                        </div>);
                    break;
            }
        });

        let descComp = null;
        if (this.state.descFocused) {
            descComp = <React.Fragment>
                <Image picture={data.picture} onChange={this.uploadImage} way={itway} />
                <TextField
                    className={descClass}
                    multiline={true}
                    type="text"
                    margin="normal"
                    variant="outlined"
                    rows={10}
                    key={keyPre + '_description'}
                    onFocus={() => this.setState({descFocused: true})}
                    onBlur={(ev) => this.blurDescription(itway+'/description', ev.target.value)}
                    defaultValue={data.description}
                    autoFocus={true}
                />
            </React.Fragment>;
        } else {
            descComp = <div>{data.description}</div>;
        }
        let prodlinesIcons = <div className="qProductLineButtonContainer">
            <div className="prodLineIcon" ></div>
            <div className="prodLineIcon" ></div>
        </div>;
        if (!readonly) {
            prodlinesIcons = <div className="qProductLineButtonContainer">
                <div className="icon-deleteIcon" onClick={() => sAction.updateDetailCustom('delItem', {way: way, index: ind})}></div>
                <div className="icon-editDesc" onClick={() => this.setState({descFocused: !this.state.descFocused})}></div>
            </div>;
        }


        return (<div>
            <div className="qProductLine">
                <div className="qProductLineButtonContainer">
                    <div
                        onClick={() => sAction.rightPanelDetail('Products', data.id)}
                        className={data.id ? 'icon-eye hoverBlue' : ''}
                        style={{'margin': 'auto', 'marginLeft': '2px', 'width': '19px', 'fontSize': 14}}>
                    </div>
                    {data.picture &&
                    <div
                        onClick={() => sAction.popup('image', {url: sAction.param.files+'image/'+data.picture})}
                        className="icon-preview hoverBlue"
                        style={{'margin': 'auto', 'marginLeft': '2px', 'width': '19px', 'fontSize': 13}}>
                    </div>}
                </div>
                {arrowsContainer}
                {prodlinesIcons}
                {renderComp}
            </div>
            <div className="quoteDescContainer">
                {descComp}
            </div>
        </div>);
    }
}

export default QProduct;
