import React from "react";
import PureComponent from "../pure";

import sAction from "sAction";
import EnumField from "./filterFields/enumField";
import BoolField from "./filterFields/boolField";
import TextField from "./filterFields/textField";
import DateField from "./filterFields/DateField";
import ParentField from "./filterFields/ParentField";
import MultirelateField from "./filterFields/MultirelateField";

class NewListViewHeaderField extends PureComponent {
  constructor() {
    super();
    this.state = {
      filterOpen: false,
      doOpen: true
    };
    this.iconContainer = React.createRef();
    this.headerInput = React.createRef();
    this.interval = null;
    this.index = 0;
  }

  headerInputSearch(value,customParam = {}) {
    const data = this.props.data;
    const prefix = this.props.prefix;
    const field = data.def.get("name");
    const fieldModule = data.def.get("fieldModule");
    const fieldRel = data.fieldRel;


    var operand = "cont";
    const type = data.def.get("type")

    if (type == "enum") {
      operand = "eq";
    }
    if(type == "multienum"){
      value = "^"+value+"^";
    }

    customParam["fieldType"] = type;
    if(data.def.get("inlineSearchMultiValue")){
      customParam["multiValue"] = true
    }

    if(value === ""){
      const filter = {
        field: field,
        type: null,
        value: null
      };
      let filters = new Array(filter);
      const data = {
        field: field,
        filters: filters,
        operator: "and",
        prefix: prefix
      };
      sAction.removeFilter(data)

      return
    }

    const paramData = {
      filters: [{ field: field, fieldModule: fieldModule, fieldRel: fieldRel, value: value, type: operand, ...customParam }],
      prefix: prefix,
      operator: "and",
      field: field,
      fieldModule: fieldModule,
      fieldRel: fieldRel,
      reload: true
    };
    sAction.addFilter(paramData);
  }

  openFilter() {
    const data = this.props.data;
    const prefix = this.props.prefix;
    const icon = this.iconContainer.current;
    const offset = icon.offsetLeft;

    let key = data.key
    if(data.key){
      key = key.toLowerCase()
    }
    const scrollTable = document.querySelector(".newListViewContentInner");
    const scrollLeft = scrollTable.scrollLeft;
    sAction.openFilter(data.def, offset - scrollLeft, prefix, key, data.fieldRel);
  }

  render() {
    this.index++
    const value = this.props.data;
    const orderBy = this.props.orderBy;
    const actFiltering = this.props.actFiltering;
    const openFilter = this.props.openFilter;
    const filtering = this.props.filtering;
    const prefix = this.props.prefix;

    const name = this.props.data.def.get("name");
    let defaultValue = null;
    if (this.props.filterValues && this.props.filterValues[name]) {
      defaultValue = this.props.filterValues[name];
    }
    let attr = {
      defaultValue: defaultValue
    }
    if(this.props.removeFieldText){
      attr.key = this.index;
    }

    var asc = this.props.asc;
    const sortable = this.props.sortable;
    const type = this.props.data.def.get("type");
    //__________________________________________ LABEL
    let label = null
    const fieldModule = value.def.get("fieldModule");
    //pokud je nastaveny alternativni nazev, zobrazit misto klasickeho nazvu
    if(value.alterName){
      label = value.alterName
    } else if (sAction.dataGet("reportWindow/alterName").size > 0){
      label = sAction.findAlterName(value)
    } else {
      label = sAction.translate(value.label, fieldModule);
    }
    if (asc == 1) {
      asc = 0;
    } else {
      asc = 1;
    }
    if (orderBy == false) {
      asc = 1;
    }
    //__________________________________________ LABEL END

    //___________________________________________ ICONA (serazeni/filtrace)
    var icon = null;
    // console.log(prefix)
    if (actFiltering == false && sortable == true && prefix != "reportWindow/results") {
      icon = (
        <div
          onClick={() => sAction.orderBy(value.key.toLowerCase(), asc, prefix)}
          className={"orderArrowsContainer listViewHeadericonContainer "}
        >
          <div
            className={
              orderBy == true && asc == 0
                ? "orderTriangleTop select"
                : "orderTriangleTop"
            }
          />
          <div
            className={
              orderBy == true && asc == 1
                ? "orderTriangleBottom select"
                : "orderTriangleBottom"
            }
          />
        </div>
      );
    } else if (actFiltering == true) {
      var usedMark = null;
      if (filtering.state == true) {
        usedMark = <div className="filteringUsedMark" />;
      }

      icon = (
        <div ref={this.iconContainer} className="listViewHeadericonContainer">
          <div
            onClick={() => this.openFilter()}
            className={"icon-filter listViewheaderFiltration"}
          >
            {usedMark}
          </div>
        </div>
      );
    }

    var field = null;
    var containerClass = "newListViewHeaderCell setWidth";
    if (actFiltering == true) {
      containerClass += " activeFiltering";
      switch (type) {
        case "ic":
        case "phone":
        case "currency":
          field = (
            <div className={"listviewHeaderInput " + type}>
              <TextField
                label={label}
                {...attr}
                type={"number"}
                onChange={e => this.headerInputSearch(e)}
              />
            </div>
          );
          break;
        case "enum":
          field = (
            <div className={"listviewHeaderInput " + type}>
              <EnumField
                label={label}
                {...attr}
                options={this.props.data.def.get("options")}
                colors={this.props.data.def.get("colors")}
                onChange={e => this.headerInputSearch(e)}
              />
            </div>
          );
          break;
        case "multienum":
          field = (
            <div className={"listviewHeaderInput " + type}>
              <EnumField
                label={label}
                options={this.props.data.def.get("options")}
                onChange={e => this.headerInputSearch(e)}
              />
            </div>
          );
          break;
        case "AcmDynamicEnum":
          field = (
              <div className={"listviewHeaderInput " + type}>
                <EnumField
                    label={label}
                    options={this.props.data.def.get("options")}
                    dynEnum={true}
                    onChange={e => this.headerInputSearch(e)}
                />
              </div>
          );
          break;
        case "multirelate":
          field = (
              <div className={"listviewHeaderInput " + type}>
                <MultirelateField
                    label={label}
                    type={"multirelate"}
                    name={name}
                    {...attr}
                    onChange={e => this.headerInputSearch(e)}
                />
              </div>
          );
          break;
        case "date":
        case "datetime":
        case "datetimecombo":
          field = (
            <div className={"listviewHeaderInput " + type}>
              <DateField
                  {...attr}
                label={label}
                onChange={e => this.headerInputSearch(e)}
              />
            </div>
          );
          break;
        case "relate":
          field = (
            <div className={"listviewHeaderInput " + type}>
              <TextField
                label={label}
                type={"text"}
                onChange={e => this.headerInputSearch(e)}
              />
            </div>
          );
          break;
        case "parent":
          field = (
            <div className={"listviewHeaderInput " + type}>
              <ParentField
                label={label}
                module={fieldModule}
                {...attr}
                type={"text"}
                onChange={(e,customParam) => this.headerInputSearch(e,customParam)}
              />
            </div>
          );
          break;
        case "varchar":
        case "name":
        case "account_name":
        case "url":
        case "file":
        case "id":
        case "text":
          field = (
            <div className={"listviewHeaderInput " + type}>
              <TextField
                label={label}
                type={"text"}
                {...attr}
                onChange={e => this.headerInputSearch(e)}
              />
            </div>
          );
          break;

        case 'bool':
          field = (
            <div className={"listviewHeaderInput " + type}>
              <BoolField
                label={label}
                {...attr}
                onChange={e => this.headerInputSearch(e)}
              />
            </div>
          );
          break;
        case "int":
          field = (
            <div className={"listviewHeaderInput " + type}>
              <TextField
                label={label}
                type={"int"}
                onChange={e => this.headerInputSearch(e)}
              />
            </div>
          );
          break;
        case "decimal":
          field = (
            <div className={"listviewHeaderInput " + type}>
              <TextField
                label={label}
                type={"decimal"}
                onChange={e => this.headerInputSearch(e.replace(",", "."))}
              />
            </div>
          );
          break;
        default:
          console.log('Nenalezen filtr pro typ: ' + type);
          break;
      }
    } else {
      field = (
        <div
          className="newListViewHeaderCellLabel"
          onClick={() =>
            (sortable && prefix != "reportWindow/results") && sAction.orderBy(value.key.toLowerCase(), asc, prefix)
          }
        >
          {label}
        </div>
      );
    }
    //___________________________________________ ICONA (serazeni/filtrace) END
    return (
      <div
        data-width={value.width}
        className={containerClass}
        data-last={this.props.last}
      >
        {field}
        <div className="newListViewHeaderCellIconSet">
          {icon}
          <div
            className="icon-listviewResize changeWidthHolder"
            onMouseDown={e => sAction.rowResize(e,prefix)}
          />
        </div>
      </div>
    );
  }
}
export default NewListViewHeaderField;
