import detailDefault from "../../detailDefault";
import moment from "moment";

export default class detailacm_Gifts extends detailDefault {
  load(sAction, data) {

    if(!sAction.dataGet(data.prefix + "/fields/count/value")) {
      sAction.dsClear()
      sAction.dsAdd("set", data.prefix + "/fields/count/value", 1)
      sAction.dsAdd("set", data.prefix + "/changes/fields/count", 1)
      sAction.dsProcess()
    }

    if(!sAction.dataGet(data.prefix + "/fields/datum_predani/value")) {
      const currentDate = moment(new Date()).format("YYYY-MM-DD")
      sAction.dsClear()
      sAction.dsAdd("set", data.prefix + "/fields/datum_predani/value", currentDate)
      sAction.dsAdd("set", data.prefix + "/changes/fields/datum_predani", currentDate)
      sAction.dsProcess()
    }

  }

  update(sAction, data) {
    switch (data.field) {
      case "acm_gifts_contactscontacts_ida":
        const params = {
          module : "Contacts",
          id : data.value.id,
          action: "getAccountInfo"
        }
        sAction.rest.post("customAction", params, function(resp) {
          if (resp.errCode == "OK") {
            const fieldData = {
              "way": data.prefix+"/fields/accounts_acm_gifts_1_name",
              "name": "accounts_acm_gifts_1accounts_ida",
              "fieldName": "accounts_acm_gifts_1_name",
              "type": "relate",
              "value": {
                "id": resp.id,
                "name": resp.name
              }
            }
            sAction.saveField(fieldData, true);
          }
        });
        break;
      case "acm_gifts_acm_giftsstockacm_giftsstock_ida":
        const giftName = sAction.dataGet( data.prefix + "/fields/acm_gifts_acm_giftsstock_name/value");
        sAction.dsClear()
        sAction.dsAdd("set", data.prefix + "/name", giftName)
        sAction.dsAdd("set", data.prefix + "/fields/name/value", giftName)
        sAction.dsAdd("set", data.prefix + "/changes/fields/name", giftName)
        sAction.dsProcess()
        break;

    }
  }
}