export default function saveRecordLeave(paramData, module) {
    const self = this;

    this.saveRecordBase(paramData, data => {
        if (data.state === true) {
            self.dsClear();
            self.dataSet(paramData.prefix + "/changes", {fields: [], files: []});
            if (paramData.type === "rightPanel") {
                this.dataSet("rightPanel/reloadOnClose", true)
                this.reloadListOnClose();
                this.closeRightPanel();
            } else {
                self.unLoad();
                window.location.href = this.checkPreviousUrl(paramData?.relType, data.data.module)
            }
        } else {
            self.error(data.msg);
        }
    });
}
