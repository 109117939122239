import PropTypes from 'prop-types';
import React from 'react';
import PureComponent from '../../pure';
import sAction from 'sAction';

import InputText from '../../formElements/InputText';
import Select from '../../formElements/Select';
import {EmailPopupActionPanel} from './shared/EmailPopupActionPanel';
import Loader from '../../loader';
import ErrorMessage from '../../default/ErrorMessage';
import TabContainer from '../../formElements/TabContainer';
import EmailOAuthAddAccount from './EmailOAuthAddAccount';

export default class EmailSettingsOePopup extends PureComponent {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            data: {
                has_password: '',
                id: '',
                mail_sendtype: '',
                mail_smtpauth_req: '',
                mail_smtppass: '',
                mail_smtpport: '465',
                mail_smtpserver: '',
                mail_smtpssl: '1',
                mail_smtptype: '',
                mail_smtpuser: '',
                name: '',
                type: '',
                user_id: '',
            },
            expand_pass: false,
            expand_test: false,
            loaded: false,
            errors: [],
            error_msg: [],
            is_new: false,
            activeView: 0,
        };
    }

    handleChange = ({target: {name, value}}) => {
        this.setState((prevState) => ({
            data: {...prevState.data, [name]: value},
        }));
        if (name === 'mail_smtpssl') {
            const portValues = {'': '25', '1': '465', '2': '587'};
            this.setState((prevState) => ({
                data: {...prevState.data, mail_smtpport: portValues[value]},
            }));
        }
    };

    handleOAuth = () => {
        sAction.popupHide();
        sAction.popup('emailOAuthAddAccount', {
            title: 'Add oAuth account',
        });
    };

    componentDidMount() {
        this._isMounted = true;

        const {id: outbound_email} = this.props;
        if (!outbound_email) {
            this.setState({loaded: true, expand_pass: true, is_new: true});
            return;
        }

        sAction.rest.post('email/editOutbound', {outbound_email}, (data) => {
            if (!this._isMounted) return;
            this.setState({loaded: true, data});
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    isFormValid = () => {
        const {
            // has_password,
            // id,
            // mail_sendtype,
            // mail_smtpauth_req,
            mail_smtppass,
            mail_smtpport,
            mail_smtpserver,
            // mail_smtpssl,
            // mail_smtptype,
            mail_smtpuser,
            name,
            // type,
            // user_id
        } = this.state.data;

        const errors = [];
        const error_msg = [];
        if (!mail_smtpport) errors.push('mail_smtpport');
        if (!mail_smtpserver) errors.push('mail_smtpserver');
        if (!name) errors.push('name');

        this.setState({errors, error_msg});

        if (errors.length) {
            // Nejsou vyplněny všechny povinné položky
            this.setState({error_msg: [sAction.translate('ERR_FILL_REQUIRED_ITEMS', 'Emails')]});
            return false;
        }

        return true;
    };

    // ulozeni zmen v popupu
    savePopup = () => {
        if (!this.isFormValid()) return false;

        // ne vsechny nazvy promennych jsou stejne
        const sendData = {
            mail_id: this.state.data.id,
            type: this.state.data.type,
            mail_sendtype: this.state.data.mail_sendtype,

            mail_name: this.state.data.name,
            mail_smtpserver: this.state.data.mail_smtpserver,
            mail_smtpport: this.state.data.mail_smtpport,
            mail_smtpauth_req: this.state.data.mail_smtpauth_req,
            mail_smtpssl: this.state.data.mail_smtpssl,
            mail_smtpuser: this.state.data.mail_smtpuser,
            mail_smtppass: this.state.data.mail_smtppass,
            mail_smtptype: this.state.data.mail_smtptype,
        };
        sAction.load();
        sAction.rest.post('email/saveOutbound', sendData, (data) => {
            sAction.unLoad();
            if (!data || !data.status) {
                this.setState({data: {...this.state.data, id: data.id}, errors: [1], error_msg: data.errorMessage});
                return false;
            }
            sAction.popupHide();
            if (this.props.data && this.props.data.get('callback')) {
                if (!sendData.mail_id) sendData.mail_id = data.id;
                this.props.data.get('callback')(sendData);
            } else if (this.props.onChange) {
                this.props.onChange();
            }
        });
    };

    render() {
        const {
            expand_pass,
            loaded,
            errors,
            error_msg,
            data: {
                has_password,
                id,
                mail_sendtype,
                mail_smtpauth_req,
                mail_smtppass,
                mail_smtpport,
                mail_smtpserver,
                mail_smtpssl,
                mail_smtptype,
                mail_smtpuser,
                name,
                type,
                user_id,
            },
        } = this.state;

        const tabs = [
            sAction.translate('LBL_EMAIL_SETTINGS_EMAIL_TAB', 'Emails'),
        ];

        if (this.props?.oAuth.allowOAuthProvider) {
            tabs.push(sAction.translate('LBL_EMAIL_SETTINGS_COMPANY_EMAIL_TAB', 'Emails'));
        }

        if (!loaded) return <Loader />;

        return (
            <div className="email__popup">
                <div className="acmPopupHeader">
                    {/* Nastavení odchozího mail serveru*/}
                    {sAction.translate('LBL_EMAIL_ACCOUNTS_OUTBOUND')}
                </div>

                <EmailPopupActionPanel onSave={this.savePopup} />

                <TabContainer
                    tabs={tabs}
                    value={this.state.activeView}
                    onChange={(e, value) => {
                        this.setState({activeView: value});
                    }}
                />

                {this.state.activeView === 0 && (
                    <div className="email__wrapper">
                        {!!error_msg.length && <ErrorMessage>{error_msg}</ErrorMessage>}

                        <InputText
                            required
                            margin="dense"
                            // inputRef={this.to_addrsRef}
                            id="name"
                            // label="Jméno"
                            label={sAction.translate('LBL_EMAIL_ACCOUNTS_NAME')}
                            type="text"
                            name="name"
                            error={errors.includes('name')}
                            value={name}
                            onChange={this.handleChange}
                        />
                        <InputText
                            required
                            margin="dense"
                            // inputRef={this.to_addrsRef}
                            id="mail_smtpserver"
                            // label="SMTP server"
                            label={sAction.translate('LBL_EMAIL_ACCOUNTS_SMTPSERVER')}
                            type="text"
                            name="mail_smtpserver"
                            error={errors.includes('mail_smtpserver')}
                            value={mail_smtpserver}
                            onChange={this.handleChange}
                        />
                        <InputText
                            margin="dense"
                            // inputRef={this.to_addrsRef}
                            id="mail_smtpuser"
                            // label="SMTP uživatelské jméno"
                            label={sAction.translate('LBL_EMAIL_ACCOUNTS_SMTPUSER')}
                            type="text"
                            name="mail_smtpuser"
                            error={errors.includes('mail_smtpuser')}
                            value={mail_smtpuser}
                            onChange={this.handleChange}
                        />
                        {expand_pass ? (
                            <InputText
                                margin="dense"
                                // inputRef={this.to_addrsRef}
                                id="mail_smtppass"
                                // label="SMTP heslo"
                                label={sAction.translate('LBL_EMAIL_ACCOUNTS_SMTPPASS')}
                                type="password"
                                name="mail_smtppass"
                                value={mail_smtppass}
                                onChange={this.handleChange}
                            />
                        ) : (
                        // Změnit heslo
                            <a onClick={() => this.setState({expand_pass: true})}>
                                {sAction.translate('LBL_CHANGE_PASSWORD')}
                            </a>
                        )}
                        <Select
                            required
                            options={[
                                {label: sAction.translate('LBL_NONE', 'Emails'), value: ''},
                                {label: 'SSL', value: '1'},
                                {label: 'TLS', value: '2'},
                            ]}
                            // label="Povolit SMTP přes SSL nebo TLS?"
                            label={sAction.translate('LBL_EMAIL_SMTP_SSL_OR_TLS')}
                            name="mail_smtpssl"
                            error={errors.includes('mail_smtpssl')}
                            value={mail_smtpssl}
                            onChange={this.handleChange}
                        />
                        <InputText
                            required
                            margin="dense"
                            // inputRef={this.to_addrsRef}
                            id="mail_smtpport"
                            // label="SMTP port"
                            label={sAction.translate('LBL_EMAIL_ACCOUNTS_SMTPPORT')}
                            type="text"
                            name="mail_smtpport"
                            error={errors.includes('mail_smtpport')}
                            value={mail_smtpport}
                            onChange={this.handleChange}
                        />
                    </div>
                )}

                {this.state.activeView === 1 && (
                    <div className="email__wrapper">
                        {!!error_msg.length && <ErrorMessage>{error_msg}</ErrorMessage>}

                        <EmailOAuthAddAccount outboundOnly={true} />

                    </div>
                )}
            </div>
        );
    }
}

EmailSettingsOePopup.propTypes = {
    data: PropTypes.object,
    id: PropTypes.any,
    oAuth: PropTypes.shape({
        allowOAuthProvider: PropTypes.bool,
        providerName: PropTypes.string,
    }),
    onChange: PropTypes.func,
};
