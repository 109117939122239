import sAction from "sAction";

export default function ares(sendData, prefix = "view") {
  this.load();

  sendData.ares_zastupci = !!this.dataGet(prefix + "/fields/ares_zastupci");
  const url = sendData.ico ? "ares" : "ares2";

  this.rest.post("detailapi/" + url, sendData, data => {
    this.unLoad();
    var fields = ["billing", "primary", "Contactsprimary"];


    fields.forEach(field => {
      data.forEachObject((v, k) => {
        if (k.charAt(0) === "_") {
          data[field + k] = v;
          // Oprava aresu aby nemazal adresy a prepsalo se vse
          if (k === fields.length - 1) {
            delete data[k];
          }
        }
      });
    });

    if (typeof data !== "object") {
      this.error(this.translate("LBL_ARES_NO_DATA", "Accounts"));
      return;
    }

    if (!data.num) {
      this.error(
        this.translate("LBL_ARES_NOT_FOUND", "Accounts")
      );
      return;
    }

    if (data.num && data.num > 1) {
      delete data.num;
      const params = {
        header: this.translate("LBL_VYBER","acm_reports"),
        "accounts": data,
        prefix
      }
      this.popup("aresChooseAccountPopup",  params );
      return;
    }

    if(data){
      let sendDataGps = {};
      sendDataGps.street = data.primary_address_street;
      // sendDataGps.street = 'lidická';
      // sendDataGps.city = '';
      sendDataGps.city = data.primary_address_city;

      this.rest.post("acmAddress", sendDataGps, dataGps => {
        if (dataGps.length === 1) {
          this.dsClear();
          this.dsAdd("set", prefix + "/changes/fields/acm_address", dataGps[0].ulice + " " + dataGps[0].cislo_dom + "/" + dataGps[0].cislo_orient);
          this.dsAdd("set", prefix + "/changes/fields/acm_address_cast_obce", dataGps[0].cast_obce);
          this.dsAdd("set", prefix + "/changes/fields/acm_address_gpsx", dataGps[0].x);
          this.dsAdd("set", prefix + "/changes/fields/acm_address_gpsy", dataGps[0].y);
          this.dsAdd("set", prefix + "/changes/fields/acm_address_kraj", dataGps[0].kraj);
          this.dsAdd("set", prefix + "/changes/fields/acm_address_obec", dataGps[0].obec);
          this.dsAdd("set", prefix + "/changes/fields/acm_address_okres", dataGps[0].okres);
          this.dsAdd("set", prefix + "/changes/fields/acm_address_psc", dataGps[0].psc);
          this.dsAdd("set", prefix + "/changes/fields/acm_address_street", dataGps[0].ulice);

          this.dsAdd("set", prefix + "/fields/acm_address/def/item/gpsX", dataGps[0].x);
          this.dsAdd("set", prefix + "/fields/acm_address/def/item/gpsY", dataGps[0].y);
          this.dsAdd("set", prefix + "/fields/acm_address/def/item/obec", dataGps[0].obec);
          this.dsAdd("set", prefix + "/fields/acm_address/def/item/street", dataGps[0].ulice + " " + dataGps[0].cislo_dom + "/" + dataGps[0].cislo_orient);
          this.dsAdd("set", prefix + "/fields/acm_address/value", dataGps[0].ulice + " " + dataGps[0].cislo_dom + "/" + dataGps[0].cislo_orient);
          this.dsProcess();
        }
        else if (dataGps.length === 0){
          this.alert(this.translate("LBL_NO_ADDRESSES_FOUND"))
        }
        else{
          this.alert(this.translate("LBL_MULTIPLE_ADDRESSES_FOUND"))
          // TODO: popup vyberu z moznych adres - zatim zbytecne ... tak si jednou kliknou navic
        }
      })
    }

    this.updateFields(data, prefix);

    if (data.ticker_symbol) {
      this.checkICO(data.ticker_symbol);
    }
  });
}
