import React, { useEffect, useState } from "react";
import logError from "../../views/desktop/components/LogError";

export default function Loader(props) {
  const timeUntilReport = 16000; // ms

  const [state, setState] = useState({
    hasError: false,
    error: "Loading took too long",
    info: { componentStack: "Loading took too long." },
    logId: Math.random().toString(16).slice(2).substring(0, 8),
    visibleJson: false,
    isPWA: false,
  });

  const clickStack = [
    {
      targetHtml: "-- recording disabled --",
    },
  ];

  useEffect(() => {
    const loadingTimer = setTimeout(() => {
      console.log("[info] Loading took too long, reporting to server...");
      logError(state.error, state.info, state, clickStack, 'slow-loading');
    }, timeUntilReport);

    return () => {
      clearTimeout(loadingTimer);
    };
  }, []);

  const loader = (
    <div className="pageLoadLoader">
      <div className="lds-ellipsis">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );

  return props.fullscreen ? <div className="pageLoad">{loader}</div> : loader;
}
