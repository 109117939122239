export default function sendEmailToParticipants(params) {

  const handleSelection = (returnData) => {

    const filter = returnData.id !== "all" ? null : this.getListviewFilter("conf/popup/data/listView");

    this.load();
    const url = "getCampaignParticipantsEmails";
    const sendData = {
      ids: returnData.id,
      bean_id: params.id,
      filter
    }

    this.rest.post(url, sendData, (data) => {
      this.unLoad();

      let to_addrs;
      if (data.emails && data.emails.length) {
        let emails = [];
        data.emails.forEach(v => {
          emails.push(v.email);
        });
        to_addrs = emails.join(", ")
      }
      if (!to_addrs) {
        /* Nepodařilo se získat žádný e-mail příjemce, pravděpodobně není vyplněn. */
        this.error(this.translate("LBL_NO_PARTICIPANT_EMAIL", "acm_CampaignsAction"));
        return;
      }

      const openPopup = () => {
        const emailData = {
          parent_type: params.module,
          parent_id: params.id,
          parent_name: params.name,
      
          to_addrs,
          send_individually: true,
          disable_recipient_change: true,
        };
    
        this.openEmailPopup(emailData);
      }
  
      if (data.errors && data.errors.length) {
        let links = [];
        data.errors.forEach(v => {
          links.push("<a target=\"_blank\" href=\"#detail/" + v.module + "/" + v.id + "\">" + (v.name || "____") + "</a>");
        });
        links = links.join("<br>")
        
        this.confrim(
            // U následujícího kontaktu nebo kontaktů není vyplněn primární e-mail. Vyplňte jej a poté klikněte na tlačítko "Pokračovat", jinak bude ignorován.
            this.translate("ERR_SEND_MASS_EMAIL_MISSING_EMAILS", "Emails") + "<br><br>" + links,
            () => {
              this.popupHide();
              openPopup()
            }
        );
      } else {
        openPopup();
      }
      
    });

  };

  const relateData = {
    module: "acm_CampaignsPartipants",
    defaultFilter: {
      participants: {
        operandType: "relate",
        parentId: params.id,
        module: "acm_CampaignsAction",
        relationship: ["acm_campaignsaction_acm_campaignspartipants"],
        name: "acm_campaignsaction_acm_campaignspartipants_name",
        type: "eq"
      }
    }
  };
  this.openRelatePopup(relateData, (returnData) => {
    handleSelection(returnData);

    this.popupHide();
  });


}
