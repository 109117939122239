import detailDefault from '../../detailDefault';

export default class detailacm_machines extends detailDefault {
    load(sAction, data) {
        if (sAction.getNewPopupId()) {
            this.newMachineFillAccount(sAction);
        }
        // throw new Error("Error from custom action");
    }

    update(sAction, data) {
        if (data.field === 'machinenum' && data.value) {
            sAction.load();
            sAction.rest.post('checkMachineDuplicity', {machinenum: data.value}, (returnData) => {
                sAction.unLoad();
                if (returnData.status) {
                    if (returnData.message.data.duplicity) {
                        sAction.error(sAction.translate('LBL_CANT_SAVE_DUPLICATE_MACHINENUM'));
                        sAction.dataSet(`${data.prefix}/machineDuplicity`, true);
                        return;
                    }
                    sAction.dataSet(`${data.prefix}/machineDuplicity`, false);
                }
            });
        }

        // debug
        if (data.field == 'description' && data.value == 'throwError') {
            throw new Error('Error from custom action');
        }
    }

    newMachineFillAccount(sAction) {
        const popupContent = sAction.dataGet('conf/popup/content');
        if (popupContent === 'popupDetail') {
            const popupModule = sAction.dataGet('conf/popup/data/detailView/module');

            if (popupModule === 'acm_machines') {
                const customerAccountName = sAction.dataGet('view/fields/acm_service_order_accounts_1_name/value');
                const customerAccountId = sAction.dataGet('view/fields/acm_service_order_accounts_1accounts_ida/value');
                const customerBranchName = sAction.dataGet('view/fields/acm_service_order_accounts_2_name/value');
                const customerBranchId = sAction.dataGet('view/fields/acm_service_order_accounts_2accounts_ida/value');

                if (customerBranchName && customerBranchId) {
                    sAction.dsClear();
                    sAction.dsAdd('set', 'conf/popup/data/detailView/fields/acm_machines_accountsaccounts_ida/value', customerBranchId);
                    sAction.dsAdd('set', 'conf/popup/data/detailView/fields/acm_machines_accounts_name/value', customerBranchName);
                    sAction.dsAdd('set', 'conf/popup/data/detailView/changes/acm_machines_accountsaccounts_ida', customerBranchId);
                    sAction.dsAdd('set', 'conf/popup/data/detailView/changes/fields/acm_machines_accountsaccounts_ida', customerBranchId);
                    sAction.dsAdd('set', 'conf/popup/data/detailView/changes/fields/relate/acm_machines_accounts_name', customerBranchName);
                    sAction.dsProcess();
                } else if (customerAccountName && customerAccountId) {
                    sAction.dsClear();
                    sAction.dsAdd('set', 'conf/popup/data/detailView/fields/acm_machines_accountsaccounts_ida/value', customerAccountId);
                    sAction.dsAdd('set', 'conf/popup/data/detailView/fields/acm_machines_accounts_name/value', customerAccountName);
                    sAction.dsAdd('set', 'conf/popup/data/detailView/changes/acm_machines_accountsaccounts_ida', customerAccountId);
                    sAction.dsAdd('set', 'conf/popup/data/detailView/changes/fields/acm_machines_accountsaccounts_ida', customerAccountId);
                    sAction.dsAdd('set', 'conf/popup/data/detailView/changes/fields/relate/acm_machines_accounts_name', customerAccountName);
                    sAction.dsProcess();
                }
            }
        }
    }

    beforeSave(sAction, data) {
        // stroj ma jit ulozit ale zobrazit varovny popup
        // if(sAction.dataGet(`${data.prefix}/machineDuplicity`)){
        //     sAction.error(sAction.translate("LBL_CANT_SAVE_DUPLICATE_MACHINENUM"))
        //     return false;
        // }

        return true;
    }
}
