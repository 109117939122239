import detailDefault from '../../detailDefault';
import React from 'react';

export default class detailAccounts extends detailDefault {
    load(sAction, data) {
        this.updateDetailApiVisibility(sAction, data);
        if (data.prefix === 'conf/popup/data/detailView') { // pokud je tvoreno ze subpanelu
            const ParentId = sAction.dataGet('view/id');
            const ParentName = sAction.dataGet('view/name');
            const page = sAction.dataGet('conf/page');
            if (page !== 'acm_databox_accounts') {
                sAction.dsClear();
                sAction.dsAdd('set', data.prefix + '/fields/parent_name/def/id_value', ParentId);
                sAction.dsAdd('set', data.prefix + '/fields/parent_name/value', ParentName);
                sAction.dsAdd('set', data.prefix + '/changes/fields/parent_id', ParentId);
                sAction.dsProcess();
            } else {
                sAction.dsClear();
                sAction.dsAdd('set', data.prefix + '/fields/parent_name/def/defaultFilter',
                    {
                        tickerSymbol: {
                            field: 'ticker_symbol',
                            footer: false,
                            type: 'neq',
                            value: '',
                        },
                    },
                );
                sAction.dsProcess();
                const ico = sAction.dataGet(data.prefix + '/fields/ticker_symbol/value');
                if (ico === null) {
                    sAction.dsClear();
                    sAction.dsAdd('set', data.prefix + '/fields/parent_name/def/required', true);
                    sAction.dsProcess();
                } else {
                    sAction.dsClear();
                    sAction.dsAdd('set', data.prefix + '/fields/parent_name/def/readonly', true);
                    sAction.dsProcess();
                }
            }
        }

        this.checkAddressFilled(sAction, data);
        this.checkParent(sAction, data);


        sAction.dsClear();
        sAction.dsAdd('set', data.prefix+'/fields/seeky_activity/def/type', 'HTMLText');
        sAction.dsAdd('set', data.prefix+'/fields/seeky_activity/def/readonly', true);

        const id = sAction.dataGet(data.prefix+'/id');
        if (!id) {
            sAction.dsAdd('set', data.prefix+'/fields/dealer_type/def/required', true);
        }

        sAction.dsProcess();

        if (sAction.deviceType == 'servis') {
            sAction.dataDelete(data.prefix+'/tabs', 1);
            sAction.dataDelete(data.prefix+'/tabs', 1);
        }

        if (!sAction.dataGet(data.prefix + '/fields/billing_address_city/value')) {
            this.generateAccountName(sAction, data.prefix);
        }
    }

    update(sAction, data) {
        switch (data.field) {
            case 'billing_address_country':
                this.updateDetailApiVisibility(sAction, data);
                break;
            case 'acm_address':
                this.getNearAccounts(sAction, data);
                break;
            case 'parent_id':
                this.checkParent(sAction, data);
                this.generateAccountName(sAction, data.prefix);
                this.getParentAccountType(sAction, data.prefix);
                break;
            case 'billing_address_city':
                this.generateAccountName(sAction, data.prefix);
                break;
        }

        this.addressesHelper(sAction, data, data);
    }

    getParentAccountType(sAction, prefix) {
        const parent_id = sAction.dataGet(prefix + '/fields/parent_name/def/id_value');
        const dealer_type = sAction.dataGet(prefix + '/fields/dealer_type/value');

        // pokud neni vyplneny dealer_type, tak se zjisti dealer_type z parent account
        if (parent_id && !dealer_type) {
            const searchData = {
                module: 'Accounts',
                value: parent_id,
                fields: ['id'],
                getFields: ['dealer_type'],
            };

            sAction.quickSearch(searchData, (returnData) => {
                if (returnData && returnData.length === 1) {
                    sAction.dsClear();
                    sAction.dsAdd('set', prefix + '/fields/dealer_type/value', returnData[0].dealer_type);
                    sAction.dsAdd('set', prefix + '/changes/fields/dealer_type', returnData[0].dealer_type);
                    sAction.dsProcess();
                }
            });
        }
    }

    checkParent(sAction, data) {
        const parent = sAction.dataGet(data.prefix + '/fields/parent_id/value');
        const parentChanges = sAction.dataGet(data.prefix + '/changes/fields/parent_id');

        sAction.dsClear();
        if (
            (parent || parentChanges ) &&
        parentChanges !== ''
        ) {
            sAction.dsAdd('set', data.prefix + '/fields/ticker_symbol/def/required', false);
        } else {
            sAction.dsAdd('set', data.prefix + '/fields/ticker_symbol/def/required', true);
        }
        sAction.dsProcess();

        const page = sAction.dataGet('conf/page');
        if (page === 'acm_databox_accounts') {
            const ico = sAction.dataGet(data.prefix + '/fields/ticker_symbol/value');
            if (ico === null) {
                sAction.dsClear();
                sAction.dsAdd('set', data.prefix + '/fields/ticker_symbol/def/required', false);
                sAction.dsProcess();
            }
        }
    }
    addressesHelper(sAction, data, field) {
    // const street = sAction.dataGet(data.prefix + "/fields/billing_address_street/value");
    // const city = sAction.dataGet(data.prefix + "/fields/billing_address_city/value");


        if (field.field === 'acm_address') {
            sAction.dsClear();

            const acm_address_street = sAction.dataGet(data.prefix + '/fields/acm_address/def/item/street');
            const acm_address_city = sAction.dataGet(data.prefix + '/fields/acm_address/def/item/obec');
            const acm_address_psc = sAction.dataGet(data.prefix + '/fields/acm_address/def/item/psc');
            const acm_address_kraj = sAction.dataGet(data.prefix + '/fields/acm_address/def/item/kraj');
            const acm_address_okres = sAction.dataGet(data.prefix + '/fields/acm_address/def/item/okres');

            if (acm_address_street) {
                sAction.dsAdd('set', data.prefix + '/fields/billing_address_street/value', acm_address_street);
                sAction.dsAdd('set', data.prefix + '/changes/fields/billing_address_street', acm_address_street);
            }
            if (acm_address_city) {
                sAction.dsAdd('set', data.prefix + '/fields/billing_address_city/value', acm_address_city);
                sAction.dsAdd('set', data.prefix + '/changes/fields/billing_address_city', acm_address_city);
            }
            if (acm_address_psc) {
                sAction.dsAdd('set', data.prefix + '/fields/billing_address_postalcode/value', acm_address_psc);
                sAction.dsAdd('set', data.prefix + '/changes/fields/billing_address_postalcode', acm_address_psc);
            }
            if (acm_address_kraj) {
                sAction.dsAdd('set', data.prefix + '/fields/billing_address_kraj/value', acm_address_kraj);
                sAction.dsAdd('set', data.prefix + '/changes/fields/billing_address_kraj', acm_address_kraj);
            }
            if (acm_address_okres) {
                sAction.dsAdd('set', data.prefix + '/fields/billing_address_state/value', acm_address_okres);
                sAction.dsAdd('set', data.prefix + '/changes/fields/billing_address_state', acm_address_okres);
            }

            sAction.dsProcess();
        }
    }

    checkAddressFilled(sAction, data) {
        const gpsx = sAction.dataGet(data.prefix + '/fields/acm_address/def/item/gpsX');
        const gpsy = sAction.dataGet(data.prefix + '/fields/acm_address/def/item/gpsY');

        if (gpsx && gpsy) {
            sAction.dsClear();
            sAction.dsAdd('set', data.prefix + '/fields/acm_address/def/required', false);
            sAction.dsProcess();
        }
    }

    // fce skryva/odkryva detail fieldy podle zeme, jestli je zeme CZECH REPUBLIC
    updateDetailApiVisibility(sAction, data) {
        let address;
        if (data.value) {
            address = data.value;
        } else {
            address = sAction.dataGet(data.prefix + '/fields/billing_address_country/value');
        }

        const updateFieldApiValue = (fieldName, fieldValue) => {
            if (sAction.dataGet(data.prefix + '/fields/'+fieldName) !== undefined) {
                sAction.dsAdd('set', data.prefix + '/fields/'+fieldName+'/def/showDetailApi', fieldValue);
            }
        };

        sAction.dsClear();
        if (!address || address === 'CZECH REPUBLIC') {
            // zobrazi ikony
            updateFieldApiValue('name', 'ApiName');
            updateFieldApiValue('account_name', 'ApiName');
            updateFieldApiValue('ticker_symbol', 'ApiICO');
            updateFieldApiValue('sic_code', 'ApiDIC');
            updateFieldApiValue('billing_address_street', 'ApiStreet');
            updateFieldApiValue('shipping_address_street', 'ApiStreet');
            updateFieldApiValue('billing_address_postalcode', 'ApiPSC');
            updateFieldApiValue('shipping_address_postalcode', 'ApiPSC');
        } else if (address === 'SLOVAKIA') {
            // skryje ikony
            updateFieldApiValue('name', 'ApiNameSK');
            updateFieldApiValue('account_name', 'ApiNameSK');
            updateFieldApiValue('ticker_symbol', 'ApiICOSlovakia');
            updateFieldApiValue('sic_code', '');
            updateFieldApiValue('billing_address_street', 'ApiStreet');
            updateFieldApiValue('shipping_address_street', 'ApiStreet');
            updateFieldApiValue('billing_address_postalcode', 'ApiPSC');
            updateFieldApiValue('shipping_address_postalcode', 'ApiPSC');
        } else {
            // skryje ikony
            updateFieldApiValue('name', 'ApiNameOthers');
            updateFieldApiValue('account_name', '');
            updateFieldApiValue('ticker_symbol', '');
            updateFieldApiValue('sic_code', '');
            updateFieldApiValue('billing_address_street', '');
            updateFieldApiValue('shipping_address_street', '');
            updateFieldApiValue('billing_address_postalcode', '');
            updateFieldApiValue('shipping_address_postalcode', '');
        }


        const tabs = sAction.dataGet(data.prefix + '/tabs');
        if (tabs) {
            const finstatIndex = tabs.findIndex((i) => i.name === 'LBL_DETAILVIEW_PANEL_FINSTAT');
            if (finstatIndex !== -1) {
                if (address !== 'SLOVAKIA') {
                    sAction.dsAdd('set', data.prefix + '/tabs/' + finstatIndex + '/hidden', true);
                } else {
                    sAction.dsAdd('set', data.prefix + '/tabs/' + finstatIndex + '/hidden', false);
                }
            }
        }
        sAction.dsProcess();
    }

    getNearAccounts(sAction, data) {
        const item = sAction.dataGet(data.prefix + '/fields/acm_address/def/item');
        const id = sAction.dataGet(data.prefix + '/id');
        const address = {
            x: item.get('gpsX'),
            y: item.get('gpsY'),
            cast_obce: item.get('cast_obce'),
            kraj: item.get('kraj'),
            obec: item.get('obec'),
            okres: item.get('okres'),
            psc: item.get('psc'),
            id: id,
        };

        sAction.rest.post('getNearAddresses', address, (returnData) => {
            if (returnData.status) {
                if (returnData.message.data.length > 0) {
                    let message = sAction.translate('LBL_NEAR_ACCOUNT_FOUND', 'Accounts');
                    const accounts = [];
                    returnData.message.data.forEachObject((value) => {
                        accounts.push(
                            (
                                <div style={{margin: 'auto'}}>
                                    <a href={'#detail/Accounts/' + value.id} target='_blank' rel="noreferrer">{value.name} | IČ: {value.ticker_symbol}</a>
                                </div>
                            ),
                        );
                    });
                    message = (
                        <div style={{margin: 'auto'}}>
                            <div>
                                {message}
                            </div>
                            <br />
                            {accounts}
                        </div>
                    );
                    const data = {
                        // Potvrzení
                        header: sAction.translate('LBL_CONFIRM'),
                        buttons: [
                            // Pokračovat
                            {label: sAction.translate('LBL_CONTINUE', 'Home'), callback: () => sAction.popupHide()},
                        ],
                    };

                    sAction.popup(message, data);
                }
            } else {
                sAction.error(sAction.translate(returnData.errorMessage.text));
            }
        });
    }

    generateAccountName(sAction, prefix) {
        if (sAction.dataGet(prefix + '/fields/rel_type/value') === 'parent') {
            return;
        }
        const parentAccount = sAction.dataGet(prefix + '/fields/parent_name/value');
        const city = sAction.dataGet(prefix + '/fields/billing_address_city/value');
        let newAccountName = '';


        if (parentAccount && !city) {
            newAccountName = `${parentAccount} - `;
        } else if (!parentAccount && city) {
            newAccountName = ` - ${city}`;
        } else if (city && parentAccount) {
            newAccountName = `${parentAccount} - ${city}`;
        }

        if (newAccountName) {
            sAction.dataSet(prefix + '/fields/name/value', newAccountName);
            sAction.dataSet(prefix + '/changes/fields/name', newAccountName);
        }
    }

    beforeSave(sAction, data) {
        const products = sAction.dataGet(data.prefix + '/customData/products/productsUses/leftEnum');
        // conf/popup/data/detailView
        if (!data.prefix.includes('conf/popup/data')) {
            if (!products || (products && products.toJS().length === 0)) {
                sAction.error(sAction.translate('LBL_PRODUCTS_MUST_BE_SELECTED'));
                return false;
            }
        }
        return true;
    }
}
