import React, {PureComponent} from "react";
import sAction from "sAction";
import {Button} from "@material-ui/core";

/**
 * @parent src/components/detailView/Accounts/DetailProductsTab
 * @props active, data, id, module, way
 */
export default class ProductsTable extends PureComponent {
    constructor(props) {
        super(props);

        this.productsTakesRows = []
        this.productsUsesRows = []

        this.isRightPanel = sAction.dataGet("rightPanel/show")

    }

    createProductsTable(side, productData) {
        let productsRows = []
        const products = productData.get("leftEnum")
        const productsInfo = productData.get("rightEnum")

        products.map((product, key) => {
            let productInfoArr = productsInfo.get(key)
            if (productInfoArr && productInfoArr.size > 0) {
                productsInfo.map((productInfo, productInfoKey) => {
                    if (key === productInfoKey) {
                        productInfo.forEach(element => {
                            productsRows.push(<tr className="productsRow" key={element}>
                                <td className="product">{product}</td>
                                <td className="productInfo">{element}</td>
                            </tr>)
                        })
                    }
                })
            } else {
                productsRows.push(<tr className="productsRow" key={key}>
                    <td className="product">{product}</td>
                    <td className="productInfo"/>
                </tr>)
            }
        })

        return productsRows
    }

    render() {
        const productsUses = this.props.data.get("products").get("productsUses")
        const productsTakes = this.props.data.get("products").get("productsTakes")

        let productsTakesRows = null
        let productsUsesRows = null

        if (productsTakes) {
            productsTakesRows = this.createProductsTable("takes", productsTakes)
        }

        if (productsUses) {
            productsUsesRows = this.createProductsTable("uses", productsUses)
        }

        let buttonProductsTakes = null
        let buttonProductsUses = null
        if(!this.isRightPanel){
            buttonProductsTakes = (<Button className="acmButton hoverNewDesignBlue stillHover"
                              onClick={() => sAction.openRightPanelMultiEnum("account_products_takes")}>{sAction.translate("LBL_EDIT_BUTTON")}</Button>);
            buttonProductsUses = (<Button className="acmButton hoverNewDesignBlue stillHover"
                                                 onClick={() => sAction.openRightPanelMultiEnum("account_products_uses")}>{sAction.translate("LBL_EDIT_BUTTON")}</Button>);
        }

        return (
            <>{
                <div className="productTabContainer">
                    <div className="productsContainer">
                        <div className="productsHeaderContainer">
                            <div className="headLine">{sAction.translate("LBL_PRODUCT_TAKES")}</div>
                            {buttonProductsTakes}

                        </div>
                        <div className="productsTableContainer">
                            <table className="productsTable">
                                <thead>
                                <tr className="productsRow">
                                    <th className="product">{sAction.translate("LBL_PRODUCTS")}</th>
                                    <th className="productInfo">{sAction.translate("LBL_INFO")}</th>
                                </tr>
                                </thead>
                                <tbody className={"productsTableBody"}>
                                {productsTakesRows}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="productsContainer">
                        <div className="productsHeaderContainer">
                            <div className="headLine">{sAction.translate("LBL_PRODUCT_USES")}</div>
                            {buttonProductsUses}
                        </div>
                        <div className="productsTableContainer">
                            <table className="productsTable">
                                <thead>
                                <tr className="productsRow">
                                    <th className="product"> {sAction.translate("LBL_PRODUCTS")}</th>
                                    <th className="productInfo">{sAction.translate("LBL_INFO")}</th>
                                </tr>
                                </thead>
                                <tbody className={"productsTableBody"}>
                                {productsUsesRows}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }
            </>
        )
    }
}
