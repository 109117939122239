export default function updateDynamicEnum(prefix, field, enumList,disabledOptions){
    let list = this.app_strings[enumList];
    var options = [];
    list.forEachObject((option, key) => {
        let disabled = false;

        if (disabledOptions.includes(key)) {
            disabled = true;
        }
        options.push({
            value: key,
            label: option,
            disabled: disabled
        });
    });

    this.dataSet(prefix + "/fields/" + field + "/def/customOptions", options);
}
