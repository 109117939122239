import detailDefault from "../../detailDefault";

export default class detailacm_databox_accounts extends detailDefault {
    load(sAction, data) {
        this.hideButtons(sAction, data);
        this.duplicateControl(sAction, data);
    }

    duplicateControl(sAction, data) {
        const tickerSymbol = sAction.dataGet(data.prefix + "/fields/ticker_symbol/value");
        const params = {
            module : 'accounts',
            action: 'check_if_ticker_symbol_exist',
            ticker_symbol: tickerSymbol,
          }
        sAction.rest.post("customAction", params, function(resp) {
            if (resp.errCode == "OK") {
                if (resp.ticker_symbol) {
                    const imported = sAction.dataGet(data.prefix + "/fields/imported/value");
                    if (imported !== "1") {
                        sAction.error(sAction.translate(resp.message));
                        sAction.dsAdd("delete", data.prefix + "/buttons", 5);
                    }
                    sAction.dsProcess();
                }
            }
        });
    }

    hideButtons(sAction, data) {
        const imported = sAction.dataGet(data.prefix + "/fields/imported/value");
        if (imported === "1") {
            sAction.dsClear();
            sAction.dsAdd("delete", data.prefix + "/buttons", 5);
            sAction.dsAdd("delete", data.prefix + "/buttons", 5);
            sAction.dsProcess();
        }
    }
}