/**
 * Change id of accounts for filtering acm_machines on group (headquarters / branch)
 *
 * @param {{}} params
 */
export default function accountChangeForMachines(params) {
    this.dsClear();
    const productData = this.dataGet(`${params.way}/customData/productData`);
    productData.groups.forEach(((group, gInd) => {
        const way = `${params.way}/customData/productData/groups/${gInd}/${params.field}`;
        this.dsAdd(`set`, way, params.id_value);
    }));
    this.dsProcess();
}
