import { Map, List } from "immutable";
import { DataConstructors } from "../../../DataConstructors";

export default function calendarCustom(state, action) {
  
  var allState = state;
  if(action.type == "CALENDARINIT"){
    const prefix = action.prefix.split("/");
    const params = action.content.params;
    const filters = new DataConstructors["calFilters"]({
      showMeetings : params.showMeetings,
      showCalls : params.showCalls,
      showTasks : params.showTasks,
      showCompleted : params.showCompleted,
      meetStates : List(params.meetStates),
      callStates : List(params.callStates),
      taskStates : List(params.taskStates),
      filtersDisplayed : true
    });

    var eventData = null;
    var date_start = null;
    var date_end = null;
    var dashletId = null;


    if(action.content.eventData){
      const events = action.content.eventData;
      date_start = action.content.date_start;
      date_end = action.content.date_end;
      dashletId = action.content.dashletId;


      eventData = {Meetings : [], Calls : [], Tasks : []};
      
      events.Meetings.forEach(ev => {

        eventData.Meetings.push(new DataConstructors["calendarEvent"](ev));
      });
      events.Calls.forEach(ev => {
        eventData.Calls.push(new DataConstructors["calendarEvent"](ev));
      });
      events.Tasks.forEach(ev => {
        eventData.Tasks.push(new DataConstructors["calendarEvent"](ev));
      });
    }

    const calendar = new DataConstructors["calendar"]({
      sharedView : params.sharedView,
      filters: filters,
      setting: new Map(params),
      eventData: eventData,
      meetingView : new DataConstructors["DetailView"]({type : "calendar"}),
      callView : new DataConstructors["DetailView"]({type : "calendar"}),
      taskView : new DataConstructors["DetailView"]({type : "calendar"}),
      detailView : new DataConstructors["DetailView"]({type : "popup"}),
      date_start : date_start,
      date_end : date_end,
      dashletId : dashletId
    });

   return allState.setIn(prefix, calendar);
  }
  else if (action.type == "CALENDARLOADQUICKFORM") {

    const prefix = action.prefix.split("/");

    const data = action.content.invitees;

    const relatedInv = new DataConstructors["invitees"]({
      Users: List(data.Users),
      Contacts: List(data.Contacts),
      Leads: List(data.Leads)
    });

    const searchInv = new DataConstructors["invitees"]({
      Users: List(),
      Contacts: List(),
      Leads: List()
    });

    const invitees = new Map({
      relatedInv : relatedInv,
      searchInv : searchInv
    });

    return allState.setIn(prefix, invitees).setIn(action.prefixBackup.split("/"), invitees);
  }
  else if(action.type == "CALENDARSEARCHINVITEES"){
    const prefix = action.prefix.split("/");

    const relPref = prefix.slice();
    relPref.push("relatedInv");

    prefix.push("searchInv");

    var invitees = {Users : [], Contacts: [], Leads : []};

    if(state.getIn(relPref)) {
      var fieldArrState = state?.getIn(relPref).toJS();
      action.content.invitees.Users.forEach(searchInv => {
        if (!fieldArrState.Users.find(function (inv) {
          return (inv.id == searchInv.id);
        })) {
          invitees.Users.push(searchInv);
        }
      });

      action.content.invitees.Contacts.forEach(searchInv => {
        if (!fieldArrState.Contacts.find(function (inv) {
          return (inv.id == searchInv.id);
        })) {
          invitees.Contacts.push(searchInv);
        }
      });

      action.content.invitees.Leads.forEach(searchInv => {
        if (!fieldArrState.Leads.find(function (inv) {
          return (inv.id == searchInv.id);
        })) {
          invitees.Leads.push(searchInv);
        }
      });

    }

    return allState.setIn(prefix, new Map(invitees));
  }
  else if(action.type == "CALENDARCLEARDATA"){
    //DOSTAT
    if(action.content.prefix){
      var calState = state.getIn(action.content.prefix.split("/"));

      const emptyList = new DataConstructors["invitees"]({
        Users: List(),
        Contacts: List(),
        Leads: List()
      });

      const invitees = new Map({
        relatedInv: emptyList,
        searchInv: emptyList
      });

      calState = calState.setIn(["invitees"], invitees);

      calState = clearDetailFields(calState, "meetingView");
      calState = clearDetailFields(calState, "callView");
      calState = clearDetailFields(calState, "taskView");

      return allState.setIn(action?.content?.prefix.split("/"), calState);
    }
  }
  else if(action.type == "CALENDARINITACTIVITY"){
    const data = action.content.invitees;
    
    const prefix = action.prefix.split("/");

    const invList = new DataConstructors["invitees"]({
      Users: List(data.Users),
      Contacts: List(data.Contacts),
      Leads: List(data.Leads)
    });


    return allState.setIn(prefix, new Map(invList));
  }


  return allState;
}
function clearDetailFields(calState, viewName) {
  var fieldArrState = calState.getIn([viewName, "fields"]);

  fieldArrState.toJS().forEachObject(field => {
    //musi se procistit v parent_name v definici id_value aby se pri nove schuzce nedohledavala name
    //lokace se musi procistit v definici item aby se nedavala v novem popupu
    if(field.def.type === "parent"){
      fieldArrState = fieldArrState.setIn([field.name, "def", "id_value"], null);
      fieldArrState = fieldArrState.setIn([field.name, "def", "parent_type_value"], null);
    }
    if(field.name === "location"){
      fieldArrState = fieldArrState.setIn([field.name, "def", "item"],
          new Map({cast_obce: null, gpsX: null, gpsY: null, kraj: null, obec: null, okres: null, psc: null}));
    }
    fieldArrState = fieldArrState.setIn([field.name, "value"], field.def.default ?? null );
    fieldArrState = fieldArrState.setIn([field.name, "def","isInvalid"], false);
  });

  calState = calState.setIn([viewName, "changes"], new Map({fields: new Map(), files: new Map(), customData: new Map(), forceChange: false}));
  
  return calState.setIn([viewName, "fields"], fieldArrState);
}

