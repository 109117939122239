import React from "react"

export default function quoteCreateOrderPopup(params) {
    const data = {
        header: this.translate('LBL_QUOTE_CREATE_ORDER', 'Quotes'),
        buttons: [
            { label: this.translate('LBL_CANCEL'), callback: () => this.popupHide() },
            // Neposilat do Abry
            {
                label: this.translate('LBL_NOT_SEND_TO_ABRA', 'Quotes'),
                callback: () => this.quoteCreateOrder({...params, abra: false}),
            },

            // Poslat do Abry
            {
                label: this.translate('LBL_SEND_TO_ABRA', 'Quotes'),
                callback: () => this.quoteCreateOrder({...params, abra: true}),
            },
        ],
    };

    let message = this.translate('LBL_SEND_TO_ABRA_QUESTION', 'Quotes');

    this.popup(message, data);
}
