import React from 'react';
import MaterialDefault from './MaterialDefault';
import MaterialSelect from '@material-ui/core/Select';
import {
    Select as MuiSelect,
    FormControl,
    MenuItem,
    InputLabel,
    ListSubheader,
    TextField,
    InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import sAction from 'sAction';

export default class Select extends MaterialDefault {
    constructor(props) {
        super(props);
        let value = '';
        if (this.props.defaultValue != undefined) {
            value = this.props.defaultValue;
        }

        let open = false;
        if (this.props.open != undefined) {
            open = this.props.open;
        }
        this.state = {
            value: value,
            focus: false,
            openOnFocus: true,
            open: open,
            options: this.sortOptions(this.props.options),
            searchText: '',
        };
        this.options = this.sortOptions(this.props.options);
    }

    componentDidUpdate(prevProps) {
        if (this.props.options !== prevProps.options) {
            this.setState({options: this.sortOptions(this.props.options)});
        }
    }

    sortOptions(options) {
        const newOptions = options.sort((a, b) => a.label - b.label);
        return newOptions;
    }

    searchText(value) {
        this.setState({searchText: value});
        let options = this.options;

        options = options.filter((option) => {
            if (option.label.toLowerCase().includes(value.toLowerCase())) {
                return option;
            }
        });

        if (options[0]?.value !== 'acm_add_new') {
            options = [this.options[0]].concat(options);
        }
        this.setState({options: options});
    }

    onChange(e) {
        this.setState({
            value: e.target.value,
            open: false,
        });
        if (this.props.onChange != undefined) {
            this.props.onChange(e);
        }
    }
    onClose(e) {
        this.setState({
            open: false,
            searchText: '',
        });
        setTimeout(() => { }, 100);
        if (this.props.onClose != undefined) {
            this.props.onClose(e);
        }
    }
    onOpen(e) {
        this.setState({open: true});
        if (this.props.onOpen != undefined) {
            this.props.onOpen(e);
        }
    }
    onBlur(e) {
        if (this.props.onBlur != undefined) {
            this.props.onBlur(e);
        }
    }
    onKeyDown(e) {
        if (e.keyCode === 13 && this.state.open === false) {
            this.setState({open: true});
        }

        if (this.props.onKeyDown != undefined) {
            this.props.onKeyDown(e);
        }
    }
    onFocus(e) {
        if (this.props.onFocus !== undefined) {
            this.props.onFocus(e);
        }
    }
    render() {
        let labelRender = null;
        if (this.props.label != undefined) {
            labelRender = (
                <InputLabel htmlFor={this.props.id}>{this.props.label}</InputLabel>
            );
        }
        // const options = ;
        const colors = this.props.colors;

        const optionsRender = [];
        this.state.options.forEach((value, key) => {
            let isDisabled = false;
            if (value.disabled) {
                isDisabled = true;
            }

            if (typeof(value.label) === 'string') {
                value.label = sAction.decodeHTMLEntities(value.label);
            }
            if (this.state.options.length > 200) {
                if (value.type != 'header') {
                    optionsRender.push(
                        <option key={key} value={value.value} disabled={isDisabled}>
                            {value.label} {sAction.getStorage('debug') && ' ['+value.value+']'}
                        </option>,
                    );
                }
            } else {
                if (value.type == 'header') {
                    let style = null;
                    if (colors && colors[value.value]) {
                        style = {backgroundColor: colors[value.value], color: colors[value.value]};
                    }
                    optionsRender.push(
                        <MenuItem key={key} disabled={true} className="acmSelectHeader">
                            {style ? (
                                <span className='colorList listFilter' style={style}> </span>
                            ) : null}
                            {value.label} {sAction.getStorage('debug') && ' ['+value.value+']'}
                        </MenuItem>,
                    );
                } else {
                    let style = null;
                    if (colors && colors[value.value]) {
                        style = {backgroundColor: colors[value.value], color: colors[value.value]};
                    }
                    optionsRender.push(
                        <MenuItem
                            key={key}
                            disabled={isDisabled}
                            value={value.value}
                            className={value.type == 'button' ? 'selectButton' : ''}
                        >
                            {style ? (
                                <span className='colorList listFilter' style={style}> </span>
                            ) : null}
                            {value.label}{sAction.getStorage('debug') && ' ['+value.value+']'}
                        </MenuItem>,
                    );
                }
            }
        });
        let className = 'acmSelectContainer';
        if (this.state.focus == true) {
            className = 'acmSelectContainer focus';
        }
        if (this.props.error == true) {
            className += ' error';
        }
        // const attr = this.createAttr("acmSelect");

        let value = this.state.value;
        if (this.props.value != undefined) {
            value = this.props.value;
        }
        return (
            <FormControl
                className={
                    this.props.containerClassName != undefined ?
                        className + ' ' + this.props.containerClassName :
                        className
                }
                disabled={this.props.disabled}
                onKeyDown={(e) => this.onKeyDown(e)}
                autoFocus={this.props.autoFocus}
                style={this.props.containerStyle ? this.props.containerStyle : null}
            >
                {labelRender}
                {this.props?.allowSearch ? (
                    <MuiSelect
                        native={optionsRender.length > 200 ? true : false}
                        // Disables auto focus on MenuItems and allows TextField to be in focus
                        MenuProps={{
                            autoFocus: false,
                            ...this.props.menuProps,
                        }}
                        labelId="search-select-label"
                        id={this.props.id}
                        className="acmSelect"
                        value={value}
                        onChange={(e) => this.onChange(e)}
                        // This prevents rendering empty string in Select's value
                        // if search text would exclude currently selected option.
                        renderValue={() => value}
                        open={this.state.open}
                        autoFocus={this.props.autoFocus}
                        inputProps={{id: this.props.id}}
                        onOpen={(e) => this.onOpen(e)}
                        onClose={(e) => this.onClose(e)}
                        onBlur={(e) => this.onBlur(e)}
                        onKeyDown={(e) => this.onKeyDown(e)}
                        onFocus={(e) => this.onFocus(e)}
                        inputRef={this.props.myRef}
                        name={this.props.name}
                        style={this.props.style ? this.props.style : null}
                        autoWidth={this.props.autoWidth}
                    >
                        {/* TextField is put into ListSubheader so that it doesn't
                            act as a selectable item in the menu
                            we can click the TextField without triggering any selection.*/}
                        <ListSubheader>
                            <TextField
                                size="small"
                                autoFocus
                                placeholder={sAction.translate('LBL_TYPE_TO_SEARCH')}
                                fullWidth
                                onBlur={(e) => this.onBlur(e)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(e) => this.searchText(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key !== 'Escape') {
                                        // Prevents autoselecting item while typing (default Select behaviour)
                                        e.stopPropagation();
                                    }
                                }}
                            />
                        </ListSubheader>
                        {optionsRender}
                    </MuiSelect>
                ) : (
                    <MaterialSelect
                        native={optionsRender.length > 200 ? true : false}
                        className="acmSelect"
                        value={value}
                        id={this.props.id}
                        inputProps={{id: this.props.id}}
                        onChange={(e) => this.onChange(e)}
                        onOpen={(e) => this.onOpen(e)}
                        onClose={(e) => this.onClose(e)}
                        onBlur={(e) => this.onBlur(e)}
                        onKeyDown={(e) => this.onKeyDown(e)}
                        onFocus={(e) => this.onFocus(e)}
                        inputRef={this.props.myRef}
                        open={this.state.open}
                        autoFocus={this.props.autoFocus}
                        name={this.props.name}
                        style={this.props.style ? this.props.style : null}
                        autoWidth={this.props.autoWidth}
                        MenuProps={this.props.menuProps}
                    >
                        {optionsRender}
                    </MaterialSelect>
                )}
            </FormControl>
        );
    }
}
