export default function adminMessage(data,callback) {
    const action = data.action
    if(action == "setStorage"){
        data.data.forEachObject((data,key) => {
            this.setStorage(key,data)
        })
    }else if(action == "updateCssFromStorage"){
        this.overwriteStyle();
    }else if(action == "getStorage"){
        const storage = this.getStorage(data.data.key);
        callback(storage);
    }else if(action === "debugView"){
        this.setStorage("debug", data.data.checked || "");
    } else if(action === "acmDate"){
        this.popup("acmDate",{value:data.data.value,callback:(date) => {
                callback(date)
            }});
    } else if(action === "acmMonth"){
        this.popup("acmDate",{views: ['month'], value:data.data.value,callback:(date) => {
                callback(date)
            }});
    } else if(action === "acmDateTime"){
        this.popup("acmDateTime",{value:data.data.value, views: 'month',callback:(date) => {
                callback(date)
        }});
    }
    if (action == "setStyle") {
      
    }
}
