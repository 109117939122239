import React from "react";

import InputField from "../inputFieldClass";
import Loader from "../../loader";
import sAction from "sAction";
import {Record, Map, List} from "immutable";

export default class AcmAddress extends InputField {
  _isMounted = false;

  constructor() {
    super();
    this.state = {
      state: "fine",
      value: null,
      id: null,
      loading: false,
      resultData: null,
      resultOpen: false,
      resultArrowIndex: -1
    };
    this.searchInterval = null;
    this.input = React.createRef();
    this.inputObec = React.createRef();
    this.isAdressSelected = true;
    let microservicesScopes = sAction.dataGet('conf/user/microservice_access_token_allowed_scopes')
    if(Map.isMap(microservicesScopes) || Record.isRecord(microservicesScopes) || List.isList(microservicesScopes)){
      microservicesScopes = microservicesScopes.toJS();
    }
    this.twoInputs = true;
    if(microservicesScopes.includes('maps-seznam-mapycz')){
      this.twoInputs = false;
    }
  }
  keyUp(event) {
    const keyCode = event.keyCode;
    if (keyCode === 13 && event.ctrlKey === false) {
      if (this.state.resultOpen == true) {
        const item = this.state.resultData[this.state.resultArrowIndex];
        if (item != undefined) {
          this.selectItem(item, true, true);
          this.setState({ resultOpen: false });
          this.input.current.value = item.name;
        }
      } else {
        this.save();
      }
    } else if (keyCode === 27) {
      this.cancel();
    } else if (keyCode === 40 || keyCode === 38) {
      this.changeArrowIndex(keyCode);
    } else if (keyCode !== 9 && keyCode !== 37 && keyCode !== 39) {
      this.waitForSearch();
    }
  }
  waitForSearch() {
    this.isAdressSelected = false;
    if (this.searchInterval != null) {
      clearInterval(this.searchInterval);
    }
    var self = this;
    this.searchInterval = setInterval(() => {
      self.search();
      clearInterval(self.searchInterval);
    }, 800);
  }
  changeArrowIndex(keyCode) {
    var resultArrowIndex = this.state.resultArrowIndex;
    if (keyCode === 40) {
      resultArrowIndex += 1;
    } else if (keyCode === 38) {
      resultArrowIndex -= 1;
    }

    if (resultArrowIndex < -1) {
      resultArrowIndex = -1;
    }
    if (this.state.resultData != null) {
      if (resultArrowIndex >= this.state.resultData.length) {
        resultArrowIndex = this.state.resultData.length;
      }
    }
    this.setState({ resultArrowIndex });
  }
  search = () => {
    if (!this.input.current || !this._isMounted) {
      return;
    }
    const value = this.input?.current?.value ?? '';
    const cityValue = this.inputObec?.current?.value ?? '';
    if (value !== "" || value !== null) {
      const searchData = {
        street:value,
        city:cityValue,
      };
      var self = this;
      this.setState({ loading: true });
      sAction.searchAcmAddress(searchData, data => {
        let transformed = [];

        if (data.length) {
          data.forEach((item) => {
            let name = '';
            if(item.type === 'poi'){
              name = item.name;
            }

            let streetNumber = item?.address?.address?.split('/') ?? [];
            let current = {
              kraj: item.address.kraj ?? '',
              obec: item.address.city ?? '',
              okres: item.address.state ?? '',
              psc: item.address.zip ?? '',
              ulice: item.address.street ?? '',
              x: item.gpsX ?? '',
              y: item.gpsY ?? '',
              name: name,
            };

            current.cislo_dom = streetNumber[0] ?? '';
            current.cislo_orient = streetNumber[1] ?? '';
            transformed.push(current);
          });
          // transformed = transformed.sort(this.sortAddresses); // sort addresses by numbers, breaks maps
        }

        if (!this._isMounted) {
          return;
        }
        self.setState({
          resultArrowIndex: -1,
          resultData: transformed,
          resultOpen: true,
          loading: false
        });
      });
    }
  }

  sortAddresses(a, b){
    const cisloDomA = a.cislo_dom.replace('ev. č. ', '')
    const cisloDomB= b.cislo_dom.replace('ev. č. ', '')
    if(cisloDomA !== '' && cisloDomB !== ''){
      if(+cisloDomA === +cisloDomB){
        if(a.cislo_orient !== '' && b.cislo_orient !== ''){
          if(+a.cislo_orient < +b.cislo_orient){
            return -1
          } else{
            return 1
          }
        }
      }
      if(+cisloDomA < +cisloDomB){
        return -1
      } else{
        return 1
      }
    }
    if(cisloDomA !== ''){
      return -1
    }
    if(cisloDomB !== ''){
      return 1
    }
    return 0;
  }

  selectItem(item) {
    this.isAdressSelected = true;
    this.setState({
      name: sAction.getStreetlabelAcmAddress(item)
    });
    sAction.saveAcmAddressField(
      item,
      this.props.data,
      this.props.prefix,
      this.props.way
    );
  }
  focus() {
    if (this.state.resultData != null) {
      this.setState({
        resultOpen: true
      });
    }
  }
  getSearchResult() {
    var searchResult = null;
    if (this.state.loading == true) {
      searchResult = (
        <div className="quickSearchResult">
          <Loader />
        </div>
      );
    } else if (this.state.resultOpen === true) {
      var resultList = [];
      this.state.resultData.forEach((item, index) => {
        var lineClass = "quickSearchResultLine";

        if (index === this.state.resultArrowIndex) {
          lineClass += " arrowSelect";
        }

        resultList.push(
          <div
            tabIndex={index}
            onClick={() => this.selectItem(item)}
            key={index}
            className={lineClass}
          >
            <div className={'quickSearchResultLineItem'}>
              {this.getLabel(item)}
            </div>
            <div className={'quickSearchResultLineItem'}>
              <span className={'itemInfo'}>{item.name}</span>
            </div>
          </div>
        );
      });
      if (!resultList.length) {
        resultList.push(
          <div
            tabIndex={0}
            key="No-results"
            onClick={this.chooseLocation}
            className="quickSearchResultLineError"
          >
            Neznámá adresa - vyberte pomocí mapy
          </div>
        );
      }
      searchResult = <div className="quickSearchResult">{resultList}</div>;
    }
    return searchResult;
  }

  chooseLocation = () => {
    this.save();
    setTimeout(() => {
      document.querySelector(".icon-Location").click();
    }, 1000);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  cancel() {
    this.isAdressSelected = true;
    super.cancel();
  }

  save = () => {
    if (this.isAdressSelected) {
      super.save();
    } else {
      super.cancel();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    if (!this.isAdressSelected) {
      const item = this.props.data.def.get("item");
      const data = {
        x: item.get("gpsX"),
        y: item.get("gpsY"),
      }
      this.selectItem(data);
    }
  }

  getLabel(item) {
    let label = sAction.getStreetlabelAcmAddress(item);
    label = label ? `${label ?? ''}, ${item.obec ?? ''} ${item.psc ?? ''}` :  `${item.obec ?? ''} ${item.psc ?? ''}`;
    return label;
  }
  onBlur(e) {
    const relTarget = e.relatedTarget;
    if (relTarget == null) {
      this.cancel();
    } else {
      var parent = relTarget.closest(
        "div[data-fieldname='" + this.props.data.name + "']"
      );
      if (parent == null) {
        this.cancel();
      }
    }
  }
  render() {
    const data = this.props.data;
    const newRecord = this.props.newRecord;
    var containerClass = "inputEditContainer";

    var value = data.value;
    if (newRecord && (value === "" || value === undefined || value === null)) {
      value = "";
    } else {
      const item = data.def.get("item");
      const obec = item.get("obec");
      const cast_obce = item.get("cast_obce");
      const psc = item.get("psc");
      const kraj = item.get("kraj");
      var obecValue = "";
      if (obec) {
        obecValue += obec;
        if (psc) {
          //   value += " "+psc;
        }
        if (kraj) {
          //   value += " "+kraj;
        }
      }
    }
    const searchResult = this.getSearchResult();

    const containerClassField = "inputContainer acmAddressField";

    let underline = false;
    let secondInput = null;
    let primaryInputDefault = `${value ?? ''} ${obecValue ?? ''}`

    if(this.twoInputs){
      primaryInputDefault = value ?? '';
      underline = true;
      secondInput = (
          <input
              type="text"
              className="acmAddressCity underline"
              onKeyDown={e => this.onKeyDown(e, "last")}
              onKeyUp={event => this.keyUp(event)}
              placeholder={"obec"}
              defaultValue={obecValue}
              ref={this.inputObec}
          />
      );
    }
    primaryInputDefault = primaryInputDefault.trim();
    return (
      <div className={containerClass} data-fieldname={data.name}>
        <div className={containerClassField}>
          <input
            type="text"
            className={'acmAddressStreet' + (underline ? ' underline' : '')}
            placeholder={"ulice"}
            onKeyDown={e => this.onKeyDown(e, "first")}
            autoFocus={true}
            onKeyUp={event => this.keyUp(event)}
            onFocus={() => this.focus()}
            ref={this.input}
            id={data.name}
            defaultValue={primaryInputDefault}
            autoComplete="off"
          />
          {secondInput}
          {searchResult}
        </div>
        {!newRecord && (
          <div className="buttonContainer">
            <div
              tabIndex="2"
              onClick={() => this.save()}
              className="inputEditButton"
            >
              <div className={"icon-detailConfrim"} />
            </div>
            <div
              tabIndex="2"
              onClick={() => this.cancel()}
              className=" inputEditButton"
            >
              <div className={"icon-detailCancel"} />
            </div>
          </div>
        )}
      </div>
    );
  }
}
