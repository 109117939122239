import sAction from "sAction";
import Button from "ROOT/src/components/formElements/Button";
import React, {PureComponent} from "react";

/**
 * @parent src/components/RightPanel/RightPanel.js
 */
export default class RightPanelMultiEnumHeader extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            load: null,
        }
    }

    encodeFields() {
        sAction.load()

        let fields = sAction.dataGet("rightPanel/data/checkedFields")
        const formattedFields = this.formatFields(fields)

        this.saveToCustomData(formattedFields, fields)

        sAction.unLoad()
    }

    saveToCustomData(formattedFields, checkedFields) {
        const whichProducts = this.props.data.get("fieldName")
        if (whichProducts.includes("uses")) {
            sAction.dataSet("view/changes/customData/products/productsUses", formattedFields)
            sAction.dataSet("view/changes/customData/products/productsUses/checkedFields", checkedFields)
            sAction.dataSet("view/changes/customData/products/productsUses/fieldName", "account_products_uses")
            sAction.dataSet("view/customData/products/productsUses", formattedFields)
            sAction.dataSet("view/customData/products/productsUses/checkedFields", checkedFields)
            sAction.dataSet("view/customData/products/productsUses/fieldName", "account_products_uses")
        } else if (whichProducts.includes("takes")) {
            sAction.dataSet("view/changes/customData/products/productsTakes", formattedFields)
            sAction.dataSet("view/changes/customData/products/productsTakes/checkedFields", checkedFields)
            sAction.dataSet("view/changes/customData/products/productsTakes/fieldName", "account_products_takes")
            sAction.dataSet("view/customData/products/productsTakes", formattedFields)
            sAction.dataSet("view/customData/products/productsTakes/checkedFields", checkedFields)
            sAction.dataSet("view/customData/products/productsTakes/fieldName", "account_products_takes")
        } else {
            console.error("field is not takes or uses check props or field definition");
        }
    }

    formatFields(fields) {
        let formattedFields = {
            leftEnum: {},
            rightEnum: {},
        }
        const products = sAction.dataGet("rightPanel/data/products")
        const productsInfo = sAction.dataGet("rightPanel/data/productsInfo")

        products.map((product, productKey) => {
            if (fields.get("leftEnum").includes(productKey)) {
                formattedFields.leftEnum[productKey] = product
            }
        })

        productsInfo.map((productsInfoArr, productsInfoKey) => {
            productsInfoArr.forEach((element, index) => {
                const productsInfo = fields.get("rightEnum").get(productsInfoKey)

                if (productsInfo && productsInfo.indexOf(index) > -1) {
                    if (Array.isArray(formattedFields.rightEnum[productsInfoKey])) {
                        formattedFields.rightEnum[productsInfoKey].push(element)
                        return
                    }

                    formattedFields.rightEnum[productsInfoKey] = [element]
                }
            })
        })


        return formattedFields
    }

    makeDuplicityPopup() {
        sAction.popupHide()
        const popupData = {
            header: sAction.translate("LBL_PRODUCT_DUPLICITY_HEADER"),
            buttons: [
                {
                    label: sAction.translate("LBL_NO"), callback: () => {
                        sAction.popupHide()
                    }
                },
            ],
        }

        sAction.popup(sAction.translate("LBL_PRODUCT_DUPLICITY"), popupData);
    }

    /**
     * creates popup with fields text field where user will put new product
     * and checks duplicity
     * @param enumSide string contains either "products or productsInfo"
     */
    addProductToList(enumSide) {

        const popupData = {

            header: sAction.translate("LBL_ADD_ITEM_TO_LIST"),
            callback: (data) => {
                if (this.checkDuplicity(enumSide, data)) {
                    this.makeDuplicityPopup()
                    return;
                }

                let sendData = {}
                if (enumSide === "products") {
                    const key = data.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()

                    sendData = {
                        side: "leftSide",
                        newData: {[key]: data}
                    }
                } else if (enumSide === "productsInfo") {
                    const key = sAction.dataGet("rightPanel/data/activeTab/fieldKey")

                    sendData = {
                        side: "rightSide",
                        newData: {[key]: data}
                    }
                } else {
                    console.log("Err: invalid enumSide check button arguments in render function")
                    return
                }

                sAction.rest.post("addToList", sendData, restData => {
                    if (restData.status) {
                        sAction.popupHide()
                        sAction.openRightPanelMultiEnum(this.props.data.get("fieldName"))

                    }
                })
            }

        }
        sAction.popup("addToRightPanelEnum", popupData);

    }

    checkDuplicity(enumSide, newElement) {
        let foundDuplicity = false;

        const listData = sAction.dataGet(`rightPanel/data/${enumSide}`)
        if (enumSide === "products") {
            listData.forEach(element => {
                if (element === newElement) {
                    foundDuplicity = true
                }
            })
        } else if (enumSide === "productsInfo") {
            listData.forEach(element => {
                if (element.includes(newElement)) {
                    foundDuplicity = true
                }
            })
        } else {
            console.log("Err: invalid enumSide check button arguments in render function")
        }

        return foundDuplicity
    }


    render() {
        return (
            <div className="viewActionPanel">
                <div className="viewActionPanelButtons">
                    <Button className="hoverGreen" onClick={() => this.encodeFields()}>
                        <span className={"actionPanelButtonIcon icon-saveIcon"}/>
                        {sAction.translate("LBL_SAVE_RECORD")}
                    </Button>
                    <div className="viewPanelButtonsDelimiter"/>
                    <Button className="hoverGreen" onClick={() => {
                        this.encodeFields();
                        sAction.closeRightPanel()
                    }}>
                        <span className={"icon-saveIcon actionPanelButtonIcon"}/>
                        {sAction.translate("LBL_SAVE_RECORD_LEAVE")}
                    </Button>
                    <div className="viewPanelButtonsDelimiter"/>
                    <Button onClick={() => this.addProductToList("products")}>
                        <span className={"icon-addIcon actionPanelButtonIcon"}/>
                        {sAction.translate("LBL_ADD_ITEM_TO_LIST")}
                    </Button>
                    <div className="viewPanelButtonsDelimiter"/>
                    <Button onClick={() => this.addProductToList("productsInfo")}>
                        <span className={"icon-addIcon actionPanelButtonIcon"}/>
                        {sAction.translate("LBL_ADD_ITEM_INFO_TO_LIST")}
                    </Button>
                </div>
            </div>
        )
    }
}