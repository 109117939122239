import PureComponent from '../pure';
import sAction from 'sAction';

export default class FormField extends PureComponent {
    componentDidMount() {}
    getTabData(shift = false) {
        return {
            name: this.props.data.name,
            prefix: this.props.prefix,
            index: this.props.index,
            rowWay: this.props.rowWay,
            shift: shift,
        };
    }
    onKeyDown(e, type = null, save = true) {
        let data = null;
        // TAB 9
        // ENTER 13
        if (e.keyCode === 9 || (e.keyCode === 13 && (type === 'enum' || type === 'dynEnum'))) {
            data = this.getTabData(e.shiftKey);
        } else {
            // this.check(); -> check se zavolá na child fieldu a na ICO to spust kontolu
            if(e.keyCode === 9){
                if (save === true && this.check() && e.keyCode === 13) {
                    this.save(e);
                }
            }
        }
        if (data != null && data.prefix) {
            e.preventDefault();
            if (save === true && type !== 'dynEnum') {
                if (this.check()) {
                    this.save(e);
                } else {
                    data['error'] = true;
                }
            }
            sAction.detailTabNext(data, save);
        }
    }

    getValue() {
        const value = this.refs.input.value;
        return {name: this.props.data.name, value: value};
    }
}
