import React, {useState} from "react"
import sAction from "sAction"
import utils from '../../../action/utils/utils.js'

export default function QuickStandardState({lineData, extraClass}) {
    const [position, setPosition] = useState({})
    const {areAllEqual, isAtLeastOneEqual, areValuesEmpty} = utils();

    const getColor = () => {
        if (areValuesEmpty(lineData.get("front_pneu_type"), lineData.get("rear_pneu_type"))){
            return "stockStateUnknown";
        } else if (lineData.get("quick") === "1" && areAllEqual('quick', lineData.get("front_pneu_type"), lineData.get("rear_pneu_type"))) {
            return "isInStock";
        } else if (lineData.get("quick") === "0" && areAllEqual('standard', lineData.get("front_pneu_type"), lineData.get("rear_pneu_type"))){
            return "isInStock";
        } else if (lineData.get("quick") === "1" && isAtLeastOneEqual('quick', lineData.get("front_pneu_type"), lineData.get("rear_pneu_type"))) {
            return "lowOnStock";
        } else if (lineData.get("quick") === "0" && isAtLeastOneEqual('standard', lineData.get("front_pneu_type"), lineData.get("rear_pneu_type"))) {
            return "lowOnStock";
        } else if (lineData.get("quick") === "1" && !areAllEqual('quick', lineData.get("front_pneu_type"), lineData.get("rear_pneu_type"))) {
            return "isNotInStock";
        } else if (lineData.get("quick") === "0" && !areAllEqual('standard', lineData.get("front_pneu_type"), lineData.get("rear_pneu_type"))){
            return "isNotInStock";
        }
    }

    const getProductState = () => {
        if(!lineData.get("quick")){
            return '--'
        }

        return lineData.get("quick") === "0" ? "Standard" : "Quick"
    }


    return (
        <div
            className={"newListViewLine " + extraClass}
            onMouseEnter={(e) => setPosition({
                top: e.pageY - 100 + 'px',
                left: e.pageX - 300 + 'px'
            })}
            onMouseLeave={() => setPosition({})}
        >
            <span className={`stockSquare ${getColor()}`} style={{borderRadius: '20px'}}/>
            {Object.keys(position).length > 0 ? <div className="stockPopup" style={position}>
                <div className="calAdiconHeader">
                    <span className="calAdiconBoxTitle">{sAction.translate("LBL_QUICK_STANDARD_STATE")}</span>
                </div>
                <div className="stockOptionsContainer">
                    <table className="stockStateTable">
                        <tbody>
                        <tr className="stockLine">
                            <td className="stockStateName">{sAction.translate("LBL_PRODUCT")}</td>
                            <td className="stockStateNumber">{getProductState()}</td>
                        </tr>
                        <tr className="stockLine">
                            <td className="stockStateName">{sAction.translate("LBL_FRONT_PNEU")}</td>
                            <td className="stockStateNumber">
                                {sAction.translateSelect("quick_standard_list", lineData.get("front_pneu_type")) || '--'}
                            </td>
                        </tr>
                        <tr className="stockLine">
                            <td className="stockStateName">{sAction.translate("LBL_REAR_PNEU")}</td>
                            <td className="stockStateNumber">
                                {sAction.translateSelect("quick_standard_list", lineData.get("rear_pneu_type")) || '--'}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div> : null}
        </div>
    );
}