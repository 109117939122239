export default function send_questionnaire(params){

  this.popup("send_questionnaire",{
      header:this.translate("LBL_TEST_TEMPLATE"),
      callback: (data) => {
          let accID = this.dataGet(params.prefix+"/fields/acm_satisfaction_questionnaire_accounts_name/def/id_value");
          const d = {
              ...params,
              ...data,
              contact : accID
          }
          this.load();

          this.rest.post("send_questionnaire",d,ret => {
              if (ret.status){
                  this.unLoad();
                  this.popupHide();
              }
              else{
                  this.unLoad();
                  this.alert(ret.errorMessage.text)
              }
          })
      }
  });
}
