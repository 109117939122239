import React from "react";
import PureComponent from "../pure";
import sAction from "sAction";
import MenuButton from "./menuButton";

export default class MenuGroup extends PureComponent {
  render() {
    var colorNumber = 0;
    const data = this.props.data;
    const groupIndex = this.props.groupIndex;
    const open = this.props.open;
    const colorCount = sAction.colorTemplate.length;
    var groupWithSelectModule = false;
    // console.log(data.toJS()); 
    const buttonsRender = data.buttons.map((button, buttonIndex) => {
      const iconColor = sAction.colorTemplate[colorNumber];
      if (colorNumber >= colorCount) {
        colorNumber = 0;
      } else {
        colorNumber += 1;
      }
      if (button.get("name").toLowerCase() == this.props.activeModule.toLowerCase()) {
        groupWithSelectModule = true;
      }
      return (
        <MenuButton
          key={buttonIndex}
          iconColor={iconColor}
          index={buttonIndex}
          activeModule={this.props.activeModule}
          way={"menu/groups/" + groupIndex + "/buttons/" + buttonIndex}
          ref={button.name}
          button={button}
          menuOpen={this.props.menuOpen}
        />
      );
    });

    var extraClass = "menuGroup";
    if (groupWithSelectModule) {
      extraClass += " select";
    }
    if (this.props.menuOpen === true) {
      return (
        <div>
          {this.props.oneGroup || (
            <div
              onClick={() => sAction.toggleMenuGroup(groupIndex)}
              className={extraClass + (open ? " open" : "")}
            >
              <div>
              <span className={"icon-" + data.icon} />
              {sAction.translate(data.name)}
              </div>
              <div className="menuGroupOpenIndicator">
                <div className={open ? "icon-subpanelClose" : "icon-subpanelOpen"} />
              </div>
            </div>
          )}
          <div className={"menuGroupItems" + (open ? " fullHeight" : "")}>
            {buttonsRender}
          </div>
        </div>
      );
    } else {
      return <>{buttonsRender}</>;
    }
  }
}
