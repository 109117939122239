export default function detailTabNext(data) {
    const shift = data.shift;
    const index = data.index;
    const rowWay = data.rowWay;
    const rowWayExplode = rowWay.split('/');
    const rowIndex = rowWayExplode[rowWayExplode.length - 1];
    rowWayExplode.splice(rowWayExplode.length - 1, 1);
    const rowsWay = rowWayExplode.join('/');

    const rowsData = this.dataGet(rowsWay);

    let newField = null;
    newField = getNextField(rowsData, rowIndex, index, data, this);

    if (newField != null) {
        if (this.detailTempData[data.prefix] === undefined) {
            this.detailTempData[data.prefix] = {
                activeField: newField,
            };
        } else {
            this.detailTempData[data.prefix]['activeField'] = newField;
        }
        this.dsClear();
        if (data.error !== true) {
            this.dsAdd('set', data.prefix + '/fields/' + data.name + '/actEdit', false);
            this.dsAdd('set', data.prefix + '/fields/' + newField + '/actEdit', true);
        }

        const newType = this.dataGet(data.prefix + '/fields/' + newField + '/def/type');
        if (newType === 'enum' || newType === 'currency_id' || newType === 'multienum') {
            this.dsAdd('set', data.prefix + '/fields/' + newField + '/def/forceOpen', true);
        }
        this.dsProcess();
    }
}

function getNextField(rowsData, rowIndex, index, data, sAction) {
    let newColIndex;
    let newRowIndex;
    let maxIndex = 0;
    rowsData.forEach((row) => {
        if (row.size > maxIndex) {
            maxIndex = row.size;
        }
    });
    if (data.shift === true) {
        newColIndex = Math.abs((index - 1) % maxIndex);
        newRowIndex = newColIndex - index === -1 ? rowIndex : ((parseInt(rowIndex) - 1) % rowsData.size);
        newRowIndex = newRowIndex === -1 ? rowsData.size - 1 : newRowIndex;
    } else {
        newColIndex = Math.abs((index + 1) % maxIndex);
        newRowIndex = newColIndex - index === 1 ? rowIndex : ((parseInt(rowIndex) + 1) % rowsData.size);
    }
    const splitName = data.name?.split('_');
    if (splitName.length === 3 && splitName.indexOf('address') !== -1) {
        return getNextFieldAddress(rowsData, index, rowIndex, maxIndex, data, sAction);
    }
    const newField = rowsData.getIn([newRowIndex, newColIndex]);
    if (newField === undefined || newField === 'empty_row') {
        return getNextField(rowsData, newRowIndex, newColIndex, data, sAction);
    } else {
        const displayIfNew = sAction.dataGet(data.prefix+'/fields/'+newField+'/def/displayIfNew');
        const id = sAction.dataGet(data.prefix+'/id');
        const readonly = sAction.dataGet(data.prefix+'/fields/'+newField+'/def/readonly');
        const visible = sAction.dataGet(data.prefix+'/fields/'+newField+'/def/visible');
        const type = sAction.dataGet(data.prefix+'/fields/'+newField+'/def/type');
        const skippedTypes = ['bool', 'image', 'radioenum', 'file', 'button'];
        if (visible === false || readonly === true || skippedTypes.indexOf(type) !== -1 ||
            (displayIfNew === false && id === null)) {
            return getNextField(rowsData, newRowIndex, newColIndex, data, sAction);
        }
        const newFieldSplit = newField.split('_');
        if (newFieldSplit.length === 3 && newFieldSplit[2] === 'street' && data.shift) {
            return newFieldSplit[0] + '_address_country';
        }
        return newField;
    }
}
function getNextFieldAddress(rowsData, index, rowIndex, maxIndex, data, sAction) {
    const addressOrder= [
        'street',
        'city',
        'postalcode',
        'state',
        'kraj',
        'country',
    ];
    let increment;
    if (!data.shift && index === maxIndex - 1) {
        increment = 1;
    } else if (data.shift && index === 0) {
        increment = -1;
    } else {
        increment = 0;
    }

    let newRowIndex;
    let newColIndex;
    const splitName = data.name.split('_');
    if (data.shift === true) {
        newColIndex = Math.abs((index - 1) % maxIndex);
        newRowIndex = (newColIndex - index === 1 && splitName[2] === 'street') ? parseInt(rowIndex) - 1 : parseInt(rowIndex);
        newRowIndex = newRowIndex === -1 ? rowsData.size - 1 : newRowIndex;
    } else {
        newColIndex = Math.abs((index + 1) % maxIndex);
        newRowIndex = (newColIndex - index === -1 && splitName[2] === 'country') ? (parseInt(rowIndex) + 1) % rowsData.size : parseInt(rowIndex);
    }

    const newSplitName = rowsData.getIn([newRowIndex, newColIndex])?.split('_');
    const addressIndex = (addressOrder.indexOf(splitName[2]) + increment) % addressOrder.length;

    if ((splitName[2] === 'country' && index === maxIndex - 1 && !data.shift) ||
        splitName[2] === 'street' && index === 0 && data.shift) {
        sAction.dataSet(data.prefix + '/fields/' + data.name + '/actEdit', false);
        data.name = '';
        if (rowsData.size === 1 && (splitName[2] === 'street' && index === 0 && data.shift)) {
            return newSplitName[0] + '_address_' + addressOrder[addressOrder.length - 1];
        }
        return getNextField(rowsData, newRowIndex, index, data, sAction);
    }
    return newSplitName[0] + '_address_' + addressOrder[addressIndex];
}
