
export default function downloadFile(id, module, value) {
    // console.log('downloadFile', data);
    if (!id) {
        console.error('Chybí id souboru/záznamu');
    // return; //vyjimka email
    }
    if (!module) {
        console.error('Chybí modul souboru/záznamu');
    // return; //vyjimka email
    }


    this.filesXhr({
        action: 'previewPDF',
        data: {id: id, module: module},
        success: (ret) => {
            if (ret.status == true) {
                const result = ret.message.data;
                console.log('result', ret);
                if (result.token && result.type == 'local') {
                    const link = this.param.files + 'downloadFile/' + result.token;
                    console.log(link );
                    this.openRightPanel('pdf', {name: value, url: link});
                } else if (result.type == 'sharepoint') {
                    console.log('sharepoint', result);
                    this.openRightPanel('pdf', {name: value, url: result.url});
                }
            } else {
                // this.popupHide();
                // this.alert(this.translate(ret.errorMessage.text));
            }
        },
    });
}
