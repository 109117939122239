export default function checkRelateErrors(prefix, module){
    let fieldsToCheck = '';
    if(module === "Quotes"){
        fieldsToCheck = ["accounts_quotes_1_name", "billing_account_name"]
    } else if (module === "acm_orders") {
         fieldsToCheck = ["accounts_acm_orders_1_name", "accounts_acm_orders_2_name", "accounts_acm_orders_3_name", "acm_orders_accounts_name"]
    }

    let canSave = true;
    let self = this
    if(fieldsToCheck) {
        fieldsToCheck?.forEach(field => {
            const error = self.dataGet(prefix + '/fields/' + field + '/def/error')
            if (error) {
                canSave = false;
            }
        })
    }

    this.dataSet(prefix + '/canSave', canSave)
}