export default function searchAcmAddress(paramData, callback) {
    let query = paramData.city ? `${paramData.city ?? ''} ${paramData.street ?? ''}` : paramData.street ?? '';
    if(!query || query === ' '){
        console.log('STOP search')
        const emptyResponse = {data: {}};
        callback(emptyResponse);
        return emptyResponse;
    }

    this.rest.fetchMS('maps/v1/get-poi', 'GET', {query}, false).then(response => {
        callback(response);

        return response;
    });
}
