import React from 'react';

import MaterialDefault from './MaterialDefault';
import InputText from './InputText';
import sAction from 'sAction';
import Loader from '../loader';

export default class Relate extends MaterialDefault {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            state: 'fine',
            value: props.data.value,
            id: null,
            resultData: null,
            resultOpen: false,
            resultArrowIndex: -1,
            loaded: false,
        };
        this.searchInterval = null;
        this.input = React.createRef();
        this.containerRef = React.createRef();
    }
    keyUp(event) {
        const keyCode = event.keyCode;
        if (keyCode === 13 && event.ctrlKey === false) {
            if (this.state.resultOpen == true) {
                const item = this.state.resultData[this.state.resultArrowIndex];
                if (item != undefined) {
                    this.selectItem(item, true, false);
                    this.setState({resultOpen: false});
                    this.input.current.value=item.name;
                }
            } else {
                // this.save();
            }
        } else if (keyCode === 27) {
            this.cancel();
        } else if (keyCode === 40 || keyCode === 38) {
            this.changeArrowIndex(keyCode);
            this.executeScroll();
        } else if (keyCode !== 9 && keyCode !== 37 && keyCode !== 39) {
            this.waitForSearch();
        }
    }
    waitForSearch() {
        if (this.searchInterval != null) {
            clearInterval(this.searchInterval);
        }
        const self = this;
        this.searchInterval = setInterval(() => {
            self.search();
            clearInterval(self.searchInterval);
        }, 300);
    }
    changeArrowIndex(keyCode) {
        let resultArrowIndex = this.state.resultArrowIndex;
        if (keyCode === 40) {
            resultArrowIndex += 1;
        } else if (keyCode === 38) {
            resultArrowIndex -=1;
        }

        if (resultArrowIndex < -1) {
            resultArrowIndex = -1;
        }
        if (this.state.resultData != null) {
            if (resultArrowIndex >= this.state.resultData.length) {
                resultArrowIndex = this.state.resultData.length;
            }
        }
        this.setState({resultArrowIndex});
    }
    search() {
        if (!this._isMounted) return;

        this.setState({resultOpen: true, loaded: false});

        const value = this.input.current.value;
        const module = this.props.module;
        let searchFields = ['name'];
        if (this.props.module === 'ProductTemplates') {
            searchFields = ['name', 'vendor_part_num', 'mft_part_num_int', 'ext_nazev'];
        }
        let getFields = ['name', 'id'];
        if (this.props.data.searchFields != undefined) {
            searchFields = this.props.data.searchFields;
            getFields = getFields.concat(searchFields);
        }

        if (value !== '' || value !== null) {
            const searchData = {
                fields: searchFields,
                getFields: getFields,
                value,
                module,
                defaultFilter: this.props.defaultFilter,
            };
            const self = this;
            sAction.quickSearch(searchData, (data) => {
                // pokud neni mounted nebo resultOpen stihlo vypnout napriklad v cancel() nemuzeme menit state
                if (!this._isMounted || !self.state.resultOpen) {
                    return;
                }

                self.setState({
                    resultArrowIndex: -1,
                    resultData: data,
                    resultOpen: true,
                    loaded: true,
                });
            });
        }
    }
    selectItem(item, doUpdate = true, cancelEdit = true) {
        this.setState({
            id: item.id,
            name: item.name,
        });
        let fieldIndex = 0;
        if (this.props.fieldIndex) {
            fieldIndex = this.props.fieldIndex;
        }
        this.props.callback(item, fieldIndex);
        // if (this.props.updateField == true) {
        this.input.current.value = item.name;
        // }
        this.cancel();
    }
    focus() {
        if (this.state.resultData != null) {
            this.setState({
                resultOpen: true,
            });
        }
    }
    openPopupList(selectedActive) {
        const self = this;
        const data = {
            module: this.props.module,
            selectedActive: selectedActive,
            defaultFilter: this.props.defaultFilter,
        };
        sAction.openRelatePopup(data, (returnData) => {
            self.selectItem(returnData);
            sAction.popupHide();
        });
    }
    getSearchResult() {
        let searchResult = null;
        if (this.state.resultOpen === true) {
            if (this.state.loaded) {
                const resultList = [];
                if (this.state.resultData.length < 1) {
                    resultList.push(<div key="noRecords" className="quickSearchNoRecords">
                        <span>{sAction.translate('LBL_NO_MATCHES_FOUND')}</span></div>);
                }
                this.state.resultData.forEach((item, index) => {
                    let itemLabel = item.name;
                    if (this.props.formatSearchItemLabel != null) {
                        itemLabel = this.props.formatSearchItemLabel(item);
                    }

                    let lineClass = 'quickSearchResultLine';
                    if (item.id === this.props.data.id_value) {
                        lineClass += ' select';
                    }

                    let refAttr = {};
                    if ((index - 1) === this.state.resultArrowIndex || (index + 1) === this.state.resultArrowIndex) {
                        refAttr = {
                            ref: this.containerRef,
                        };
                    }

                    if (index === this.state.resultArrowIndex) {
                        lineClass += ' arrowSelect';
                        refAttr = {
                            ref: this.containerRef,
                        };
                    }

                    resultList.push(
                        <div
                            {...refAttr}
                            tabIndex={index}
                            onClick={() => this.selectItem(item)}
                            key={index}
                            className={lineClass}
                        >
                            {itemLabel}
                        </div>,
                    );
                });
                // Musim vynutit styl height auto aby popup nebyl moc maly a zaroven sedelo na vse na radcich
                searchResult = <div style={{height: 'auto'}} className="quickSearchResult">{resultList}</div>;
            } else {
                searchResult = <div style={{height: 'auto'}} className="quickSearchResult"><div className="quickSearchNoRecords">{<Loader/>}</div></div>;
            }
        }

        return searchResult;
    }

    executeScroll = () => {
        if (this.containerRef.current) {
            this.containerRef.current.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'});
        }
    };

    chechChange() {
        if (this.state.value !== this.input.current.value) {
            if (this.props.updateField == true) {
                this.input.current.value = '';
            } else {
                this.props.callback({name: this.input.current.value});
            }
        }
        this.cancel();
    }
    onBlur(e) {
        const relTarget = e.relatedTarget;

        if (relTarget == null) {
            this.chechChange();
        } else if (!(e.relatedTarget.className == 'quickSearchResultLine')) {
            this.chechChange();
        }
    }

    cancel() {
        if (this.searchInterval) {
            clearInterval(this.searchInterval);
        }
        this.setState({
            resultOpen: false,
        });
    }

    componentDidMount() {
        this._isMounted = true;
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.autoFocus && !prevProps.autoFocus) {
            this.input.current.focus();
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        const data = this.props.data;
        let keyAttr = {};
        let selectedActive = false;
        const newRecord = this.props.newRecord;
        const containerClassName = this.props.containerClassName ? this.props.containerClassName : null;
        let containerClass = 'inputEditContainer ' + containerClassName;
        const forceChange = this.props?.forceChange ?? false;

        if (forceChange === true) {
            sAction.dataSet(this.props?.prefix + '/forceChange', false);
        }

        let value = data.value;
        if (newRecord && (value === '' || value === undefined || value === null)) {
            value = '';
        }

        if (this.props.name || this.props.name === '') {
            value = this.props.name;
            selectedActive = true;
            keyAttr = {key: value};
        }

        const searchResult = this.getSearchResult();

        const containerClassField = 'inputContainer relateField';

        if (!containerClass || (containerClass && !containerClass?.includes('relateField'))) {
            containerClass += ' relateField';
        }

        const btnComp = [];
        // buttons={[{className: "detailInfoIcon",  onClick: this.prodInfo(data.id)}]}
        if (this.props.buttons != undefined) {
            this.props.buttons.forEach((btn) => {
                btnComp.push(<div
                    key={btn.id}
                    onClick={() => btn.onClick(btn.params)}
                    className="inputEditButton"
                >
                    <div className={btn.className} />
                </div>);
            });
        }

        return (
            <div className={containerClass} data-fieldname={data.name} style={this.props.containerStyle ? this.props.containerStyle : null}>
                {searchResult}
                <div className={containerClassField}>
                    <InputText
                        {...keyAttr}
                        onKeyDown={(e) => this.onKeyDown(e)}
                        onKeyUp={(event) => this.keyUp(event)}
                        onFocus={() => this.focus()}
                        onBlur={(e) => this.onBlur(e)}
                        myRef={this.input}
                        id={value}
                        defaultValue={value}
                        {...((value !== this.input?.current?.value && forceChange === true) && {value: value})}
                        // value={value}
                        label={this.props.data.label}
                        InputProps={this.props.InputProps}
                        error={this.props.error}
                        autoFocus={this.props.autoFocus}
                        className={this.props.inputClassName ? this.props.inputClassName : null}
                    />
                </div>
                {!newRecord && (
                    <div className="buttonContainer">
                        <div
                            onClick={() => this.openPopupList(selectedActive)}
                            className="inputEditButton openPopupButton"
                        >
                            {!this.props.hideSearchIcon && (<div className={'icon-search relateSearchIcon'} />)}
                        </div>
                        {btnComp}
                    </div>
                )}
            </div>
        );
    }
}
