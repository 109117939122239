export default function accCreateQuote(data) {

  this.detailPredefinedFields = [];
  //nazev firmy
  this.detailPredefinedFields.push({
    type: "relate_simple",
    fieldName: "billing_account_name",
    name: "billing_account_id",
    value: { id: data.id, "name": data.name }
  });

  //faze Nabidky => nova
  this.detailPredefinedFields.push({
    type: "field",
    name: "quote_stage",
    value: "New"
  });
  this.detailNewRecord({
    module: "Quotes",
    way: data.prefix
  });
}
