import sAction from "sAction";
import React, {PureComponent} from "react";


export default class MultirelateView extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const value = []
        if (this.props.data.def.get("options")) {
            const names = this.props.data.def.get("options").get("names")
            const ids = this.props.data.def.get("options").get("id")
            const rel_type = this.props.data.def.get("options").get("rel_type")

            names.forEach((element, index) => {

                value.push(
                    <div key={index} className="multiRelateItem">
                        <div onClick={(e) => this.props.show(e, {
                            module: "Accounts",
                            id: ids.get(index),
                        })} className="icon-eye detailViewFieldIconEye"/>
                        <a href={"#detail/Accounts/" + ids.get(index)}>
                            {element}
                        </a>
                        <div className="relTypeField">{"(" + sAction.translate(rel_type.get(index)) + ")"}</div>
                    </div>)
            })
        }

        return (
            <div>{value ? value : null}</div>
        )
    }

}