export default function currentLocation(data) {
  const parentThis = this;
  if (navigator.geolocation) {
    navigator.permissions.query({ name: 'geolocation' }).then(permission)
  } else {
    this.alert(this.translate('LBL_GEOLOCATE_NOT_SUPPORTED','Accounts'));
  }
  function permission(permission){
    if (permission.state !== 'denied'){
      navigator.geolocation.getCurrentPosition(getPosition);
    }
    else{
      parentThis.alert(parentThis.translate('LBL_GEOLOCATE_BLOCKED','Accounts'));
    }
  }
  function getPosition(position){
    var paramData = {
      x:position.coords.longitude,//16.6049092 , 17.07515972
      y:position.coords.latitude,//49.2004596 , 49.70798138
    }

    parentThis.rest.post("acmAddressByGPS",paramData,AAdata => {
      let street = AAdata.ulice+' '+ AAdata.cislo_dom;
      const msg = '<b>'+parentThis.translate('LBL_FOUND_ADDRESS','Accounts')+'</b> <br>' +
        '<table>' +
          '<tr>' +
            '<td><b>'+parentThis.translate('LBL_BILLING_ADDRESS_STREET','Accounts')+'</b> </td>' +
            '<td>'+street +'<br></td>' +
          '</tr>' +
          '<tr>' +
            '<td><b>'+parentThis.translate('LBL_BILLING_ADDRESS_KRAJ','Accounts')+'</b> </td>' +
            '<td>'+AAdata.kraj +'<br></td>' +
          '</tr>' +
          '<tr>' +
            '<td><b>'+parentThis.translate('LBL_BILLING_ADDRESS_CITY','Accounts')+'&nbsp;&nbsp;</b> </td>' +
            '<td>'+AAdata.obec +'<br></td>' +
          '</tr>' +
          '<tr>' +
            '<td><b>'+parentThis.translate('LBL_BILLING_ADDRESS_POSTALCODE','Accounts')+'</b> </td>' +
            '<td>'+AAdata.psc +'<br></td>' +
          '</tr>' +
        '</table>';
      parentThis.confrim(msg, () => {
        parentThis.dsClear();

          // billing adress
          parentThis.dsAdd("set", data.prefix + "/fields/billing_address_street/value", street);
          parentThis.dsAdd("set", data.prefix + "/changes/fields/billing_address_street", street);

          parentThis.dsAdd("set", data.prefix + "/fields/billing_address_city/value", AAdata.obec);
          parentThis.dsAdd("set", data.prefix + "/changes/fields/billing_address_city", AAdata.obec);

          parentThis.dsAdd("set", data.prefix + "/fields/billing_address_kraj/value", AAdata.kraj);
          parentThis.dsAdd("set", data.prefix + "/changes/fields/billing_address_kraj", AAdata.kraj);

          parentThis.dsAdd("set", data.prefix + "/fields/billing_address_postalcode/value", AAdata.psc);
          parentThis.dsAdd("set", data.prefix + "/changes/fields/billing_address_postalcode", AAdata.psc);

          // acm_address
          parentThis.dsAdd("set", data.prefix + "/fields/acm_address/def/item/gpsX", AAdata.x);
          parentThis.dsAdd("set", data.prefix + "/changes/fields/acm_address_gpsX", AAdata.x);

          parentThis.dsAdd("set", data.prefix + "/fields/acm_address/def/item/gpsY", AAdata.y);
          parentThis.dsAdd("set", data.prefix + "/changes/fields/acm_address_gpsY", AAdata.y);

          parentThis.dsAdd("set", data.prefix + "/fields/acm_address/value", street);
          parentThis.dsAdd("set", data.prefix + "/changes/fields/acm_address_street", street);
          parentThis.dsAdd("set", data.prefix + "/changes/fields/acm_address", street);

          parentThis.dsAdd("set", data.prefix + "/fields/acm_address/def/item/obec", AAdata.obec);
          parentThis.dsAdd("set", data.prefix + "/changes/fields/acm_address_obec", AAdata.obec);

          parentThis.dsAdd("set", data.prefix + "/fields/acm_address/def/item/kraj",AAdata.kraj);
          parentThis.dsAdd("set", data.prefix + "/changes/fields/acm_address_kraj", AAdata.kraj);

          parentThis.dsAdd("set", data.prefix + "/fields/acm_address/def/item/psc", AAdata.psc);
          parentThis.dsAdd("set", data.prefix + "/changes/fields/acm_address_psc", AAdata.psc);

          parentThis.dsAdd("set", data.prefix + "/fields/acm_address/def/item/okres", AAdata.psc);
          parentThis.dsAdd("set", data.prefix + "/changes/fields/acm_address_okres", AAdata.psc);

        parentThis.dsProcess();

        parentThis.popupHide();
      });
    })
  }
}
