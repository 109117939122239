import React, {useState, useEffect} from 'react';
import sAction from 'sAction';
import Loader from '../loader';
import PropTypes from 'prop-types';
import Button from '../formElements/Button';
import InputText from '../formElements/InputText';
import CheckBox from '../formElements/CheckBox';
import MicrosoftButton from '../formElements/MicrosoftButton';

const EmailSettingsOAuth = () => {
    const [loading, setLoading] = useState(false);
    const [oAuthUrl, setOAuthUrl] = useState(null);
    const [oauthSetState, setOauthSetState] = useState('success');
    const [oauthDataSet, setOauthDataSet] = useState(false);
    const [emailDataLoaded, setEmailDataLoaded] = useState(false);
    const [emailTestTo, setEmailTestTo] = useState('');

    const [state, setState] = useState({
        oAuthProviderName: 'Azure',
        oAuthClientId: '',
        oAuthClientSecret: '',
        oAuthTenantId: '',
        oauthDataLoaded: false,
    });

    const [emailState, setEmailState] = useState({
        emailHost: '',
        emailHostInbound: '',
        emailPort: '',
        emailUsername: '',
        emailFromName: '',
        emailUseTLS: true,
        emailUseOAuth: false,
        allowOAuthCompanyProvider: false,
        allowUserSendViaSystemEmail: false,
    });

    useEffect(() => {
        const urlParams = parseUrlParams(window.location.hash);
        const code = urlParams.get('code');
        const respState = urlParams.get('state');
        const sessionState = urlParams.get('session_state');

        getOAuthData();

        if (code && respState) {
            processOAuthRedirect(code, respState, sessionState);
        } else {
            setLoading(false);
        }

        getEmailOAuthData();
        if (oauthDataSet) {
            getOAuthUrl();
        }
    }, []);

    useEffect(() => {
        if (oauthDataSet) {
            getOAuthUrl();
        }
    }, [oauthDataSet]);

    useEffect(() => {
        if (!emailDataLoaded) return;
        // po dvou s neaktivity se ulozi data nastaveni emailu
        const emailDataSaveTimer = setTimeout(() => {
            saveEmailOAuthData();
            sAction.toast({
                name: 'uloženo',
                description: ' ',
            });
        }, 3000);

        return () => {
            clearTimeout(emailDataSaveTimer);
        };
    }, [emailState]);

    const parseUrlParams = (url) => {
        const params = url.split('?')[1];
        const urlParams = new URLSearchParams(params);
        return urlParams;
    };

    const getOAuthData = () => {
        sAction.rest.post('getOAuthData', {}, (returnData) => {
            if (!returnData.status) {
                sAction.error(returnData.errorMessage.text);
                return;
            }
            if (!returnData.message.data.oAuthProviderName) {
                returnData.message.data.oAuthProviderName = 'Azure';
            }

            delete returnData.message.data.oauthSetState;

            returnData.message.data.oauthDataLoaded = true;
            setState(returnData.message.data);

            if (
                returnData.message.data.oAuthClientId &&
                returnData.message.data.oAuthClientSecret &&
                returnData.message.data.oAuthTenantId
            ) {
                setOauthDataSet(true);
            } else {
                setOauthDataSet(false);
            }
        }, false);
    };

    const getOAuthUrl = () => {
        sAction.rest.post('generateAzureOAuthUrl', {}, (returnData) => {
            if (!returnData.status) {
                sAction.error(returnData.errorMessage?.text);
                return;
            }
            if (!returnData.status) {
                sAction.error(returnData.errorMessage?.text);
                return;
            }
            setOAuthUrl(returnData?.message?.data.authUrl);
        }, false );
    };

    const processOAuthRedirect = (code, respState, sessionState) => {
        const data = {
            code: code,
            state: respState,
            session_state: sessionState,
            verify_state: respState, // only temp for testing
        };

        sAction.rest.post('processAzureOAuth', data, (returnData) => {
            if (!returnData.status) {
                setOauthSetState('error');
                switch (returnData.errorMessage.text) {
                    case 'Bad Request':
                        sAction.error('Neplatný token');
                        break;
                    default:
                        sAction.error(returnData.errorMessage.text);
                }
                return;
            }
            setLoading(false);
            // on success change url to remove code and state
            window.history.replaceState({}, document.title,
                window.location.pathname + '#admin/custom/EmailSettingsOAuth',
            );
            setOauthSetState('success');
        },
        false,
        );
    };

    const handleChange = (e, name) => {
        setState({
            ...state,
            [name]: e.target.value,
        });
        setOauthSetState('warning');
    };

    const handleChangeEmail = (e, name) => {
        // bool values
        const boolValues = ['emailUseOAuth', 'emailUseTLS', 'allowOAuthCompanyProvider', 'allowUserSendViaSystemEmail'];
        if (boolValues.includes(name)) {
            setEmailState({
                ...emailState,
                [name]: e,
            });
            return;
        }
        // string values
        setEmailState({
            ...emailState,
            [name]: e.target.value,
        });
        if (name !== 'emailTestTo') {
            setOauthSetState('warning');
        }
    };

    const handleSubmitOAuth = () => {
        setLoading(true);
        sAction.rest.post(
            'saveOAuthData',
            state, (returnData) => {
                setLoading(false);
                if (!returnData.status) {
                    sAction.error(returnData.errorMessage?.text);
                    return;
                }
                getOAuthData();
            },
            false,
        );
    };

    const saveEmailOAuthData = () => {
        sAction.rest.post(
            'saveEmailOAuthData',
            emailState,
            (returnData) => {
                if (!returnData.status) {
                    sAction.error(returnData.errorMessage?.text);
                    return;
                }
                setEmailDataLoaded(false);
                getEmailOAuthData();
            },
            false,
        );
    };

    const getEmailOAuthData = () => {
        sAction.rest.post('getEmailOAuthData', {}, (returnData) => {
            if (!returnData.status) {
                sAction.error(returnData.errorMessage.text);
                return;
            }
            returnData.message.data.emailUseOAuth = !!returnData.message.data.emailUseOAuth;
            returnData.message.data.emailUseTLS = !!returnData.message.data.emailUseTLS;
            returnData.message.data.allowOAuthCompanyProvider = !!returnData.message.data.allowOAuthCompanyProvider;
            setEmailState({
                ...emailState,
                ...returnData.message.data,
            });

            setTimeout(() => {
                setEmailDataLoaded(true);
            }, 500);
        }, false);
    };

    const sendTestEmail = () => {
        setLoading(true);
        try {
            sAction.rest.post('sendTestEmail', {email: emailTestTo}, (returnData) => {
                setLoading(false);
                if (returnData?.code && returnData?.code !== 200) {
                    sAction.error(
                        sAction.translate('LBL_ERROR_EXCEPTION_RESP'),
                        sAction.translate('LBL_ERROR'),
                        true,
                        returnData?.message);
                    return;
                }
                if (!returnData.status) {
                    sAction.error(
                        returnData.errorMessage?.text,
                        sAction.translate('LBL_ERROR'),
                        true,
                        returnData.errorMessage?.data?.error);
                    return;
                }
                sAction.toast({
                    name: sAction.translate('LBL_TEST_MAIL_WAS_SENT'),
                    description: ' ',
                });
            });
        } catch (e) {
            setLoading(false);
            sAction.error(sAction.translate('LBL_ERROR_EXCEPTION_RESP'), sAction.translate('LBL_ERROR'), true, e);
        }
    };

    const authorizeOAuth = () => {
        saveEmailOAuthData();
        window.location.href = oAuthUrl;
    };

    if (loading) {
        return <Loader />;
    }

    const notifyBoxes = [
        {
            tag: 'help_page',
            type: 'info',
            text: sAction.app_strings['LBL_EMAIL_OAUTH_INFO'],
            btnText: sAction.app_strings['LBL_EMAIL_OAUTH_HELP'],
            btnLink: 'https://coripo.com/manual/coripo-manual-outlook-office365.pdf',
        },
    ];

    return (
        <div className="customReportContainer">
            <div className="customReport adminEmailOAuth">
                <div className="customReportHeader adminEmailOAuthHeaderFlex">
                    <div className="headerIconEmailOAuth">
                        <i className="iconfa-emailOAuthAdmin"></i>
                    </div>
                    <h2 className="adminEmailOAuthHeader">
                        {sAction.app_strings['LBL_EMAIL_OAUTH_SETTINGS']}
                    </h2>
                </div>

                <div className="customReportContent">
                    <div className="oauthSettingsGrid">
                        <div className="oauthParamsForm">
                            <div className="authParamsHeader">
                                <img
                                    src="img/Microsoft-Azure-Logo.png"
                                    alt="Azure OAuth"
                                    className="azureLogo"
                                />
                                <a href="https://coripo.com/manual/coripo-manual-outlook-office365.pdf" target='_blank' rel="noreferrer">
                                    <span className="iconfa-help"></span>
                                </a>
                            </div>
                            {state.oauthDataLoaded ? (
                                <>
                                    <InputText
                                        required
                                        id="oAuthClientId"
                                        label={sAction.translate(
                                            'LBL_EMAIL_SETTINGS_OAUTH_CLIENT_ID',
                                        )}
                                        type="text"
                                        name="oAuthClientId"
                                        value={state.oAuthClientId}
                                        onChange={(e) =>
                                            handleChange(e, 'oAuthClientId')
                                        }
                                    />
                                    <InputText
                                        required
                                        id="oAuthClientSecret"
                                        label={sAction.translate(
                                            'LBL_EMAIL_SETTINGS_OAUTH_CLIENT_SECRET',
                                        )}
                                        type="text"
                                        name="oAuthClientSecret"
                                        value={state.oAuthClientSecret}
                                        onChange={(e) =>
                                            handleChange(e, 'oAuthClientSecret')
                                        }
                                    />
                                    <InputText
                                        required
                                        id="oAuthTenantId"
                                        label={sAction.translate(
                                            'LBL_EMAIL_SETTINGS_OAUTH_TENANT_ID',
                                        )}
                                        type="text"
                                        name="oAuthTenantId"
                                        value={state.oAuthTenantId}
                                        onChange={(e) =>
                                            handleChange(e, 'oAuthTenantId')
                                        }
                                    />
                                    <Button
                                        className="btn btn-primary emailOAuthSaveBtn"
                                        onClick={handleSubmitOAuth}
                                    >
                                        {sAction.translate(
                                            'LBL_EMAIL_SETTINGS_OAUTH_SAVE',
                                        )}
                                    </Button>
                                </>
                            ) : (
                                <Loader />
                            )}
                        </div>
                        <div className={'oauthSignInForm' + (!oauthDataSet ? ' inactive' : '')} >
                            <p className="headerEmailSettings">
                                Nastavení emailu
                            </p>

                            <InputText
                                required
                                id="emailHost"
                                label={sAction.translate(
                                    'LBL_EMAIL_SETTINGS_OAUTH_EMAIL_HOST',
                                )}
                                type="text"
                                name="emailHost"
                                autoComplete="off"
                                value={emailState.emailHost}
                                onChange={(e) =>
                                    handleChangeEmail(e, 'emailHost')
                                }
                            />

                            <InputText
                                required
                                id="emailHostInbound"
                                label={sAction.translate(
                                    'LBL_EMAIL_SETTINGS_OAUTH_EMAIL_HOST_INBOUND',
                                )}
                                type="text"
                                name="emailHostInbound"
                                autoComplete="off"
                                value={emailState.emailHostInbound}
                                onChange={(e) =>
                                    handleChangeEmail(e, 'emailHostInbound')
                                }
                            />

                            <InputText
                                required
                                id="emailPort"
                                label={sAction.translate(
                                    'LBL_EMAIL_SETTINGS_OAUTH_EMAIL_PORT',
                                )}
                                type="text"
                                name="emailPort"
                                autoComplete="off"
                                value={emailState.emailPort}
                                onChange={(e) =>
                                    handleChangeEmail(e, 'emailPort')
                                }
                            />

                            <InputText
                                required
                                id="emailUsername"
                                label={sAction.translate(
                                    'LBL_EMAIL_SETTINGS_OAUTH_EMAIL_ADDRESS',
                                )}
                                type="text"
                                name="emailUsername"
                                autoComplete="off"
                                value={emailState.emailUsername}
                                onChange={(e) =>
                                    handleChangeEmail(e, 'emailUsername')
                                }
                            />

                            <InputText
                                required
                                id="emailFromName"
                                label={sAction.translate(
                                    'LBL_EMAIL_SETTINGS_OAUTH_EMAIL_FROM_NAME',
                                )}
                                type="text"
                                name="emailFromName"
                                autoComplete="off"
                                value={emailState.emailFromName}
                                onChange={(e) =>
                                    handleChangeEmail(e, 'emailFromName')
                                }
                            />
                            <br />
                            <div className="checkboxLine"
                                title={sAction.translate(
                                    'LBL_EMAIL_SETTINGS_OAUTH_EMAIL_USE_TSL',
                                )}>
                                <CheckBox
                                    id="emailUseTLS"
                                    defaultValue={emailState.emailUseTLS ? 1 : 0}
                                    onChange={(e) =>handleChangeEmail(e, 'emailUseTLS')}
                                />
                                <p className="controlLabel">
                                    {sAction.translate(
                                        'LBL_EMAIL_SETTINGS_OAUTH_EMAIL_USE_TSL',
                                    )}
                                </p>
                            </div>

                            <div className="checkboxLine"
                                title={sAction.translate(
                                    'LBL_EMAIL_SETTINGS_OAUTH_EMAIL_USE_OAUTH',
                                )}>
                                <CheckBox
                                    id="emailUseOAuth"
                                    defaultValue={emailState.emailUseOAuth ? 1 : 0}
                                    onChange={(e) => handleChangeEmail(e, 'emailUseOAuth')}
                                />
                                <p className="controlLabel">
                                    {sAction.translate('LBL_EMAIL_SETTINGS_OAUTH_EMAIL_USE_OAUTH')}
                                </p>
                            </div>

                            <div className="checkboxLine"
                                title={sAction.translate(
                                    'LBL_EMAIL_SETTINGS_OAUTH_ALLOW_COMPANY_PROVIDER',
                                )}>
                                <CheckBox
                                    id="allowOAuthCompanyProvider"
                                    defaultValue={emailState.allowOAuthCompanyProvider ? 1 : 0}
                                    onChange={(e) => handleChangeEmail(e, 'allowOAuthCompanyProvider')}
                                />
                                <p className="controlLabel">
                                    {sAction.translate('LBL_EMAIL_SETTINGS_OAUTH_ALLOW_COMPANY_PROVIDER')}
                                </p>
                            </div>

                            <div className="checkboxLine"
                                title={sAction.translate(
                                    'LBL_EMAIL_SETTINGS_OAUTH_ALLOW_SEND_VIA_SYSTEM',
                                )}>
                                <CheckBox
                                    id="allowUserSendViaSystemEmail"
                                    defaultValue={emailState.allowUserSendViaSystemEmail ? 1 : 0}
                                    onChange={(e) => handleChangeEmail(e, 'allowUserSendViaSystemEmail')}
                                />
                                <p className="controlLabel">
                                    {sAction.translate('LBL_EMAIL_SETTINGS_OAUTH_ALLOW_SEND_VIA_SYSTEM')}
                                </p>
                            </div>

                            <div className="btnSignInWithMicrosoftContainer">
                                <MicrosoftButton
                                    label={sAction.translate('LBL_EMAIL_SETTINGS_OAUTH_LOGIN')}
                                    onClick={authorizeOAuth}
                                    className={!oAuthUrl ? 'inactive' : ''}
                                />

                                {oauthSetState === 'success' &&
                                    <span className="oauthSetStatus iconfa-successOAuth"></span>}
                                {oauthSetState === 'warning' &&
                                    <span className="oauthSetStatus iconfas-warningOAuth" title="Je nutné opakovat přihlášení"></span>}
                                {oauthSetState === 'error' &&
                                    <span className="oauthSetStatus iconfa-errorOAuth" title="Nastala chyba"></span>}
                            </div>

                            <p>{!oauthDataSet ? <>
                                <br/>
                                {sAction.translate('LBL_EMAIL_SETTINGS_DISABLED_NOTE')}
                            </> : ''}</p>
                        </div>

                        <div className="oauthEmailTestForm">
                            <p className="headerEmailSettings">
                                {sAction.translate('LBL_EMAIL_SETTINGS_OAUTH_EMAIL_TEST')}
                            </p>

                            <div className="testMailContainer">
                                <InputText
                                    required
                                    id="emailTestTo"
                                    label={sAction.translate('LBL_EMAIL_SETTINGS_OAUTH_EMAIL_TEST_MAILTO')}
                                    type="text"
                                    name="emailTestTo"
                                    autoComplete="off"
                                    defaultValue={emailTestTo}
                                    onChange={(e) => setEmailTestTo(e.target.value)}
                                />

                                <Button className="btn btn-primary" onClick={sendTestEmail}>
                                    {sAction.translate('LBL_EMAIL_SETTINGS_OAUTH_EMAIL_TEST_SEND')}
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div className='notifyContainer'>
                        {notifyBoxes.map((notifyBox) => {
                            return (
                                <div className='notifyBox' key={notifyBox.tag}>
                                    <div className={'notifyBoxContent' + (notifyBox.btnText ? ' gridForBtn' : '')}>
                                        <p>{notifyBox.text}</p>
                                        <Button
                                            className='btn btn-primary'
                                            href={notifyBox.btnLink}
                                            target='_blank' >
                                            {notifyBox.btnText}
                                        </Button>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                </div>
            </div>
        </div>
    );
};

EmailSettingsOAuth.propTypes = {
    resetButtons: PropTypes.func.isRequired,
    addButton: PropTypes.func.isRequired,
};

export default EmailSettingsOAuth;
