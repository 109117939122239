import React from "react";
import FilterBasic from "./filterBasic";
import sAction from "sAction";
import TabContainer from "../../../components/formElements/TabContainer";
import MultiSelect from "../../../components/formElements/MultiSelect";
import Loader from "../../../components/loader";


export default class filterMultirelate extends FilterBasic {
    constructor(props) {
        super(props);
        let operatorTab = 0
            if (this.props.actFilter?.get("operator") === "or") {
                operatorTab = 1
            }
        this.state = {
            operatorTab,
            loaded: false,
            accountOptions: [],
        }

    }

    componentDidMount() {
        sAction.rest.post("createMultirelateFilter", this.props.data?.get("name"), returnData => {
            let tmp = [];
            if(!returnData.status){
                console.log(returnData.errorMessage)
                this.setState({accountOptions: tmp, loaded: true})
                return
            }
            returnData.message.data.forEachObject((account, accountId) => {
                tmp.push({value: accountId, label: account});
            })
            this.setState({accountOptions: tmp, loaded: true})
        }, false)
    }

    componentDidUpdate() {
        const actFilter = this.props.actFilter;
        if (actFilter == null || actFilter.actCustom === false) {
            this.props.parent.normal();
        } else {
            this.props.parent.advanced();
        }
    }
    //Nefunguje razeni od A do Z
    multiEnumValue(values) {
        const data = this.props.data

        if (this.state.operatorTab === 0) {
            this.addFilter(data.get("name"), 'contLikeArray', values)
            return
        }

        const prefix = this.props.prefix;
        let filters = [];
        values.forEach(value => {
            filters.push({
                field: data?.get("name"),
                type: "cont",
                value: value
            })
        })
        const params = {
            field: data?.get("name"),
            filters: filters,
            operator: "or",
            prefix,
            reload: true
        };
        sAction.addFilter(params);

    }

    render() {
        if (!this.state.loaded) {
            return (<Loader/>)
        }
        const data = this.props.data;

        let defaultValue = null;
        if (this.props.actFilter != null) {
            if (this.props.actFilter.get("operator") === "or") {
                defaultValue = []
                this.props.actFilter.get("filters").forEach(filter => {
                    defaultValue.push(filter.get("value"))
                })
            } else {
                defaultValue = this.props.actFilter.filters.get(0).value;
            }

        }


        const custom = [
            <div className="filterEnumSelectContainer" key="select">
                {data.get("type") === "multirelate" && (
                    <TabContainer tabs={["AND", "OR"]} value={this.state.operatorTab}
                                  onChange={(e, value) => this.setState({operatorTab: value})}
                    />
                )}
                <MultiSelect
                    ref="input"
                    id="filler"
                    options={this.state.accountOptions}
                    defaultValue={defaultValue}
                    open={false}
                    valueFromProps={true}
                    onBlur={values => this.multiEnumValue(values)}
                />
            </div>
        ];

        let removeFilterButton = null;
        if (this.props.actFilter != null) {
            removeFilterButton = (
                <div onClick={() => this.removeFilter(data.get("name"))} className="filterButton cancelFilterButton">
                    {sAction.translate("LBL_DELETE_FILTER")}
                </div>
            );
        }
        return (
            <div>
                <div onClick={() => this.order(data, 1)} className="filterButton">
                    {sAction.translate("LBL_ORDER_AZ")}
                </div>
                <div onClick={() => this.order(data, 0)} className="filterButton">
                    {sAction.translate("LBL_ORDER_ZA")}
                </div>
                <div onClick={() => this.addFilter(data.get("name"), "nnull", null)} className="filterButton">
                    {sAction.translate("LBL_CONTAINS_DATA")}
                </div>
                <div onClick={() => this.addFilter(data.get("name"), "null", null)} className="filterButton">
                    {sAction.translate("LBL_NOT_CONTAINS_DATA")}
                </div>
                {custom}
                {removeFilterButton}
            </div>
        );
    }
}