import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import sAction from 'sAction';
import InputText from '../../formElements/InputText';
import MicrosoftButton from '../../formElements/MicrosoftButton';
import Loader from '../../loader';

export default function EmailOAuthAddAccount(props) {
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        loading: false,
        errors: [],
        success: false,
        message: '',
    });
    const [oauthSetState, setOauthSetState] = useState(''); // success, warning, error
    const [settings, setSettings] = useState({
        name: '',
        email: '',
    });

    useEffect(() => {
        if (props.oeId || props.ieId) {
            sAction.rest.post('getOAuthEmailSettings', {oeId: props.oeId, ieId: props.ieId}, (response) => {
                if (response.status) {
                    setSettings({
                        ...settings,
                        name: response?.message?.data?.name,
                        email: response?.message?.data?.email,
                    });
                }
            }, false);
        }

        if (props.oAuthData) {
            processOAuthAuthorization(props.oAuthData);
        }
    }, []);

    const handleChange = (e, name) => {
        setSettings({
            ...settings,
            [name]: e.target.value,
        });
        setOauthSetState('warning');
    };

    const authorizeOAuth = () => {
        setLoading(true);

        sAction.rest.post('saveAndGenerateAzureOAuthUrl', {
            name: settings.name,
            email: settings.email,
            oeId: props.oeId,
            ieId: props.ieId,
            outboundOnly: props.outboundOnly,
        }, (response) => {
            if (response.status) {
                // redirect to azure url
                window.location.href = response.message?.data?.authUrl;
            } else {
                setLoading(false);
                setOauthSetState('error');
                setState({
                    ...state,
                    errors: response.errorMessage,
                });
                sAction.toast({
                    name: sAction.translate('LBL_EMAIL_SETTINGS_OAUTH_ERROR'),
                    description: response.errorMessage.text,
                });
            }
        }, false);
    };

    const processOAuthAuthorization = (oAuthData) => {
        setLoading(true);

        sAction.rest.post('processAzureOAuthUser', {
            code: oAuthData.code,
            state: oAuthData.respState,
            session_state: oAuthData.sessionState,
            oeId: props.oeId,
            ieId: props.ieId,
            outboundOnly: props.outboundOnly,
        }, (response) => {
            setLoading(false);

            if (response.status) {
                setOauthSetState('success');
                setState({
                    ...state,
                    success: true,
                });

                setTimeout(() => {
                    sAction.popupHide();
                    sAction.toast({
                        name: sAction.translate('LBL_EMAIL_SETTINGS_OAUTH_LOGIN_SUCCESS'),
                        description: sAction.translate('LBL_EMAIL_SETTINGS_OAUTH_LOGIN_SUCCESS_L2'),
                    });
                    window.location.href = window.location.href.split('?')[0];
                    if (props.outboundOnly === false || (props.oeId.length > 0 && props.ieId.length > 0)) {
                        setTimeout(() => {
                            window.location.reload();
                        }, 2000);
                    }
                }, 2000);
            } else {
                setOauthSetState('error');
                setState({
                    ...state,
                    errors: response.errorMessage,
                });
                sAction.toast({
                    name: sAction.translate('LBL_EMAIL_SETTINGS_OAUTH_ERROR'),
                    description: response.errorMessage.text,
                });
            }
        }, false);
    };

    return (
        <div className="emailOAuthAddAccount">
            {props.isPopup && (
                <div className="acmPopupHeader">
                    <div className="acmPopupHeader__title">{sAction.translate('LBL_EMAIL_SETTINGS_OAUTH_LOGIN_COMPANY')}</div>
                </div>
            )}

            <div className="emailOAuthAddAccount__content">
                {loading === false ? <div className='contentWrapper'>

                    <div className="authParamsHeader">
                        <img
                            src="img/Microsoft-Azure-Logo.png"
                            // src="img/Microsoft-Office-365-Logo.png"
                            alt="Azure OAuth"
                            className="azureLogo"
                        />

                    </div>

                    <InputText
                        required
                        id="name"
                        label={sAction.translate('LBL_EMAIL_SETTINGS_OAUTH_USER_NAME')}
                        type="name"
                        name="name"
                        defaultValue={settings.name}
                        value={settings.name}
                        onChange={(e) =>
                            handleChange(e, 'name')
                        }
                    />
                    <InputText
                        required
                        id="email"
                        label={sAction.translate('LBL_EMAIL_SETTINGS_OAUTH_USER_EMAIL')}
                        type="email"
                        name="email"
                        defaultValue={settings.email}
                        value={settings.email}
                        onChange={(e) =>
                            handleChange(e, 'email')
                        }
                    />
                    <br />

                    <div className="btnSignInWithMicrosoftContainer">
                        <MicrosoftButton label={sAction.translate('LBL_EMAIL_SETTINGS_OAUTH_LOGIN')} onClick={authorizeOAuth} />

                        {oauthSetState === 'success' &&
                            <span className="oauthSetStatus iconfa-successOAuth"></span>}
                        {oauthSetState === 'warning' &&
                            <span className="oauthSetStatus iconfas-warningOAuth" title="Je nutné přihlášení"></span>}
                        {oauthSetState === 'error' &&
                            <span className="oauthSetStatus iconfa-errorOAuth" title="Nastala chyba"></span>}
                    </div>

                    {state.success && state.message && (
                        <p className='successMessage'>{state.message}</p>
                    )}

                    {state.error && state.success === false && (
                        <p className='errorMessage'>{state.error}</p>
                    )}

                </div> : <Loader /> }
            </div>
        </div>
    );
}

EmailOAuthAddAccount.propTypes = {
    isPopup: PropTypes.bool,
    oAuthData: PropTypes.any,
    oeId: PropTypes.string,
    ieId: PropTypes.string,
    outboundOnly: PropTypes.bool,
};
