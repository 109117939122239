import React from "react";

export default function CustomTooltip(props) {
        return (
            <div className="Tooltip-Wrapper">
                {props.children}
                    <div className={`Tooltip-Tip ${props.direction || "top"}`}>
                        {props.content}
                    </div>
            </div>
        );
}