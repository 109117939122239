import PropTypes from 'prop-types';
import React from 'react';
import Button from './Button';

export default function MicrosoftButton(props) {
    return (
        <Button
            className={'btn btn-primary signInWithMicrosoftBtn ' + props.className}
            onClick={props.onClick}
        >
            <span className="imgMsLogin">
                <img src="img/microsoft365_logo.png" />
            </span>
            {props.label}
        </Button>
    );
}

MicrosoftButton.propTypes = {
    label: PropTypes.any,
    onClick: PropTypes.any,
    className: PropTypes.string,
};
