/**
 * pokud v historii jsou dva zaznamy ruznuch modulu chci se vratit na predchozi zaznam
 * v storu je vzdy ulezene cela url proto muzu po rozporasovani pristoupit na 4 pozici, pozice modulu
 * @returns {string}
 */
export default function checkPreviousUrl(relType, module) {
    let shouldGoBack = true
    let historyUrls = this.dataGet("history/urls")?.slice(-2);

    if(historyUrls?.size <= 1){
        return window.location.href.split('#')[0] + "#list/" + module
    }

    if (relType && relType === "child") {
        return historyUrls.get(0)
    }

    let previousUrl = ''
    if (historyUrls) {
        historyUrls.forEach(historyUrl => {
            const currentUrl = historyUrl.split('/')
            if (currentUrl[4] && previousUrl[4] === currentUrl[4]) {
                shouldGoBack = false;
                return;
            }
            previousUrl = currentUrl;
        })
    }

    this.dataSet("history/urls", this.dataGet("history/urls").pop())

    if(shouldGoBack){
        return historyUrls.get(0)
    }

    return window.location.href.split('#')[0] + "#list/" + module
}

