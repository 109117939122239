import React, {PureComponent} from "react";
import sAction from "sAction";
import TickBox from "ROOT/src/components/formElements/TickBox";
import InputText from "../../formElements/InputText";
import TabContainer from "ROOT/src/components/formElements/TabContainer";

/**
 * @parent src/components/RightPanel/RightPanel.js
 * @props activeTab, checkedFields, fieldName, products, productsInfo, savedFields
 */
export default class RightPanelMultiEnum extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            productsInfo: [],
            products: [],
            tabs: [],
            tabsKeys: [],
            activeTab: 0,
            productsFilter: "",
            productsInfoFilter: "",
        }

        this.allProductInfo = props.data
        this.RIGHTSIDE = "rightEnum"
        this.LEFTSIDE = "leftEnum"
    }

    componentDidMount() {
        this.setSavedFields()
        this.makeAllTabs()
        this.makeProductsList()
        this.makeProductsInfoList()

    }

    /**
     * saves checked field to store or removes unchecked to store
     * @param key key of product or product info
     * @param enumSide either "leftEnum" or "rightEnum"
     * @param checked bool
     * @param index index of productinfo
     */
    saveField(key, enumSide, checked, index) {
        let wholeList = sAction.dataGet(`rightPanel/data/checkedFields/${enumSide}`).toJS()

        if (enumSide === "rightEnum") {
            if (checked) {
                for (const listKey of Object.keys(wholeList)) {
                    if (listKey === key) {
                        wholeList[key].push(index)
                        this.setList(wholeList, enumSide)
                        return;
                    }
                }

                wholeList[key] = [index]
                this.setList(wholeList, enumSide)
            } else {
                for (const listKey of Object.keys(wholeList)) {
                    if (listKey === key) {
                        wholeList[listKey] = wholeList[listKey].filter(element => element !== index)
                        this.setList(wholeList, enumSide)
                    }
                }
            }

        } else {
            if (checked) {
                wholeList.push(key)
                this.setList(wholeList, enumSide)
            } else {
                wholeList = wholeList.filter(element => element !== key)
                this.setList(wholeList, enumSide)
            }
        }
    }

    /**
     * saves list containing checked values to store
     * @param list object containing checked values
     * @param side either "leftEnum" or "rightEnum"
     */
    setList(list, side) {
        sAction.dsClear()
        sAction.dsAdd("set", `rightPanel/data/checkedFields/${side}`, list);
        sAction.dsProcess()
    }

    /**
     * sets value of filter input to state and rerenders products
     * @param value
     */
    filterProducts(value) {
        value = value.toLowerCase()
        clearTimeout(this.interval);
        this.interval = setTimeout(() => {
            this.setState({productsFilter: value});
            this.makeProductsList()
        }, 30);
    }

    /**
     * sets value of filter input to state and rerenders productsInfo
     * @param value
     */
    filterProductsInfo(value) {
        clearTimeout(this.interval);
        this.interval = setTimeout(() => {
            this.setState({productsInfoFilter: value});
            this.makeProductsInfoList()
        }, 30);
    }

    /**
     * Sets parsed checked fields from database and saves to store
     */
    setSavedFields() {
        const fieldName = sAction.dataGet("rightPanel/data/fieldName")
        const savedFields = this.allProductInfo.get?.("savedFields").get?.(fieldName)
        let customProductsData = sAction.dataGet("view/customData/products")

        if (fieldName?.includes("uses")) {
            customProductsData = customProductsData.get?.("productsUses");
        } else if (fieldName.includes("takes")) {
            customProductsData = customProductsData.get?.("productsTakes")
        }

        if (savedFields || customProductsData) {
            let decodedSavedFields = null
            if (savedFields) {
                decodedSavedFields = JSON.parse(savedFields)
            }

            if (customProductsData) {
                decodedSavedFields = this.mergeWithProductsData(decodedSavedFields, customProductsData)
            }

            let wholeList = sAction.dataGet('rightPanel/data/checkedFields').toJS()
            if (Array.isArray(decodedSavedFields?.rightEnum) && decodedSavedFields?.rightEnum.length === 0) {
                wholeList.rightEnum = {}
            } else {
                wholeList.rightEnum = decodedSavedFields?.rightEnum
            }
            wholeList.leftEnum = decodedSavedFields?.leftEnum

            sAction.dsClear()
            sAction.dsAdd("set", `rightPanel/data/checkedFields`, wholeList);
            sAction.dsProcess()
        }
    }

    /**
     * Creates products/leftEnum fields based on selected tab and filter
     */
    makeProductsList() {
        this.state.products = []
        let productsToRender = []
        const checkedFields = sAction.dataGet("rightPanel/data/checkedFields")

        if (this.state.productsFilter) {
            this.allProductInfo.get("products").map((product, key) => {
                if (this.filterList("productsFilter", product)) {
                    this.pushToProductList(productsToRender, key, product, this.LEFTSIDE, checkedFields)
                }
            })
        } else {
            this.allProductInfo.get("products").map((product, key) => {
                this.pushToProductList(productsToRender, key, product, this.LEFTSIDE, checkedFields)
            })
        }

        if (productsToRender) {
            productsToRender.sort((a, b) => {
                return a.key.localeCompare(b.key)
            });
        }

        this.setState({products: [...this.state.products, productsToRender]})
    }

    /**
     * Creates productsInfo/rightEnum fields based on selected tab and filter
     */
    makeProductsInfoList() {
        const activeTab = sAction.dataGet("rightPanel/data/activeTab/fieldKey")
        const checkedFields = sAction.dataGet("rightPanel/data/checkedFields")
        let productsInfoToRender = []
        this.state.productsInfo = []

        if (activeTab) {
            if (this.state.productsInfoFilter) {
                this.allProductInfo.get("productsInfo").get(activeTab).map((productInfo, index) => {
                    if (this.filterList("productsInfoFilter", productInfo.toLowerCase())) {
                        this.pushToProductList(productsInfoToRender, activeTab, productInfo, this.RIGHTSIDE, checkedFields, index)
                    }
                })
            } else {
                if (this.allProductInfo.get("productsInfo").get(activeTab)) {
                    this.allProductInfo.get("productsInfo").get(activeTab).map((productInfo, index) => {
                        if (this.filterList("productsInfoFilter", productInfo.toLowerCase())) {
                            this.pushToProductList(productsInfoToRender, activeTab, productInfo, this.RIGHTSIDE, checkedFields, index)
                        }
                    })
                }
            }
        }
        this.setState({productsInfo: [...this.state.productsInfo, productsInfoToRender]})
    }

    /**
     * checks if value is checked gets event based on enumSide and pushes div with tickbox to array
     * @param productsToRender array which will be rendered
     * @param key product key
     * @param value product value
     * @param enumSide either "leftEnum" or "rightEnum"
     * @param checkedFields all checked fields from store
     * @param index index of productInfo that will be saved to store
     */
    pushToProductList(productsToRender, key, value, enumSide, checkedFields, index) {
        let checked = this.isChecked(enumSide, key, index, checkedFields)
        let renderIcon = null
        let className = null
        let tickBoxEvent
        if (enumSide === "leftEnum") {
            let iconEvent = this.makeEvent(enumSide, "icon", checked, key, index)
            tickBoxEvent = this.makeEvent(enumSide, "tickBox", checked, key, index)
            renderIcon = (<div className="icon-showProductsInfo productsInfoButton" onClick={iconEvent}/>)
            className = "productsRowRpe"
        } else {
            tickBoxEvent = this.makeEvent(enumSide, "", checked, key, index)
        }


        productsToRender.push(<div className={className} key={value}>
            <div className="productItem">
                <TickBox key={key} checked={checked}
                         onClick={tickBoxEvent}/>
                {value}
            </div>
            {renderIcon}
        </div>)
    }

    /**
     * Creates event based on enumSide and element. Icon and tickbox needs to have a different events
     * @param enumSide either "leftEnum" or "rightEnum"
     * @param whichEvent either "icon" or "tickBox"
     * @param checked if field is checked or not
     * @param key key of product
     * @param index index of product in list
     * @returns function event to be binded to onclick event on icon or tickbox
     */
    makeEvent(enumSide, whichEvent, checked, key, index) {
        let event = null
        if (enumSide === "leftEnum") {
            if (whichEvent === "icon") {
                event = () => {
                    this.makeAllTabs(key);
                    this.makeProductsList()
                    this.makeProductsInfoList()
                }
                if (!checked) {
                    event = () => {
                        this.saveField(key, enumSide, true, index);
                        this.makeAllTabs(key);
                        this.makeProductsList()
                        this.makeProductsInfoList()
                    }
                }
            } else if (whichEvent === "tickBox") {
                let tmpKey = null
                if (!checked) {
                    tmpKey = key
                }
                event = (e) => {
                    this.saveField(key, enumSide, e.target.checked, index);
                    this.makeAllTabs(tmpKey)
                    this.makeProductsList()
                    this.makeProductsInfoList()
                }
            }
        } else if (enumSide === "rightEnum") {
            event = (e) => {
                this.saveField(key, enumSide, e.target.checked, index)
                this.makeProductsInfoList()
            }
        } else {
            console.error("enumSide not provided check arguments")
        }

        return event
    }

    /**
     * filters values based on enum side and value
     * @param whatToFilter either "productsInfoFilter" or "productsFilter"
     * @param valueToFilter value to check
     * @returns {boolean}
     */
    filterList(whatToFilter = null, valueToFilter) {
        if (whatToFilter) {
            valueToFilter = valueToFilter.toLowerCase()
            return valueToFilter.includes(this.state[whatToFilter])
        }
        return false
    }

    /**
     * Check if field with value is already in checkedFields and return true or false based on result
     * !! operator needs to be used to check undefined with && operator.
     * @param enumSide either "leftEnum" or "rightEnum"
     * @param keyToCheck productsInfo or product key
     * @param productIndex index of product info in arr
     * @param checkedFields all checked fields
     * @returns {boolean}
     */
    isChecked(enumSide, keyToCheck, productIndex, checkedFields) {
        let productInfoList = null
        if (checkedFields) {
            productInfoList = checkedFields.get(enumSide)
        }
        if (enumSide === this.LEFTSIDE) {
            return !!productInfoList && !!productInfoList.includes(keyToCheck);
        } else if (enumSide === this.RIGHTSIDE) {
            return !!productInfoList && !!productInfoList.get(keyToCheck) && !!productInfoList.get(keyToCheck).includes(productIndex);
        }
    }

    /**
     * fills arrays with values and keys and changes stave of tbs and tabs keys to sorted array.
     */
    makeAllTabs(key = null) {
        let tabs = []
        let tabsKeys = []
        const checkedFieldKeys = sAction.dataGet("rightPanel/data/checkedFields/leftEnum")
        let activeTab = this.allProductInfo.get("products").keySeq().first()
        let activeTabIndex = 0;
        if (checkedFieldKeys && checkedFieldKeys.size > 0) {
            if (key) {
                activeTab = key
            }
            checkedFieldKeys.forEach(productKey => {
                tabs.push(this.allProductInfo.get("products").get(productKey))
                tabsKeys.push(productKey)
            })
        } else {
            this.allProductInfo.get("products").map((product, key) => {
                tabs.push(product)
                tabsKeys.push(key)
            })
        }

        sAction.dataSet("rightPanel/data/activeTab/fieldKey", activeTab)

        const sortedArrays = this.parallelSort([tabs, tabsKeys])

        if (sortedArrays[1].indexOf(key) > -1) {
            activeTabIndex = sortedArrays[1].indexOf(key)
        }

        this.setState({
            tabs: sortedArrays[0],
            tabsKeys: sortedArrays[1],
            activeTab: activeTabIndex
        })

    }

    /**
     *  Sorts two arrays the same way based on one. Maps one array to another based on index
     * @param arrays two arrays to be sorted
     * @param comparator
     * @returns array two sorted arrays in format { 0: sortedValues 1: sortedKeys}
     */
    parallelSort(arrays, comparator = (a, b) => (a < b) ? -1 : (a > b) ? 1 : 0) {
        let arrayKeys = Object.keys(arrays);
        let sortableArray = Object.values(arrays)[0];
        let indexes = Object.keys(sortableArray);
        let sortedIndexes = indexes.sort((a, b) => comparator(sortableArray[a], sortableArray[b]));

        let sortByIndexes = (array, sortedIndexes) => sortedIndexes.map(sortedIndex => array[sortedIndex]);

        return arrayKeys.map(arrayIndex => sortByIndexes(arrays[arrayIndex], sortedIndexes));

    }

    /**
     * changes active tab field key clears filter and calls rerender of product list
     * @param tabIndex
     * @param keyToFind
     */
    changeFocus(tabIndex, keyToFind = null) {
        this.state.productsInfo = []
        this.state.productsInfoFilter = ""
        this.setState({activeTab: tabIndex});

        sAction.dataSet("rightPanel/data/activeTab/fieldKey", keyToFind)
        this.makeProductsInfoList()

    }

    /**
     *
     * @param decodedSavedFields
     * @param customProductsData
     * @returns {*}
     */
    mergeWithProductsData(decodedSavedFields, customProductsData) {
        if (!decodedSavedFields) {
            decodedSavedFields = {
                leftEnum: [],
                rightEnum: {},
            }
        }

        customProductsData?.get("leftEnum").forEach((value, key) => {
            if (!decodedSavedFields.leftEnum.includes(key)) {
                decodedSavedFields.leftEnum.push(key)
            }
        })

        let newIndicies = {};

        this.allProductInfo?.get("productsInfo")?.map((productInfo, productKey) => {
            customProductsData?.get("rightEnum").forEach((customDataInfo, customDataKey) => {
                if (productKey === customDataKey) {
                    newIndicies[productKey] = this.findProductInfoIndicies(productInfo, customDataInfo)
                }
            })
        })

        for (const [key, indicies] of Object.entries(newIndicies)) {
            decodedSavedFields.rightEnum[key] = indicies;
        }

        return decodedSavedFields
    }

    /**
     * @param productInfoList
     * @param customDataInfo
     * @returns {*[]}
     */
    findProductInfoIndicies(productInfoList, customDataInfo) {
        let newIndicies = [];

        productInfoList.forEach((value, index) => {
            customDataInfo.forEach(customValue => {
                if (customValue === value) {
                    newIndicies.push(index)
                }
            })
        })

        return newIndicies;
    }

    render() {
        return (
            <>
                <div className="rightPanelEnumContent">
                    <div className="leftEnumContainer">
                        <div className="searchContainer">
                            <div className="selectInput">
                                <InputText
                                    onChange={(e) => this.filterProducts(e.target.value)}
                                    label={sAction.translate("LBL_REPORT_INPUT_FILTER")}
                                    value={this.state.productsFilter}
                                />
                            </div>
                        </div>
                        <div className="leftEnumContent">
                            {this.state.products}
                        </div>
                    </div>
                    <div className="rightEnumContainer">
                        {this.state.tabs ?
                            <div className="productsTabsContainer">
                                <TabContainer
                                    scrollable={"true"}
                                    tabs={this.state.tabs}
                                    onChange={(e, value) => this.changeFocus(value, this.state.tabsKeys[value])}
                                    value={this.state.activeTab}
                                />
                            </div> : null
                        }
                        <div className="searchContainer">
                            <div className="selectInput">
                                <InputText
                                    onChange={(e) => this.filterProductsInfo(e.target.value)}
                                    label={sAction.translate("LBL_REPORT_INPUT_FILTER")}
                                    value={this.state.productsInfoFilter}
                                />
                            </div>
                        </div>
                        <div className="rightEnumContent">
                            {this.state.productsInfo}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
