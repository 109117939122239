export default class detailDefault {
  load(sAction,data) {
    // call immediately after detailView is load
  }
  update(sAction,data) {
    // call immediately after detailView field is update
  }
  newSubRecord(sAction,data){
    // call immediately before route sub record 
  }
  beforeSave(sAction,data){
    // call bfore Record is save

    return true;
  }
  afterSave(sAction,data){
  }
  updateDetailLine(sAction, data){
      // Uprava radku detailu
  }
}
