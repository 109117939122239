export default function checkAccountRelType(accountId, desiredRelType, field, prefix, module) {
    if (!accountId) {
        console.log("nebylo nastaveno id firmy")
        return null;
    }

    let self = this
    this.rest.post("checkAccountRelType", accountId, returnData => {
        if (returnData.status) {
            if (returnData.message.data) {
                if (returnData.message.data.relType !== desiredRelType) {
                    self.dataSet(prefix + '/fields/' + field + '/actEdit', true)
                    self.dataSet(prefix + '/fields/' + field + '/def/error', self.translate(getErrorValue(desiredRelType)))
                }  else if (returnData.message.data.relType === desiredRelType){
                    self.dataSet(prefix + '/fields/' + field + '/def/error', '')
                }
                self.checkRelateErrors(prefix, module)
                return;
            }
        }
        console.log("Neni nastaven relType", returnData.errorMessage)
    }, false)
}

function getErrorValue(relType) {
    switch (relType) {
        case "parent":
            return "LBL_PARENT_ERROR"
        case "child":
            return "LBL_CHILD_ERROR"

    }
}

