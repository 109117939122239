export default function openRightPanelMultiEnum(fieldName) {
    this.openRightPanel("load");
    const infoArr = {
        fieldName: fieldName,
        recordId: this.dataGet("view/id")
    }
    this.rest.post("loadProducts", infoArr, returnData => {
        if (returnData.status) {
            console.log(returnData)
            returnData.message.data.checkedFields = {
                leftEnum: [],
                rightEnum: {},
            }
            returnData.message.data.activeTab = {
                index: 0,
                fieldKey: null
            }
            returnData.message.data.fieldName = fieldName

            this.dsClear();
            this.dsAdd("set", "rightPanel/data", returnData.message.data);
            this.dsAdd("set", "rightPanel/content", "multiEnumProd");
            this.dsProcess();

        }
    })
}