export default function isInAbra(data) {
    console.log('isInAbra', data);

    this.popup("isInAbra", {
        params: {
            id: data.id,
        }, 
        header : this.translate("LBL_IS_IN_ABRA_POPUP_TITLE", "Accounts")
    });

}