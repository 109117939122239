import sAction from 'sAction';

export default function logError(error, info, state, clickStack = null, type = 'error') {
    try { 
        // let store = sAction.store.getState().appReducer;
        let store = sAction.dataGet("conf");
        if (store) {
            store = store.toJS();
        }

        let networkStack = localStorage.getItem('networkStack');
        if (networkStack) {
            networkStack = JSON.parse(networkStack);
        }

        const localStorageData = {};
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            const lcItem = localStorage.getItem(key).trim();
            if (lcItem !== null && lcItem && lcItem.length > 0 && key !== 'networkStack') {
                try {
                    localStorageData[key] = JSON.parse(lcItem);
                } catch (e) {
                    console.log('[ info ] Error parsing local storage item', key, lcItem);
                    localStorageData[key] = lcItem;
                }
            }
        }

        const data = {
            logId: state.logId,
            error: error.toString().replace(/'/g, '"'),
            info: info,
            stack: error?.stack?.replace(/\n {2}/g, ' '),
            message: error.message,
            url: window.location.href,
            pwa: state.isPWA,
            user: sAction.dataGet('conf/user/id'),
            browser: window.navigator.userAgent,
            resolution: window.screen.width + 'x' + window.screen.height,
            history: window.history,
            language: navigator.language,
            online: navigator.onLine,
            view: sAction.dataGet('servisCommon') ? "servis" : "coripo",
            clickTrace: clickStack,
            networkStack: networkStack,
            history: sAction.dataGet('history/urls'),
            // localStorage: localStorageData,
            store: store,
            errorType: type,
        };

        console.log('[ info ] logged fatal error', data);

        if (!networkStack) {
            networkStack = [];
        }
        if (networkStack.length > 5) {
            networkStack.shift();
        }
        networkStack.push("[ error ] log id: " + state.logId);
        localStorage.setItem('networkStack', JSON.stringify(networkStack));

        sAction.rest.post('logError', data, (returnData) => {
            if (returnData.status) {
                console.log('[ OK ] logError', returnData);
            } else {
                console.log('[ error ] logError failed: ', returnData);
            }
        }, false);
    } catch (e) {
        console.log('[ error ] logError failed: ', e);
    }
}