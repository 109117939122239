export default function importRecordDatabox(param) {
    const openNewRecord = (id, module) => {
        const url = `${document.location.origin}/#detail/${module}/${id}`;
        window.open(url, '_blank', 'noopener, noreferrer');
    }
    const updateImportedRecord = (module, id, action) => {
        const params = {
            module : module,
            id: id,
            action: action,
          }
        this.rest.post("customAction", params, function(resp) {
            if (resp.errCode == "NOK") {
                this.error("Error");
                this.dsProcess();
            }
        });
        if (module === 'acm_databox_accounts') {
            this.dsAdd("delete", param.prefix + "/buttons", 6);
        }
        this.dsAdd("delete", param.prefix + "/buttons", 5);
        this.dsAdd("set", param.prefix + "/fields/imported/value", "1");
        this.dsProcess();
    }

    if (param.buttonId === "create_new_account" || param.buttonId === "create_new_account_branch") {
        this.dsClear();
        const name = this.dataGet(param.prefix + "/fields/name/value");
        const ico = this.dataGet(param.prefix + "/fields/ticker_symbol/value");
        const dic = this.dataGet(param.prefix + "/fields/sic_code/value");
        const phoneOffice = this.dataGet(param.prefix + "/fields/phone_office/value");
        const phoneAlternate = this.dataGet(param.prefix + "/fields/phone_alternate/value");
        const website = this.dataGet(param.prefix + "/fields/website/value");
        const vzv = this.dataGet(param.prefix + "/fields/number_of_vzv/value");
        const type = this.dataGet(param.prefix + "/fields/dealer_type/value");
        const abraId = this.dataGet(param.prefix + "/fields/abra_id/value");
        const addressStreet = this.dataGet(param.prefix + "/fields/billing_address_street/value");
        const addressCity = this.dataGet(param.prefix + "/fields/billing_address_city/value");
        const addressState = this.dataGet(param.prefix + "/fields/billing_address_state/value");
        const addressCountry = this.dataGet(param.prefix + "/fields/billing_address_country/value");
        const addressPostalcode = this.dataGet(param.prefix + "/fields/billing_address_postalcode/value");
        const afterLoad = (popupId) => {
            const path = `conf/popup${popupId}/data/detailView/fields`;
            const pathChanges = `conf/popup${popupId}/data/detailView/changes/fields`;
            this.dsAdd("set", `${path}/name/value`, name);
            this.dsAdd("set", `${pathChanges}/name`, name);
            this.dsAdd("set", `${path}/sic_code/value`, dic);
            this.dsAdd("set", `${pathChanges}/sic_code`, dic);
            this.dsAdd("set", `${path}/phone_office/value`, phoneOffice);
            this.dsAdd("set", `${pathChanges}/phone_office`, phoneOffice);
            this.dsAdd("set", `${path}/phone_alternate/value`, phoneAlternate);
            this.dsAdd("set", `${pathChanges}/phone_alternate`, phoneAlternate);
            this.dsAdd("set", `${path}/website/value`, website);
            this.dsAdd("set", `${pathChanges}/website`, website);
            this.dsAdd("set", `${path}/number_of_vzv/value`, vzv);
            this.dsAdd("set", `${pathChanges}/number_of_vzv`, vzv);
            this.dsAdd("set", `${path}/dealer_type/value`, type);
            this.dsAdd("set", `${pathChanges}/dealer_type`, type);
            this.dsAdd("set", `${path}/abra_id/value`, abraId);
            this.dsAdd("set", `${pathChanges}/abra_id`, abraId);
            this.dsAdd("set", `${path}/billing_address_street/value`, addressStreet);
            this.dsAdd("set", `${pathChanges}/billing_address_street`, addressStreet);
            this.dsAdd("set", `${path}/billing_address_city/value`, addressCity);
            this.dsAdd("set", `${pathChanges}/billing_address_city`, addressCity);
            this.dsAdd("set", `${path}/billing_address_state/value`, addressState);
            this.dsAdd("set", `${pathChanges}/billing_address_state`, addressState);
            this.dsAdd("set", `${path}/billing_address_country/value`, addressCountry);
            this.dsAdd("set", `${pathChanges}/billing_address_country`, addressCountry);
            this.dsAdd("set", `${path}/billing_address_postalcode/value`, addressPostalcode);
            this.dsAdd("set", `${pathChanges}/billing_address_postalcode`, addressPostalcode);
            this.dsProcess();
            if (param.buttonId === "create_new_account") {
                this.dsAdd("set", `${path}/ticker_symbol/value`, ico);
                this.dsAdd("set", `${pathChanges}/ticker_symbol`, ico);
            } else {
                this.dsAdd("set", `${path}/ticker_symbol/value`, null);
                this.dsAdd("set", `${path}/ticker_symbol/def/readonly`, true);
                this.dsAdd("set", `${pathChanges}/ticker_symbol`, null);
            }
            this.dsProcess();
        }
        const id = this.dataGet(param.prefix + "/fields/id/value")
        this.popupDetail({ afterLoad, module:"Accounts", record:"", saveCallback: data => {
            updateImportedRecord('acm_databox_accounts', id, 'update_imported_record');
            openNewRecord(data.record, 'Accounts');
        }})
    } else if (param.buttonId === "copy_person_to_account") {
        this.dsClear();
        const firstName = this.dataGet(param.prefix + "/fields/first_name/value");
        const lastName = this.dataGet(param.prefix + "/fields/last_name/value");
        const description = this.dataGet(param.prefix + "/fields/description/value");
        const department = this.dataGet(param.prefix + "/fields/department/value");
        const phoneHome = this.dataGet(param.prefix + "/fields/phone_home/value");
        const phoneMobile = this.dataGet(param.prefix + "/fields/phone_mobile/value");
        const addressStreet = this.dataGet(param.prefix + "/fields/primary_address_street/value");
        const addressCity = this.dataGet(param.prefix + "/fields/primary_address_city/value");
        const addressPostalcode = this.dataGet(param.prefix + "/fields/primary_address_postalcode/value");
        const afterLoad = (popupId) => {
            const path = `conf/popup${popupId}/data/detailView/fields`;
            const pathChanges = `conf/popup${popupId}/data/detailView/changes/fields`;
            this.dsAdd("set", `${path}/first_name/value`, firstName);
            this.dsAdd("set", `${pathChanges}/first_name`, firstName);
            this.dsAdd("set", `${path}/last_name/value`, lastName);
            this.dsAdd("set", `${pathChanges}/last_name`, lastName);
            this.dsAdd("set", `${path}/description/value`, description);
            this.dsAdd("set", `${pathChanges}/description`, description);
            this.dsAdd("set", `${path}/department/value`, department);
            this.dsAdd("set", `${pathChanges}/department`, department);
            this.dsAdd("set", `${path}/phone_home/value`, phoneHome);
            this.dsAdd("set", `${pathChanges}/phone_home`, phoneHome);
            this.dsAdd("set", `${path}/phone_mobile/value`, phoneMobile);
            this.dsAdd("set", `${pathChanges}/phone_mobile`, phoneMobile);
            this.dsAdd("set", `${path}/primary_address_street/value`, addressStreet);
            this.dsAdd("set", `${pathChanges}/primary_address_street`, addressStreet);
            this.dsAdd("set", `${path}/primary_address_city/value`, addressCity);
            this.dsAdd("set", `${pathChanges}/primary_address_city`, addressCity);
            this.dsAdd("set", `${path}/primary_address_postalcode/value`, addressPostalcode);
            this.dsAdd("set", `${pathChanges}/primary_address_postalcode`, addressPostalcode);
            this.dsProcess();
        }
        const id = this.dataGet(param.prefix + "/fields/id/value")
        this.popupDetail({ afterLoad, module:"Contacts", record:"", saveCallback: data => {
            updateImportedRecord('acm_databox_persons', id, 'update_imported_record');
            openNewRecord(data.record, 'Contacts');
        }})
    } else if (param.buttonId === "copy_machine_to_account") {
        this.dsClear();
        const name = this.dataGet(param.prefix + "/fields/name/value");
        const machineNum = this.dataGet(param.prefix + "/fields/machinenum/value");
        const description = this.dataGet(param.prefix + "/fields/description/value");
        const machineType = this.dataGet(param.prefix + "/fields/machine_type/value");
        const active = this.dataGet(param.prefix + "/fields/active/value");
        const afterLoad = (popupId) => {
            const path = `conf/popup${popupId}/data/detailView/fields`;
            const pathChanges = `conf/popup${popupId}/data/detailView/changes/fields`;
            this.dsAdd("set", `${path}/name/value`, name);
            this.dsAdd("set", `${pathChanges}/name`, name);
            this.dsAdd("set", `${path}/description/value`, description);
            this.dsAdd("set", `${pathChanges}/description`, description);
            this.dsAdd("set", `${path}/machinenum/value`, machineNum);
            this.dsAdd("set", `${pathChanges}/machinenum`, machineNum);
            this.dsAdd("set", `${path}/machine_type/value`, machineType);
            this.dsAdd("set", `${pathChanges}/machine_type`, machineType);
            this.dsAdd("set", `${path}/active/value`, active);
            this.dsAdd("set", `${pathChanges}/active`, active);
            this.dsProcess();
        }
        const id = this.dataGet(param.prefix + "/fields/id/value")
        this.popupDetail({ afterLoad, module:"acm_machines", record:"", saveCallback: data => {
            updateImportedRecord('acm_databox_machines', id, 'update_imported_record');
            openNewRecord(data.record, 'acm_machines');
        }})
    } else if (param.buttonId === "copy_activity_to_account") {
        this.dsClear();
        const id = this.dataGet(param.prefix + "/fields/id/value")
        const name = this.dataGet(param.prefix + "/fields/name/value");
        const meeting = /jednání|navštívit|obchodní jednání|porada|školení|výstava|dovolená|klíčová událost|kontakt s OTZ dovozcu|lékař/.test(name);
        const call = name.includes('telefonát');
        if (meeting) {
            const description = this.dataGet(param.prefix + "/fields/description/value");
            const dateStart = this.dataGet(param.prefix + "/fields/date_start/value");
            const dateEnd = this.dataGet(param.prefix + "/fields/date_end/value");
            const status = this.dataGet(param.prefix + "/fields/status/value");
            const afterLoad = (popupId) => {
                const path = `conf/popup${popupId}/data/detailView/fields`;
                const pathChanges = `conf/popup${popupId}/data/detailView/changes/fields`;
                this.dsAdd("set", `${path}/name/value`, name);
                this.dsAdd("set", `${pathChanges}/name`, name);
                this.dsAdd("set", `${path}/description/value`, description);
                this.dsAdd("set", `${pathChanges}/description`, description);
                this.dsAdd("set", `${path}/date_start/value`, dateStart);
                this.dsAdd("set", `${pathChanges}/date_start`, dateStart);
                this.dsAdd("set", `${path}/date_end/value`, dateEnd);
                this.dsAdd("set", `${pathChanges}/date_end`, dateEnd);
                this.dsAdd("set", `${path}/status/value`, status);
                this.dsAdd("set", `${pathChanges}/status`, status);
                this.dsProcess();
            }
            this.popupDetail({ afterLoad, module:"Meetings", record:"", saveCallback: data => {
                updateImportedRecord('acm_databox_call', id, 'update_imported_record');
                openNewRecord(data.record, 'Meetings');
            }})
        } else if (call) {
            const description = this.dataGet(param.prefix + "/fields/description/value");
            const dateStart = this.dataGet(param.prefix + "/fields/date_start/value");
            const dateEnd = this.dataGet(param.prefix + "/fields/date_end/value");
            const dateEntered = this.dataGet(param.prefix + "/fields/date_entered/value");
            const dateModified = this.dataGet(param.prefix + "/fields/date_modified/value");
            const status = this.dataGet(param.prefix + "/fields/status/value");
            const direction = this.dataGet(param.prefix + "/fields/direction/value");
            const afterLoad = (popupId) => {
                const path = `conf/popup${popupId}/data/detailView/fields`;
                const pathChanges = `conf/popup${popupId}/data/detailView/changes/fields`;
                this.dsAdd("set", `${path}/name/value`, name);
                this.dsAdd("set", `${pathChanges}/name`, name);
                this.dsAdd("set", `${path}/description/value`, description);
                this.dsAdd("set", `${pathChanges}/description`, description);
                this.dsAdd("set", `${path}/date_start/value`, dateStart);
                this.dsAdd("set", `${pathChanges}/date_start`, dateStart);
                this.dsAdd("set", `${path}/date_end/value`, dateEnd);
                this.dsAdd("set", `${pathChanges}/date_end`, dateEnd);
                this.dsAdd("set", `${path}/date_entered/value`, dateEntered);
                this.dsAdd("set", `${pathChanges}/date_entered`, dateEntered);
                this.dsAdd("set", `${path}/date_modified/value`, dateModified);
                this.dsAdd("set", `${pathChanges}/date_modified`, dateModified);
                this.dsAdd("set", `${path}/status/value`, status);
                this.dsAdd("set", `${pathChanges}/status`, status);
                this.dsAdd("set", `${path}/direction/value`, direction);
                this.dsAdd("set", `${pathChanges}/direction`, direction);
                this.dsProcess();
            }
            this.popupDetail({ afterLoad, module:"Calls", record:"", saveCallback: data => {
                updateImportedRecord('acm_databox_call', id, 'update_imported_record');
                openNewRecord(data.record, 'Calls');
            }})
        } else {
            const description = this.dataGet(param.prefix + "/fields/description/value");
            const dateEntered = this.dataGet(param.prefix + "/fields/date_entered/value");
            const dateModified = this.dataGet(param.prefix + "/fields/date_modified/value");
            const afterLoad = (popupId) => {
                const path = `conf/popup${popupId}/data/detailView/fields`;
                const pathChanges = `conf/popup${popupId}/data/detailView/changes/fields`;
                this.dsAdd("set", `${path}/name/value`, name);
                this.dsAdd("set", `${pathChanges}/name`, name);
                this.dsAdd("set", `${path}/description/value`, description);
                this.dsAdd("set", `${pathChanges}/description`, description);
                this.dsAdd("set", `${path}/date_entered/value`, dateEntered);
                this.dsAdd("set", `${pathChanges}/date_entered`, dateEntered);
                this.dsAdd("set", `${path}/date_modified/value`, dateModified);
                this.dsAdd("set", `${pathChanges}/date_modified`, dateModified);
                this.dsProcess();
            }
            this.popupDetail({ afterLoad, module:"Notes", record:"", saveCallback: data => {
                updateImportedRecord('acm_databox_call', id, 'update_imported_record');
                openNewRecord(data.record, 'Notes');
            }})
        }
    }
}