import detailDefault from "../../detailDefault";
export default class detailacm_satisfaction_questionnaire_questions extends detailDefault {
    load(sAction,data) {

        this.checkFieldsToHide(sAction, data);
    }
    update(sAction, data) {
        if (data.field === 'typ') {
            this.checkFieldsToHide(sAction, data);
        }
    }

    checkFieldsToHide(sAction, data) {
        const type = sAction.dataGet(data.prefix+"/fields/typ/value")

        if ( type === 'enum' || type === 'multienum' ) {
            this.showField(sAction, data, 'enum')
        } else {
            this.hideField(sAction, data, 'enum')
        }
    }

    hideField(sAction, data, field) {
        sAction.dsClear();
        sAction.dsAdd("set", data.prefix + "/fields/" + field + "/def/visible", false);
        sAction.dsProcess();
    }

    showField(sAction, data, field) {
        sAction.dsClear();
        sAction.dsAdd("set", data.prefix + "/fields/" + field + "/def/visible", true);
        sAction.dsProcess();
    }
}
