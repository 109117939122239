import detailDefault from "../../detailDefault";
export default class detailacm_satisfaction_questionnaire extends detailDefault {
    load(sAction,data) {

        // vyplneni linku - bude se nejspise delat na be po odeslani linku v emailu

        // let ID = sAction.dataGet(data.prefix+"/id");
        // if (ID){
        //
        //     const params = {record: ID};
        //     sAction.rest.post("satisfactionQuestionnaireURL", params, resp => {
        //         if (resp.status) {
        //             sAction.dsClear();
        //             sAction.dataSet(data.prefix + "/fields/link/value", resp.message.data.url)
        //             sAction.dataSet(data.prefix + "/fields/link/def/readonly", true)
        //             sAction.dsProcess();
        //         }
        //     });
        // }
    }
}
