import React, { Component } from 'react'
import PureComponent from '../../pure'

import Text from '../../formElements/Text'
import TextField from "@material-ui/core/TextField";

import QProduct from './QProduct'
import QGroupTotal from './QGroupTotal'
import QGroupHeader from './QGroupHeader'

import InputText from '../../formElements/InputText'
import Select from '../../formElements/Select'
import InputBase from '@material-ui/core/InputBase';

import  sAction  from "sAction";

import DescriptionIcon from "@material-ui/icons/Description";
import Button from "../../formElements/Button";
import Relate from "ROOT/src/components/formElements/Relate";
import propTypes from 'prop-types';

class QProductGroup extends PureComponent{
    
    constructor(props){
        super(props);

        this.state = {
            show: true,
            descFocused: false
        }
    }
    handleMachineChange = (relatedMachine) => {
        const itway = `${this.props.way}/${this.props.ind}`;
        if (!relatedMachine?.id) {
            console.log('returning, no item selected');
            return;
        }
        sAction.dsClear();
        sAction.dsAdd('set', `${itway}/acm_machines_id`, relatedMachine.id);
        sAction.dsAdd('set', `${itway}/acm_machines_name`, relatedMachine.name);
        sAction.dsAdd('set', `${itway}/name`, relatedMachine.name);
        sAction.dsProcess();
    };

    /**
     * Get filter for machines by headquarters and branch
     * @returns {}
     */
    getDefaultFilter() {
        return [
            {
                operandType: "relate",
                type: "eq",
                module: "Accounts",
                relName: "acm_machines_accounts",
                relationship: ["acm_machines_accounts"],
                name: "acm_machines_name",
                parentId: this.props.data.account_branch_filter_id ?? '',
                orGroup: 1,
            },
            {
                operandType: "relate",
                type: "eq",
                module: "Accounts",
                relName: "acm_machines_accounts",
                relationship: ["acm_machines_accounts"],
                name: "acm_machines_name",
                parentId: this.props.data.account_hq_filter_id ?? '',
                orGroup: 1,
            },
        ];
    }

    valChange(way, val){
        this.setState({show: true});
        sAction.dataSet(way, val);
    }

    toggleDisplay(){
        this.setState({show : !this.state.show})
    }

    blurDescription(way, val){
        this.setState({descFocused : false});
        this.valChange(way, val);
    }

    handleMove(type, way, ind){
        var list = sAction.dataGet(way);
        const cnt = list.size;

        let modifier = 0;

        if(type == "up"){
            if(ind == 0){
                return;
            }
            let pomGr = list.get(ind);
            list = list.delete(ind);
            list = list.insert(ind - 1, pomGr);
            
        }
        else if(type == "down"){
            if((ind + 1) >= cnt){
                return;
            }
            let pomGr = list.get(ind + 1);
            list = list.delete(ind + 1);
            list = list.insert(ind, pomGr);
        }

        
        sAction.dsClear();
        sAction.dsAdd("set", way, list);
        sAction.dsAdd("set","view/changes/forceChange", true);
        sAction.dsProcess();
    }

    render(){
    const data = this.props.data;
    const way = this.props.way;
    const ind = this.props.ind;
    const itway = way+"/"+ind;
    const currency_id = this.props.currency_id;
    const readonly = this.props.readonly;

    var renderComp = [];

    var name = data.name;

    let discountOptions = [];
    sAction.app_strings.discount_select_list.forEachObject((lbl, key) => {
        discountOptions.push({value: key, label: lbl});
    });

    // var stageOptions =[];
    // sAction.app_strings.quote_stage_dom.forEachObject((lbl, key) => {
    //     stageOptions.push({value: key, label: lbl});
    // });

    renderComp.push(<QGroupHeader key={"header"} readonly={readonly} />);

    data.lines.forEach(function(prod,k){
        renderComp.push(<QProduct data={prod} key={k} ind={k} way={itway+"/lines"} readonly={readonly} currency_id={currency_id} />);
    });
    
//  
    var pomClass = "";
    if(!this.state.show){
        pomClass = " hidden";
    }
    const preKey = Date.now();

    var descClass = "productLineDescription";
    var descContClass = "descGroupContainer";
    //notEmpty
    var descComp = null;
    if(this.state.descFocused){
        descComp = <TextField
        className={descClass}
        multiline={true}
        type="text"
        margin="normal"
        variant="outlined"
        rows={4}
        key={preKey+"_description"}
        onFocus={() => this.setState({descFocused : true})}
        onBlur={(ev) => this.blurDescription(itway+"/description", ev.target.value)}
        disabled={readonly}
        defaultValue={data.description}
        autoFocus={true}
    />
    }
    else{
        if(data.description?.length > 0){
            descContClass += " notEmpty";
        }
        descComp = <span>{data.description}</span>
    }

    var iconClass = "icon-quoteCloseGroup";
    if(!this.state.show){
        iconClass = "icon-quoteOpenGroup";
    }

    var moveGroupComp = null;

    if(!readonly){
        moveGroupComp = <div className="qProductLineButtonContainer">
        <div className={"icon-arrowUp prodLineIcon hoverBlue"} onClick={() => this.handleMove("up", way, ind)}></div>
        <div className={"icon-arrowDown prodLineIcon hoverBlue"} onClick={() => this.handleMove("down", way, ind)}></div>
    </div>;
    }
   const defaultFilter = this.getDefaultFilter();
    let machineLink = null;
    if(this.props.data.acm_machines_id) {
        const url = `${window.location.origin}/#detail/acm_machines/${this.props.data.acm_machines_id}`;
        machineLink = (<div className={'icon-expandIcon buttonIcon'}
             onClick={(e) => {
                 window.open(url, '_blank').focus();
             }}
             style={{marginTop: '2px', padding: '9px 0', cursor: 'pointer'}}
        />)
    }

    let machineComponent = null;
    if(readonly) {
        machineComponent = (
            <TextField
                variant={'outlined'}
                style={{marginRight: '8px'}}
                margin="normal"
                type="text"
                defaultValue={this.props.data.acm_machines_name}
                disabled={readonly}
                key={'machineComponent_name'}
                className="qGroupNameField"
            />
        );
    } else {
        machineComponent=(
            <Relate
                readonly={readonly}
                containerStyle={{marginTop: '5px'}}
                key={this.props.data.acm_machines_id}
                newRecord={false}
                defaultFilter={defaultFilter}
                way={itway}
                value={this.props.data.acm_machines_name}
                name={this.props.data.acm_machines_name}
                defaultValue={this.props.data.acm_machines_name}
                data={{
                    value: this.props.data.acm_machines_name,
                    id: this.props.data.acm_machines_id,
                }}
                module={"acm_machines"}
                callback={this.handleMachineChange}
                InputProps={{variant: 'outlined'}}
            />
        );
    }

    //.
    return <div className="qGroupContainer">
        <div className="qGroupComponent">
        <div className="qGroupHeaderContainer">
            <div className="qGroupHeader">
                <div className="qGroupHeaderLeft">
                    {moveGroupComp}
                    <div className="qGroupHeaderField">
                    <div className="qGroupHeaderFieldLbl">
                    {sAction.translate("LBL_PRODUCT_GROUP", "Quotes")}:
                    </div>
                    <TextField
                        margin="normal"
                        variant="outlined"
                        id="name"
                        type="text"
                        defaultValue={name}
                        disabled={readonly}
                        key={preKey+"_name"}
                        onBlur={(ev) => sAction.updateDetailCustom("field", {way:itway+"/name", value: ev.target.value})}
                        className="qGroupNameField"
                        
                    />
                    <div className={'qGroupHeaderFieldLbl'} style={{paddingLeft: '8px'}}>
                        {sAction.translate('LBL_ACM_MACHINES_ACM_MOTOHOURS_FROM_ACM_MACHINES_TITLE', 'acm_motohours')}:
                    </div>
                    {machineComponent}
                    {machineLink}
                    </div>
                    <div className="qGroupHeaderField qGroupHeaderFieldCenotvorba">
                    <div className="qGroupHeaderFieldLbl">
                    {sAction.translate("LBL_SLEVA", "Quotes")}:
                    </div>
                        <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            defaultValue={sAction.formatNumber(data.cenotvorba_discount)}
                            disabled={readonly}
                            key={preKey+"_cenotvorba_discount"}
                            onBlur={(ev) => sAction.cenotvorbaChange("group", {way:itway, value: sAction.parseNumber(ev.target.value), field: "cenotvorba_discount"})}
                            onKeyDown={((ev) => {if(ev.keyCode == 13){ev.target.blur();}})}
                            className="qGroupDiscountField"
                        />
                        <Select
                            open={false}
                            containerStyle={{width: '160px'}}
                            name="cenotvorba_discount_select"
                            options={discountOptions}
                            defaultValue={data.cenotvorba_discount_select || 1}
                            disabled={readonly}
                            key={preKey+"_cenotvorba_discount_select"}
                            onChange={(ev) => sAction.cenotvorbaChange("group", {way:itway, value: ev.target.value, field: "cenotvorba_discount_select"})}
                        />
                    </div>
                    {!readonly &&
                        <Button variant="text" className="acmCatProdBtn" onClick={() => sAction.rightPanelProduct({way:itway+"/lines"})}>
                            <div className="icon-expandIcon buttonIcon" />
                            {sAction.translate("LBL_PRODUCT_CATALOG", "Quotes")}
                        </Button>
                    }
                    {!readonly &&
                    <Button variant="text" className="hoverBlue acmDescGroupBtn" onClick={() => this.setState({descFocused : true})}>
                        <div className="icon-editDesc buttonIcon" />
                        {sAction.translate("LBL_ADD_GROUP_DESCRIPTION", "Quotes")}
                    </Button>
                    }
                    <div className={"quoteHoverIcon "+iconClass} onClick={() => this.toggleDisplay()}></div>
                </div>
                {!readonly &&
                    <Button 
                    className="acmDelGroupBtn hoverRed"
                    variant="text" 
                    onClick={() => sAction.updateDetailCustom("delItem", {way:way, index:ind})}>
                    <div className="buttonIcon icon-deleteIcon" />
                    {sAction.translate("LBL_DELETE_GROUP", "Quotes")}
                </Button>}
            </div>
        </div>
        <div className={"productGroupBody"+pomClass}>
        {renderComp}
        </div>
        </div>
        <div className={descContClass}>
        {descComp}     
        </div>

        <QGroupTotal
            way={itway}
            data={data}
            ind={ind}
            readonly={readonly}
        />
    </div>;
    }
}

QProductGroup.propTypes = {
    way: propTypes.string,
    ind: propTypes.number,
};

export default QProductGroup
