import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import ProductsTable from 'ROOT/src/components/detailView/Accounts/ProductsTable';

export default class DetailProductsTab extends PureComponent {
    render() {
        // vyrenderuje se pouze pokud je aktivni

        // not supported in popup detail
        if (this.props?.way.includes('conf/popup/')) {
            return (<>
                <p>
                    <b>Produkty</b> nejsou v popup okně podporovány. Použijte detailní zobrazení.
                </p>
            </>);
        }

        return (
            <>
                {
                    this.props.active ?
                        <div className="containerProductTable">
                            <ProductsTable data={this.props.data}/>
                        </div> : null
                }
            </>
        );
    }
}

DetailProductsTab.propTypes = {
    active: PropTypes.any,
    data: PropTypes.any,
    way: PropTypes.string,
};
